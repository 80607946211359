import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmResError} from '../form/BwmResError';

let init = {};
const DeleteForm = props => {
 
  const { handleSubmit, submitting, submitCb, errors } = props;
 
// console.log('Init', init)
  return (
    <form onSubmit={handleSubmit(submitCb)} >

        <div className="row">
            <div className="col-md-6">
            Do you Really want to Delete?
            </div>
        </div>
        <br/>
        <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={submitting}>
            Delete
        </button>
        
        <BwmResError errors={errors} />
    </form>
  )
}


  export default reduxForm({
    form: 'deleteForm',enableReinitialize: true
      
  })(DeleteForm)