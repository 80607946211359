import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose,combineReducers } from 'redux';

import { serviceReducer, selectedServiceReducer } from './service-reducer';
import { productReducer, selectedProductReducer } from './product-reducer';
import { eventsReducer, eventReducer } from './event-reducer';
import {serviceItemsReducer,selectedServiceItemReducer,serviceItemsByCategoryReducer, selectedServiceItemsByCategoryReducer} from './service-items-reducer';
import {serviceCategoryReducer,selectedServiceCategoryReducer} from './service-category-reducer';
import {categoryReducer,selectedCategoryReducer} from './category-reducer';
import {subcategoriesReducer,selectedSubcategoryReducer} from './subcategory-reducer';
import {serviceAreasReducer, selectedServiceAreaReducer} from './service-area-reducer';
import {brandsReducer, selectedBrandReducer} from './brand-reducer';
import { authReducer } from './auth-reducer';
import { serviceMapReducer } from './map-reducer';
import { userBookingsReducer} from './booking-reducer';
import { accountsReducer, accountDetailsReducer, searchAccountReducer, accountReducer,accountByIdReducer, accountPrivateImage,accountPrivateImageById, accountDetailsByIdReducer} from './account-reducer';
// import { accountUserReducer, selectedAccountUserReducer, selectedUserAccountUserReducer} from './account-account-reducer';
import { selectedDigitalAccountBalanceReducer, selectedDigitalWalletAccountReducer, selectedDigitalWalletAccountByIdReducer,selectedUserDigitalAccountBalanceReducer, digitalWalletTransactionsByIdReducer} from './digital-currency-reducer';
import { reducer as formReducer} from 'redux-form';

export const init =()=>{
    const reducer = combineReducers({
        services: serviceReducer,
        service: selectedServiceReducer,
        serviceCategories: serviceCategoryReducer,
        serviceCategory:selectedServiceCategoryReducer,
        serviceAreas: serviceAreasReducer,
        serviceArea: selectedServiceAreaReducer,
        // serviceItems: serviceItemsByCategoryReducer,
        serviceItems: serviceItemsReducer,
        serviceItem: selectedServiceItemReducer,
        form: formReducer,
        auth: authReducer,
        userBookings: userBookingsReducer,
        map: serviceMapReducer,
        accounts: accountsReducer,
        
        accountsSearch: searchAccountReducer,
        serviceItemsByCategory: selectedServiceItemsByCategoryReducer,
        account: accountReducer,
        accountDetails: accountDetailsReducer,
        accountById: accountByIdReducer,
        accountDetailsById:accountDetailsByIdReducer,
        privateImages: accountPrivateImage,
        privateImagesById:accountPrivateImageById,

        products: productReducer,
        product: selectedProductReducer,

        events: eventsReducer,
        event: eventReducer,

        brands:brandsReducer,
        brand: selectedBrandReducer,

        categories: categoryReducer,
        category:selectedCategoryReducer,

        subcategories: subcategoriesReducer,
        subcategory:selectedSubcategoryReducer,

       
        digitalWalletAccount:selectedDigitalWalletAccountReducer,
        digitalWalletAccountBalance:selectedDigitalAccountBalanceReducer,

        digitalWalletAccountById:selectedDigitalWalletAccountByIdReducer,
        digitalWalletTransactionsById:digitalWalletTransactionsByIdReducer,
        digitalWalletUserAccountBalanceById:selectedUserDigitalAccountBalanceReducer
    });
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        reducer, 
        composeEnhancers(applyMiddleware(thunk))
        );
    return store;
}