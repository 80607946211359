import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import ProductUpdateModal from "../../shared/Modal/ProductUpdateModal";
import ProductDeleteModal from "../../shared/Modal/ProductDeleteModal";
import * as actions from '../../../actions/products/index';
import {Link} from 'react-router-dom';
export const Products =({productData}) =>{
  const dispatch = useDispatch();

  const [product, setproduct] = useState(productData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedproduct, setSelectedproduct] = useState({});
  

  const columns = [
    {
      name: 'Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/product/'+row.image_link} />,
    },
    {
      name: 'Brand',
      selector: 'brand_name',
    },
    {
      name: 'Product',
      selector: 'name',
    },
    
    {
      name: 'Category',
      selector: 'category_name',
    },
    {
      name: 'Subcategory',
      selector: 'subcategory_name',
    },
    {
        name: 'Active',
        // selector: 'active',
        cell: row => row.active == true?'Yes': 'No',
      },
      {
        name: 'Available',
        // selector: 'active',
        cell: row => row.available == true?'Yes': 'No',
      },
      {
        name: 'Price',
        // selector: 'price',
        cell: row => row.discount == 0? row.price :(row.msrp - ((row.msrp*row.discount)/100))
      },
      {
        name: 'MSRP',
        selector: 'msrp',
      },
      {
        name: 'Discount',
        selector: 'discount',
      },
      {
        name: 'Units Stock',
        selector: 'units_stock',
      },
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-product-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-product-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchProduct(id))
    .then((res)=>{
       console.log('res',res)
      setSelectedproduct({ selectedproduct: res});
    })
    .catch()    
  }

  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteProduct(id))
    dispatch(actions.fetchProduct(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedproduct({ selectedproduct: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = product.findIndex((e)=> e.id === service.id);
    var newStateArray = product.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(product !== null || product !== undefined)
      getproductList(); 
    else return null;
  }

  function renderRows() {
    if(product !== null || product !== undefined)
      getproductList();
    else return null;
  }

  const getproductList = () => {  
    dispatch(actions.fetchProducts(page, countPerPage))
    .then(res => {
      setproduct(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setproduct({});
    });
  }
  
  useEffect(() => {
    getproductList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/products/create-product"><button className="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Product</button></Link>
      <div className="row px-4"> 
      
       
        {product &&<ProductUpdateModal record={selectedproduct} action={renderRow}/>}
        {product &&<ProductDeleteModal  record={selectedproduct} action={renderRows}/>}
        <ToastContainer/>
        
        {
          product &&
          <DataTable
            title="Products"
            columns={columns}
            data={product}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
          />
          }    

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  productData: state.services.data
  }
}

// const mapDispatchToProps = dispatch =>{
//   return {
//     fetchproduct:(id)=>dispatch(fetchproduct(id))
//   }
// }
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  // mapDispatchToProps
)(Products);
