import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions';
import 'react-toastify/dist/ReactToastify.css';
import ServiceDetailForm from '../../services/service-detail/ServiceDetailForm';
class ServiceUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['true', 'false'];
        this.updateService = this.updateService.bind(this);
    }
    
    
    updateService(userData){
        const {service} = this.props;
        let mergeData = {...service, ...userData};
        this.props.dispatch(actions.updateService(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateServiceSuccess(mergeData));
            this.setState({record:mergeData})
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-service-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Service</h4>
                                <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <ServiceDetailForm  
                                    submitCb={this.updateService} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    return{
    auth: state.auth,
    errors: state.errors,
    service: state.service.data,
     initialValues: {
        name: state.service.data.name,
        image_link: state.service.data.image_link,
        active: state.service.data.active,
        short_description: state.service.data.short_description,
        description: state.service.data.description,
        updated_at: state.service.data.updated_at,
        created_at: state.service.data.created_at
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(ServiceUpdateModal));
