import React from 'react';
import { Field, reduxForm } from 'redux-form';
// import {BwmFileUpload} from '../../shared/form/BwmFileUpload';
import {BwmInput,BwmCheckbox} from '../shared/form/BwmInput';
import {BwmTextArea} from '../shared/form/BwmTextArea';
import {BwmResError} from '../shared/form/BwmResError';
import {required,email, checkbox, phone, text, minLength2,minLength6, maxLength32, maxLength64,maxLength512} from '../shared/form/validators';

let init = {}
const ContactUsForm = props => {
    const { handleSubmit, pristine,reset, submitting, submitCb, valid ,errors,initialValues } = props;
    init = initialValues
    return (
      <form onSubmit={handleSubmit(submitCb)} >
        <div className="row">
            <div className="col-md-4">
                <Field
                    name="first_name"
                    type="text"
                    label='First Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required,text,minLength2, maxLength32]}
                />
            </div>
         
            <div className="col-md-4">
                <Field
                    name="middle_name"
                    type="text"
                    label='Middle Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[minLength2,maxLength32,text]}
                />
            </div>
            <div className="col-md-4">
                <Field
                    name="last_name"
                    type="text"
                    label='Last Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required,minLength2,maxLength32,text]}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <Field
                    name="to_email"
                    type="email"
                    label='Email'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, email]}
                />
            </div>
            <div className="col-md-6">
                <Field
                    name="cell_phone"
                    type="text"
                    label='Cell Phone'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, phone]}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <Field
                    name="message"
                    type="text"
                    label='Message'
                    rows='6'
                    className='form-control'
                    component={BwmTextArea}
                    validate={[required, maxLength512]}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <Field
                    name="acknowledgement"
                    type="checkbox"
                    label='By Clicking here you ackknowledge that you are not a Robot'
                    // className='form-control'
                    component={BwmCheckbox}
                    validate={[checkbox]}
                />
            </div>
        </div>
        
        <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={!valid || pristine || submitting}>
          Save 
        </button>
        <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
        <BwmResError errors={errors} />
      </form>
    )
  }
  
  export default reduxForm({
    form: 'contactUsForm'
      
  })(ContactUsForm)