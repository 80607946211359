import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions/products/index';
import 'react-toastify/dist/ReactToastify.css';
import SubcategoriesDetailForm from '../../products/product-listing/SubcategoryDetailForm';

class SubcategoriesUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['','true', 'false'];
        this.updateSubcategory = this.updateSubcategory.bind(this);
        //this.products = this.products.bind(this);
        this.categories = this.categories.bind(this);
        // this.subcategories = this.subcategories.bind(this);
        // this.brands = this.brands.bind(this);
    }
    componentWillMount(){
        // this.props.dispatch(actions.fetchSubcategoriess(1,100));
        this.props.dispatch(actions.fetchCategories(1,100));
        // this.props.dispatch(actions.fetchSubcategories(1,100));
        // this.props.dispatch(actions.fetchBrands(1,100));
    }

    categories(){
        if(this.props.categories === null || this.props.categories=="") 
            return null;
        let result = this.props.categories.map(a=>a.name)
        return result
    }

    updateSubcategory(SubcategoriesData){
        console.log('SubcategoriesData', SubcategoriesData)
        const {subcategory} = this.props;
        let mergeData = {...subcategory, ...SubcategoriesData};
        this.props.dispatch(actions.updateSubcategory(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateSubcategorySuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-subcategory-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Subcategories</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <SubcategoriesDetailForm  
                                    submitCb={this.updateSubcategory} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    // products = {this.products()}
                                    categories = {this.categories()}
                                    // subcategories = {this.subcategories()}
                                    // brands={this.brands()}
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    return{
    auth: state.auth,
    errors: state.errors,
    subcategory: state.subcategory.data,
    categories: state.categories.data,
     initialValues: {
        name: state.subcategory.data.name,
        image_link: state.subcategory.data.image_link,
        active: state.subcategory.data.active,
        short_description: state.subcategory.data.short_description,
        description: state.subcategory.data.description,
        updated_at: state.subcategory.data.updated_at,
        created_at: state.subcategory.data.created_at,
        category_name: state.subcategory.data.category_name,
        category_id: state.subcategory.data.category_id,
        sorting_no: state.subcategory.data.sorting_no,
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(SubcategoriesUpdateModal));
