import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions';
import 'react-toastify/dist/ReactToastify.css';
import ServiceItemDetailForm from '../../services/service-detail/ServiceItemDetailForm';
class ServiceItemUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['true', 'false'];
        this.updateServiceItem = this.updateServiceItem.bind(this);
        this.services = this.services.bind(this);
        this.serviceCategories = this.serviceCategories.bind(this);
        this.serviceAreas = this.serviceAreas.bind(this);
    }
    componentWillMount(){
        this.props.dispatch(actions.fetchServices(1,1000));
        this.props.dispatch(actions.fetchServicesCategory(1,1000));
        this.props.dispatch(actions.fetchServiceAreas(1,1000));
    }

    services(){
        if(this.props.services === null || this.props.services=="") 
            return null;
        let result = this.props.services.map(a=>a.name)
        return result
    }

    serviceCategories(){
        if(this.props.serviceCategories === null || this.props.serviceCategories=="") 
            return null;
        let result = this.props.serviceCategories.map(a=>a.name)
        return result
    }

    serviceAreas(){
        if(this.props.serviceAreas === null || this.props.serviceAreas=="") 
        return null;
        let result = this.props.serviceAreas.map(a=>a.name)
        return result
    }

    updateServiceItem(ServiceItemData){
        const {serviceItem} = this.props;
        let mergeData = {...serviceItem, ...ServiceItemData};
        this.props.dispatch(actions.updateServiceItem(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateServiceItemSuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-service-item-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Service Item</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <ServiceItemDetailForm  
                                    submitCb={this.updateServiceItem} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    services = {this.services()}
                                    serviceCategories = {this.serviceCategories()}
                                    serviceAreas={this.serviceAreas()}
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    // console.log('state',state.serviceItem.data)
    return{
    auth: state.auth,
    errors: state.errors,
    serviceItem: state.serviceItem.data,
    serviceCategories: state.serviceCategories.data,
    serviceAreas: state.serviceAreas.data,
    services: state.services.data,
     initialValues: {
        name: state.serviceItem.data.name,
        image_link: state.serviceItem.data.image_link,
        active: state.serviceItem.data.active,
        short_description: state.serviceItem.data.short_description,
        description: state.serviceItem.data.description,
        updated_at: state.serviceItem.data.updated_at,
        created_at: state.serviceItem.data.created_at,
        // service_category_id: state.serviceItem.data.service_category_id,
        service_category_name: state.serviceItem.data.service_category_name,
        // service_area_id: state.serviceItem.data.service_area_id,
        service_area_name: state.serviceItem.data.service_area_name,
        // service_id: state.serviceItem.data.service_id,
        service_name: state.serviceItem.data.service_name,
        image_link: state.serviceItem.data.image_link,
        price_package_1: state.serviceItem.data.price_package_1,
        price_package_2: state.serviceItem.data.price_package_2,
        price_package_4: state.serviceItem.data.price_package_4,
        price_package_6: state.serviceItem.data.price_package_6
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(ServiceItemUpdateModal));
