import React from 'react';

function Consult() {

    return (
        <div className="consult">
            <div className="container">
        
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="consult-area">
                            <div className="consult-title">Need Aesthetic Advice?</div>
                            <div className="consult-subtitle">Do not hasitate to ask questions</div>
                            <div className="consult-details">
                                Be Free and smart to cobsult us. We understand sometimes it is hard to open yourself, that's why we are
                                You may have many unsolved question. We are here to help. Never compromise your beauty. It is your identity.
                            </div>
                            <div className="consult-term-condition">* Consultation service is free for the first time 15 minutes only</div>

                            <div className="consult-callus">Call Us Today</div>
                            <div className="consult-phone"> +1 647 789 2323</div>

                        </div>
                        
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <img className='consult-img' src={'https://imgur.com/qj2xXo0.jpg'} alt=""/>
                    </div>
                    
                </div>
              

            </div>
            
        </div>
    )
}
export default Consult;