import { 
    FETCH_SUBCATEGORIES_SUCCESS,
    FETCH_SUBCATEGORIES_INIT,
    FETCH_SUBCATEGORIES_FAIL,

    FETCH_SUBCATEGORY_INIT,
    FETCH_SUBCATEGORY_SUCCESS,
    FETCH_SUBCATEGORY_FAIL,
    UPDATE_SUBCATEGORY_SUCCESS, 
    UPDATE_SUBCATEGORY_FAIL,
    DELETE_SUBCATEGORY_FAIL,
    DELETE_SUBCATEGORY_SUCCESS, 
    RESET_SUBCATEGORY_ERRORS, 

    FETCH_SUBCATEGORIES_BY_ID_INIT,
    FETCH_SUBCATEGORIES_BY_ID_SUCCESS,
    FETCH_SUBCATEGORIES_BY_ID_FAIL,
    UPDATE_SUBCATEGORIES_BY_ID_SUCCESS,
    UPDATE_SUBCATEGORIES_BY_ID_FAIL,
    RESET_SUBCATEGORIES_BY_ID_ERRORS,

         
} from "../actions/products/types";

const INITIAL_STATE = {
    subcategories:{
        data:[],
        errors:[]
    },
    subcategory:{
        data:{}
    }   
}

export const subcategoriesReducer = (state=INITIAL_STATE.subcategories, action) =>{
    switch(action.type){
        case FETCH_SUBCATEGORIES_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_SUBCATEGORIES_SUCCESS:
            return {...state, data: action.subcategories }
        case FETCH_SUBCATEGORIES_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})

        case DELETE_SUBCATEGORY_SUCCESS:
            return {...state, 
                data:state.data.filter(x => x.id !== action.subcategories)
            }
        case DELETE_SUBCATEGORY_FAIL:
            return Object.assign({}, state, {errors: action.errors});
        default:
            return state;
    }
}

export const selectedSubcategoryReducer = (state=INITIAL_STATE.subcategory, action) =>{
    switch(action.type){
        case FETCH_SUBCATEGORIES_BY_ID_INIT:
            return {...state, data: {} }
        case FETCH_SUBCATEGORIES_BY_ID_SUCCESS:
            return {...state, data: action.subcategory }
        case FETCH_SUBCATEGORIES_BY_ID_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_SUBCATEGORIES_BY_ID_SUCCESS:
            return {...state, data: action.subcategory};
        case UPDATE_SUBCATEGORIES_BY_ID_FAIL:
            return {...state, errors: action.errors};
        case RESET_SUBCATEGORIES_BY_ID_ERRORS:
            return {...state, errors: []};
        
            case FETCH_SUBCATEGORY_INIT:
                return {...state, data: {} }
            case FETCH_SUBCATEGORY_SUCCESS:
                return {...state, data: action.subcategory }
            case FETCH_SUBCATEGORY_FAIL:
                return {...state, errors: action.errors};
            case UPDATE_SUBCATEGORY_SUCCESS:
                return {...state, data: action.subcategory};
            case UPDATE_SUBCATEGORY_FAIL:
                return {...state, errors: action.errors};
            case RESET_SUBCATEGORY_ERRORS:
                return {...state, errors: []};
            default:
                return state;
    }
}