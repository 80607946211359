import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
// import BrandUpdateModal from "../../../shared/Modal/BrandUpdateModal";
import EventDeleteModal from "../../shared/Modal/EventDeleteModal";
import * as actions from '../../../actions/events/index';
import {Link} from 'react-router-dom';
import moment from 'moment';
export const Events =({eventData}) =>{
  const dispatch = useDispatch();

  const [event, setEvent] = useState(eventData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedEvent, setSelectedEvent] = useState({});
  

  const columns = [
    {
      name: 'Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/event/'+row.image_link} />,
    },
  
    {
      name: 'Event',
      selector: 'name',
    },
    {
      name: 'Account ID',
      selector: 'account_id',
    },
    {
      name: 'Price',
      // selector: 'price',
      cell: row => row.price !== null || undefined ? (Math.round(row.price)).toFixed(2): null
    },
    {
      name: 'Start Date',
      // selector: 'start_date',
      cell: row => row.start_date !== null || undefined ? moment(row.start_date).format('DD/MM/YYYY hh:mm:ss'): null
    },
    {
      name: 'End Date',
      //selector: 'end_date',
      cell: row => row.end_date !== null || undefined ? moment(row.end_date).format('DD/MM/YYYY hh:mm:ss'): null
    },
    {
      name: 'Duration',
      cell: row => 
      moment.duration(moment(moment(row.end_date ).format()).diff(moment(row.start_date ).format())).asDays() > 1 == true?
      Math.round(moment.duration(moment(moment(row.end_date ).format()).diff(moment(row.start_date ).format())).asDays()).toFixed(0)+' Days':
      moment.duration(moment(moment(row.end_date ).format()).diff(moment(row.start_date ).format())).asHours() + ' Hours'
    },
    {
      name: 'Expired',
      cell: row => moment.duration(moment(row.end_date).diff(moment())).asMinutes()>1440 ? Math.round(moment.duration(moment(row.end_date).diff(moment())).asDays()).toFixed() +'Days ' :(moment.duration(moment(row.end_date).diff(moment())).asMinutes()<0 ?'Expired'  :Math.round(moment.duration(moment(row.end_date).diff(moment( ))).asMinutes()).toFixed() +' Minutes')
    },
    {
      name: 'Active',
      cell: row => row.active == true?'Yes': 'No',
    },
      
    {
      name: 'Actions',
      button: true,
      cell: row =>  (
          <Fragment>
              <Link  to ={`/events/details/${row.id}`}>
                    <button className="btn btn-primary btn-sm" value="Details"
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>  
                    </button>
                  </Link>
              <button
                  data-toggle="modal"
                  data-target="#delete-brand-modal"
                  className="btn btn-danger btn-sm"
                  onClick={() => deleteRecord(row.id)}>
                  <i className="fa fa-trash"></i>
              </button>
          </Fragment>
      )
    },
  ];

  function editRecord(id) {
    dispatch(actions.fetchEvent(id))
    .then((res)=>{
       console.log('res',res)
      setSelectedEvent({ selectedEvent: res});
    })
    .catch()    
  }

  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteBrand(id))
    //The delete has been done at modal,
    //Here I just fetch the details
    dispatch(actions.fetchEvent(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedEvent({ selectedEvent: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = brand.findIndex((e)=> e.id === service.id);
    var newStateArray = brand.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(brand !== null || brand !== undefined)
      getEventList(); 
    else return null;
  }

  function renderRows() {
    if(event !== null || event !== undefined)
      getEventList();
    else return null;
  }

  const getEventList = () => {  
    dispatch(actions.fetchEvents(page, countPerPage))
    .then(res => {
      setEvent(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setEvent({});
    });
  }
  
  useEffect(() => {
    getEventList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/events/event_add"><button className="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Events</button></Link>
      <div className="row px-4">  
        {/* {brand && <BrandUpdateModal record={selectedbrand} action={renderRow}/>}*/}
        {event && <EventDeleteModal  record={selectedEvent} action={renderRows}/>} 
        <ToastContainer/>
        {event && 
          <DataTable
            title="Events"
            columns={columns}
            data={event}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
          />
        }

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  brandData: state.services.data
  }
}

// const mapDispatchToProps = dispatch =>{
//   return {
//     fetchbrand:(id)=>dispatch(fetchbrand(id))
//   }
// }
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  // mapDispatchToProps
)(Events);
