import React, { Component } from 'react';

export default class GoogleMap extends Component {

  constructor(){
    super();
    this.state ={
      isDesktop: false  
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }
  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1080 });
  }
  render() {
    const isDesktop = this.state.isDesktop;

    return (  
      <div className="map">
        <div className="container">
        <div className='row'>
            <section id='contact'>
                <div className='map-location'>
                   
                        <div className='col-md-12'>
                            <h1 className="map-shadow-title">Location and Parking</h1>
                            <h1 className="map-title">Location and Parking</h1>
                            <div className="map-details">Our Store is located between Main and Woodbine Subway station but 2 minutes walk from Main Subway 
                                Station after KFC and cross the street from Sobey's. There are 2 Sobeys Parking lots free for Sobeys Customer.
                                Those premises also have pay parking. Street pay parking also available front of the store.
                            </div>
                        </div>
                        
                    </div>
               
            </section>
            </div>
        </div>

        <div className="container-fluid"> 
        
          {isDesktop ? (
          <div className="map-image"><img src={process.env.PUBLIC_URL + '/img/page/map-1.png'} alt=""/> </div>) 
          : (
          <div className="map-image"><img src={process.env.PUBLIC_URL + '/img/page/map-2.png'} alt=""/> </div>
          )}     
        </div>
      </div>
    )
}
}
