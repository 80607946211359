import * as jwt from 'jsonwebtoken';
import * as moment from 'moment';

class AuthService {

  tokenKey = 'auth_token';

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  decode(token) {
    return jwt.decode(token);
  }

  saveToken(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  invalidateUser() {
    localStorage.removeItem(this.tokenKey);
  }

  getExpiration(token) {
    // console.log('TYoken',token)
 
      const expire = this.decode(token).exp;
      console.log('expire', expire)
      return moment.unix(expire);
    
  }

  getUserId() {
    return this.decode(this.getToken()).userId;
  }
  getFirstName() {
    return this.decode(this.getToken()).first_name;
  }

  getLastName() {
    return this.decode(this.getToken()).last_name;
  }

  getEmail(){
    return this.decode(this.getToken()).email;
  }

  getUserRole(){
    return this.decode(this.getToken()).user_role;
  }

  isValid(token) {
    return moment().isBefore(this.getExpiration(token));
  }

  isAuthenticated() {
    const token = this.getToken();
    // console.log('tok', token)
    if(token === null || token === undefined) return false;
    else return this.isValid(token)
    //return (token && this.isValid(token)) ? true : false;
  }
}

export default new AuthService();