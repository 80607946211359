import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { updateAccount } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import * as actions from '../../../actions';
import * as digitalWalletActions from '../../../actions/digital-currency';
import 'react-toastify/dist/ReactToastify.css';
import AccountUserForm from '../../account/account-detail/AccountUserForm';
import AccountWalletForm from '../../account/account-detail/AccountWalletForm';
import DataTable from 'react-data-table-component';
import moment from 'moment';
var QRCode = require('qrcode.react');
class UserUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           
            record:this.props.record,
            id: this.props.record.id,
            first_name: this.props.record.first_name,
            last_name: this.props.record.last_name,
            email: this.props.record.email,
            cell_phone: this.props.record.cell_phone,
            // password: '',
            viewWallet: false,
            viewWalletBalance:false,
            wallet_address_by_id: '',
            wallet_balance_by_id:'',
            transactions_by_id:'',
            errors: {}
        };
        this.options = ['true', 'false'];
        this.updateAccount = this.updateAccount.bind(this);
        this.getWallet_by_id = this.getWallet_by_id.bind(this);
        this.getWallet_balance_by_id = this.getWallet_balance_by_id.bind(this);
        this.createWalletById = this.createWalletById.bind(this);
        // this.getTransactions_by_id = this.getTransactions_by_id.bind(this);
    }
 
    getWallet_by_id() { 
        this.state.viewWallet==false?this.setState({viewWallet:true}):this.setState({viewWallet:false})
        
    }
    getWallet_balance_by_id(){
        this.state.viewWalletBalance==false?this.setState({viewWalletBalance:true}):this.setState({viewWalletBalance:false})
    }
    createWalletById(id){
        this.props.dispatch(digitalWalletActions.createWalletById(id))
        .then((response) => {
          if(response.status){
            this.setState({wallet_address_by_id: response.wallet_address})
            toast.success("Wallet Successfully Created");
          }else if(response.wallet){
            this.setState({wallet_address_by_id: response.wallet_address})
            toast.error(response.errors);
          }else{
              //console.log('error::',response.errors)
            toast.error(response.errors);
          }
        });
        //console.log('id::id',id)
    }

    updateAccount(userData){
        const {accountById} = this.props;
        //const {errors} = this.props.errors;
        // console.log('{accountById}',accountById.id);
         let mergeData = {...accountById, ...userData};
  
        //let mergeData = accountById;
        this.props.dispatch(actions.updateAccountById(mergeData))
        .then((response) => {
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateAccountByIdSuccess(mergeData));
            this.setState({record:mergeData})
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
             //console.log(response)
            this.setState({errors:[response.errors[0]]});
          }
        });
        // .catch(errors =>this.setState({errors:[errors.errors[0]]}));
          
      //}).catch(errors =>console.log(errors.errors[0]))//this.setState({errors:[errors.errors[0]]}));//when you like to have only single array element
          // .then(errors =>this.setState({errors:[...this.state.errors, errors.errors[0]]}))//when you like to add
      }

    render() {
        const { errors } = this.state;
        const columns = [
            {
              name: 'Date',
              cell: row =>moment.unix(row.time).format("MM/DD/YYYY hh:mm:ss")
            },
            {
              name: 'Amount Received',
              cell: row =>row.amounts_received[0].amount + 'BTC'
            }]
        return (
            <div>
                <div className="modal fade" id="update-user-modal" width="80%">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update User</h4>
                                <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                {/* {this.props.initialValues.image_link && 
                                    <div class="img-preview-container">
                                        <div class="img-preview">
                                            <img src={'http://localhost:3006/users/'+this.props.initialValues.image_link} alt=""/>        
                                        </div>
                                    </div>
                                } */}
                                <AccountUserForm  
                                            submitCb={this.updateAccount} 
                                            errors={errors}
                                            options={this.options}
                                            initialValues ={this.props.initialValues}
                                            
                                            
                                            />

                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="update-wallet">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                               
                                <h4 className="modal-title">Wallet Information</h4>
                                <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="row">  
                                    <div className="col-6">
                                        {/* <AccountWalletForm result={this.props.initialValues.wallet_address_by_id}/> */}
                                        {this.props.initialValues.wallet_address_by_id && <div>
                                            <h2>Wallet </h2>
                                            <QRCode value={this.props.initialValues.wallet_address_by_id} />
                                            <br/>
                                            { this.state.viewWallet ? this.props.initialValues.wallet_address_by_id : null }
                                            <input type="submit" value="Address" onClick={()=>this.getWallet_by_id()} />
                                           
                                            {/* <input type="submit" value="Transactions" onClick={this.getTransactionsById} data-toggle="modal" data-target="#transaction-modal" /> */}
                                            </div>
                                        }
                                        {!this.props.initialValues.wallet_address_by_id && <div>
                                            <h2>Wallet </h2>
                                            {/* <QRCode value={this.props.initialValues.wallet_address} /> */}
                                    
                                            Please Create A Wallet Clicking the Button below
                                            <input type="submit" value="Create Wallet" onClick={()=>this.createWalletById(this.props.accountById.id)} />
                                            </div>}
                                    </div>
                                    <div className="col-6">
                                    <h2>Balance</h2>
                                    
                                        <h1>{ this.state.viewWalletBalance ? this.props.initialValues.wallet_balance_by_id : null }</h1>
                                        {/* <input type="submit" value="Balance" onClick={this.getWallet_balance_by_id} /> */}
                                        {/* <h1>{this.props.initialValues.wallet_balance_by_id}</h1> */}
                                        <h1>{this.props.digitalWalletUserAccountBalanceById.toString()}</h1>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="walletTransactions">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update wallet</h4>
                                <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <DataTable
                                    // title="Users"
                                    columns={columns}
                                    data={this.props.digitalWalletTransactionsById.data}
                                    // conditionalRowStyles={conditionalRowStyles}
                                    highlightOnHover
                                    /// pagination
                                    //paginationServer
                                    //paginationTotalRows={totalRows}
                                    // paginationPerPage={countPerPage}
                                    // paginationComponentOptions={{
                                    //     noRowsPerPage: true
                                    // }}
                                    //onChangePage={page => setPage(page)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UserUpdateModal.propTypes = {
    // updateAccount: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    // errors: PropTypes.object.isRequired
};

function mapStateToProps (state) {
    return{
    auth: state.auth,
    errors: state.errors,
    accountById: state.accountById.data,
    digitalWalletAccountById: state.digitalWalletAccountById,
    digitalWalletTransactionsById:state.digitalWalletTransactionsById,
    digitalWalletUserAccountBalanceById: state.digitalWalletUserAccountBalanceById.data,
    digitalWalletAccountBalance: state.digitalWalletAccountBalance.data,
    initialValues: {
        email:state.accountById.data.email,
        password: state.accountById.data.password_hash,
        first_name:state.accountById.data.first_name,
        middle_name:state.accountById.data.middle_name,
        last_name:state.accountById.data.last_name,
        cell_phone: state.accountById.data.cell_phone,
        image_link: state.accountById.data.image_link,
        blocked: state.accountById.data.blocked,
        active: state.accountById.data.active,
        // home_phone: state.account.data.home_phone,
        // address: state.account.data.address,
        // city: state.account.data.city,
        // region: state.account.data.region,
        // postal_code: state.account.data.postal_code,
        // country: state.account.data.country,
        // notes: state.account.data.notes,
        wallet_address_by_id: state.digitalWalletAccountById.data,
        // wallet_balance_by_id: JSON.stringify(state.digitalWalletUserAccountBalanceById.data),//this will also work
      }
    }
};

export default connect(
    mapStateToProps,
    // { updateAccount }
)(withRouter(UserUpdateModal));
