import React, {Component} from 'react';
import {UserGuard} from '../../shared/auth/UserGuard';
import * as actions from '../../../actions';
import * as digitalWalletActions from '../../../actions/digital-currency';
import AccountUserForm from './AccountUserForm';
import AccountDetailsForm from './AccountDetailsForm';
import Sidebar from '../../shared/Sidebar';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Back from "../../shared/navigation/Back";
import {withRouter,Redirect} from 'react-router-dom';
var QRCode = require('qrcode.react');
// import ErrorBoundary from "../../../errors/ErrorBoundary";

class AccountDetailsById extends Component{
    constructor(){
        super();
        this.state ={
          userId: '',///
          selectedUser:'',///
          response: {},
          isAllowed: false,
          isFetching: true,
          data:'',
          isLoading:false,
          errors: [],
          errorsDetail:[],
          redirect: false,
          isOwner: false,
          viewWallet: false,
          wallet_address: '',
          wallet_balance:'',
          transactions:'',
          cards:[],
        }
        this.genderCategories = ['Female'];//not used yet
        this.options=['true', 'false'];
        this.updateAccount = this.updateAccount.bind(this);
        this.updateAccountDetails = this.updateAccountDetails.bind(this);
        this.resetAccountErrors = this.resetAccountErrors.bind(this);
        this.verifyAccountOwner = this.verifyAccountOwner.bind(this);
        this.getWallet = this.getWallet.bind(this);
        this.createWalletById = this.createWalletById.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.deletePrivateCardImages = this.deletePrivateCardImages.bind(this);
       // this.isOwner = this.isOwner.bind(this);
    }
    
    componentWillMount(){
        // const serviceId = this.props.match.params.id;
        // console.log('userId :',this.props.match.params.userId)

      let userId = this.props.match.params.userId;
      this.setState({userId:userId})
      this.props.dispatch(actions.fetchAccountDetailsById(userId))
          .then((account)=>{
            account
          });
        this.props.dispatch(actions.fetchAccountUserById(userId))
         .then((res)=>{
           this.setState({ selectedUser: res});
         })
         .catch(error=>this.setState({isAllowed: true, isFetching: false,errors:error.response.data.errors, redirect: true}))

         this.props.dispatch(actions.getPrivateImagesById(userId))
          .then((images)=>{
            this.setState({cards:images})
          });
   
         this.props.dispatch(digitalWalletActions.fetchDigitalWalletAccountById(userId))
          .then((digitalWalletAccount)=>{
            digitalWalletAccount
          })
            

          this.props.dispatch(digitalWalletActions.fetchDigitalWalletAccountBalanceById(userId))
          .then((digitalWalletAccountBalance)=>{
            digitalWalletAccountBalance
            this.setState({wallet_balance:digitalWalletAccountBalance})
          });


        // this.props.dispatch(actions.fetchAccountDetails())
        //   .then((account)=>{
        //     account
        //   });
        // this.props.dispatch(actions.fetchAccountById())
        //   .then((account)=>{
        //     account
        //   });

        // this.props.dispatch(digitalWalletActions.fetchDigitalWallet())
        //   .then((digitalWalletAccount)=>{
        //     console.log('digitalWalletAccount',digitalWalletAccount)
        //     digitalWalletAccount
        //   });
        // this.props.dispatch(digitalWalletActions.fetchDigitalWalletBalance())
        // .then((digitalWalletAccountBalance)=>{
        //   console.log('digitalWalletAccountBalance',digitalWalletAccountBalance)
        //   digitalWalletAccountBalance
        //   this.setState({wallet_balance:digitalWalletAccountBalance})
        // });
    }

    componentDidMount(){
       this.verifyAccountOwner();
       //let user = this.isOwner();
      // this.getAccountDetail()
    }

    updateAccount(accountData){
      const {accountById} = this.props;
       console.log('accountById.id',accountById.id)
      let mergeData = {...accountById, ...accountData}
      // console.log('mergeData',mergeData)
      this.props.dispatch(actions.updateAccountById(mergeData))
      .then((response) => {
        if(response.status ==='success'){
          this.setState({errors:[]});
         // this.props.dispatch(actions.updateAccountByIdSuccess(mergeData));
          toast.success("Success");
          this.props.dispatch(actions.getPrivateImagesById(accountById.id))
            .then((images)=>{
              this.setState({cards:images})
          });
        }else{
          // console.log(response.errors)
          this.setState({errors:[response.errors[0]]})
        }
      })
    }

    updateAccountDetails(accountData){
      const {accountDetailsById} = this.props;
      let mergeData = {...accountDetailsById, ...accountData}
      console.log('mergeData',mergeData)
      this.props.dispatch(actions.updateAccountDetailsById(mergeData))
      .then((response) => {
        if(response.status ==='success'){
          toast.success("Success");
          this.setState({errors:[]});
         // this.props.dispatch(actions.updateAccountDetailsByIdSuccess(mergeData));
        }else{
          // console.log(response.errors)
          this.setState({errors:[response.errors[0]]})
        }
      })
      
    }

    // updateAccount(userData){
    //   const {accountById} = this.props;
    //   //const {errors} = this.props.errors;
    //   // console.log('{accountById}',accountById.id);
    //    let mergeData = {...accountById, ...userData};

    //   //let mergeData = accountById;
    //   this.props.dispatch(actions.updateAccountById(mergeData))
    //   .then((response) => {
    //     if(response.status ==='success'){
    //       this.setState({errors:[]});
    //       this.props.dispatch(actions.updateAccountByIdSuccess(mergeData));
    //       this.setState({record:mergeData})
    //       // this.props.action(this.state.record);
    //       toast.success("Success");
    //     }else{
    //        //console.log(response)
    //       this.setState({errors:[response.errors[0]]});
    //     }
    //   });

    // }


    // createWallet(){
    //   this.props.dispatch(digitalWalletActions.createWallet())
    //   .then((response) => {
    //     if(response.status){
    //       this.setState({wallet_address: response.wallet_address})
    //       toast.success("Wallet Successfully Created");
    //     }else if(response.wallet){
    //       this.setState({wallet_address: response.wallet_address})
    //       toast.error(response.errors);
    //     }else{
    //       toast.error('Something went wrong. Refresh Page');
    //     }
    
    //   });
    // }

    createWalletById(id){
      this.props.dispatch(digitalWalletActions.createWalletById(id))
      .then((response) => {
        if(response.status){
          this.setState({wallet_address_by_id: response.wallet_address})
          toast.success("Wallet Successfully Created");
        }else if(response.wallet){
          this.setState({wallet_address_by_id: response.wallet_address})
          toast.error(response.errors);
        }else{
            //console.log('error::',response.errors)
          toast.error(response.errors);
        }
      });
      //console.log('id::id',id)
  }

    resetAccountErrors(){
      this.props.dispatch(actions.resetAccountErrors());
    }

    verifyAccountOwner(){
      const {userId} = this.props.auth;
      this.setState({isFetching: true});
        return actions.verifyAccountOwner(userId)
            .then(()=>{
            this.setState({isAllowed: true, isFetching: false,isOwner:true})
            },
            ()=>{
            this.setState({isAllowed: false, isFetching: false})
            })
    }
    isOwner(){
      
    }

    getWallet(){ 
      this.state.viewWallet==false?this.setState({viewWallet:true}):this.setState({viewWallet:false})
      
    }
    getTransactions(id) {
      this.props.dispatch(digitalWalletActions.fetchDigitalWalletTransactionsById(id))
      .catch(error=>toast.error("Something Went Wrong"))  
    }

    deletePrivateCardImages(id,index){
      this.props.dispatch(actions.deletePrivateCardByIdImages(id,index))
      .then((response) => {
        if(response.status ==='success'){
          toast.success("Success");
          this.setState({errors:[]});
          this.props.dispatch(actions.getPrivateImagesById(id))
          .then((images)=>{
            this.setState({cards:images})
          });
        }
        else if(response.status ==='empty'){
          toast.error("There is no image");
        }
        else{
          console.log(response)
          this.setState({errors:[response.errors[0]]});
          toast.error('Something went wrong. Please Login');
        }
      });
    }


    render(){
      const {accountDetailsById} = this.props;
      const {isAllowed, isFetching, errors, errorsDetail, redirect} = this.state;
        
      if(redirect)
        return <Redirect to ={{pathname:'/dashboard', error : {errors}}}/>

        const columns = [
          {
            name: 'Date',
            // selector: 'time',
            cell: row =>moment.unix(row.time).format("MM/DD/YYYY hh:mm:ss")
          },
          {
            name: 'Amount Received',
            cell: row =>row.amounts_received[0].amount + 'BTC'
          }]
        if(accountDetailsById.account_id){
          // console.log('this.state.wallet_balance',this.props.wallet_balance)
          //console.log('this.props.accountDetailsById',this.props.accountDetailsById)
        return(

            <UserGuard isAllowed={isAllowed} isFetching={isFetching}> 
              <div className="d-flex" id="wrapper">
                <Sidebar/>
                <div className="container-fluid">
                <div className="row">
                        <div className="col-md-3 col-sm-3">
                        <Back/>
                        </div></div>
                  
                
                  <div className="accountDetail">
                    <section id='accountUserForm'>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                        
                          <h2>Account User</h2>
                          {this.props.initialValues.image_link && 
                                      <div className="img-preview-container">
                                          <div className="img-preview">
                                              {/* <img src={'http://localhost:3006/users/'+this.props.initialValues.image_link} alt=""/>   */}
                                              <img style={{height:"150px",width:"150px"}} src={process.env.REACT_APP_FILE_SERVER+'users/'+this.props.initialValues.image_link} alt=""/>         
                                          </div>
                                      </div>
                          }
                        </div>
                        {process.env.REACT_APP_CRYPTO_CURRENCY=="True" && <div className="col-md-6  col-sm-12">
                          <div className="row">
                            <div className="col-5">
                                    {this.props.initialValues.wallet_address && <div>
                                      <h2>Wallet </h2>
                                      <QRCode value={this.props.initialValues.wallet_address} />
                                      <br/>
                                      { this.state.viewWallet ? this.props.initialValues.wallet_address : null }
                                      <input type="submit" value="Address" onClick={this.getWallet} />
                                      <input type="submit" value="Transactions" onClick={()=>this.getTransactions(this.props.accountById.id)} data-toggle="modal" data-target="#transaction-modal" />
                                    </div>}

                                    {!this.props.initialValues.wallet_address && <div>
                                      <h2>Wallet </h2>
                                      {/* <QRCode value={this.props.initialValues.wallet_address} /> */}
                                      
                                      Please Create A Wallet Clicking the Button below
                                      <input type="submit" value="Create Wallet" onClick={()=>this.createWalletById(this.props.accountById.id)} />
                                    </div>}
                            </div>
                            <div className="col-1">
                                {this.props.initialValues.wallet_balance && <div>
                                  <h2 className="text-right" style={{'marginLeft': '1.5rem'}}>Balance</h2>
                                  <h3 style={{'fontSize': '5.5rem'}}> ${this.props.digitalWalletUserAccountBalanceById} </h3>   
                                    </div>}
                              

                                {/* { this.state.viewWallet ? this.props.initialValues.digitalWalletAccountBalance : null } */}
                                {/* {this.props.initialValues.wallet_balance } */}
                            </div>
                            </div>
                          </div> 
                          }
                           {process.env.REACT_APP_CRYPTO_CURRENCY=="False" && <div className="col-md-6  col-sm-12">
                          <div className="row">
                            <div className="col-5">

                            </div>
                            <div className="col-1">
                            {this.props.initialValues.wallet_balance && <div>
                                <h2 className="text-right" style={{'marginLeft': '1.5rem'}}>Balance</h2>
                                <h1 style={{'fontSize': '5.5rem'}}> ${this.state.wallet_balance} </h1>   
                                  </div>}
                            </div>
                          </div>
                      </div>
                      }
                      </div> 
                    </section>
                    <section id='accountUserForm'>
                      <div className="row">
                                <div className="col-12">
                                <AccountUserForm  
                                                    submitCb={this.updateAccount} 
                                                    errors={errors}
                                                    isOwner={this.state.isOwner}
                                                    options={this.options}
                                                    initialValues ={this.props.initialValues}
                                                    />
                                                  
                              </div>
                            </div>
                    </section>

                    {process.env.REACT_APP_ACCOUNT_DETAILS_FORM =='BD'?  <section id="national-card">
                      
                      {this.props.initialValues.national_card_image &&  
                                  
                            <div><h2>National Identification Card</h2>
                                <div className="row">
                                    {this.state.cards.map((card,i) => (
                                      
                                        <div key={i} className="col-3 mapImage">
                                            {/* <img className="national-id" src={process.env.REACT_APP_FILE_SERVER+'cards/'+this.props.initialValues.national_card_image[i]} alt="" width="120vh"/> */}
                                            {/* <img className="national-id" src={'http://localhost:8081/api/v1/files/file-service/private/uploads/cards/'+this.props.initialValues.national_card_image[i]} alt="" width="120vh"/> */}
                                            {/* <img className="national-id" src={()=>getPrivateImage(card)} alt="" width="120vh"/> */}
                                            <img className="national-id" 
                                              src={`data:image/jpeg;base64,${card}`} 
                                              alt="" width="120vh"
                                              
                                            />
                                      <a onClick={()=>this.deletePrivateCardImages(this.props.accountById.id,i)}>
                                        <span className="fa fa-trash" style={{color:'red'}}>&nbsp;</span>
                                        
                                      </a>
                                        </div>
                                ))}
                                </div>
                            </div>
                            }
                      </section>
                      :<div></div>
                    }
                    <section id='accountDetailForm'>
                            <div className="row">
                              <div className="col-12">
                                <h2>Account Details</h2>
                                <AccountDetailsForm  
                                  submitCb={this.updateAccountDetails} 
                                  options={this.genderCategories}
                                  errors={errorsDetail}
                                  initialValues ={this.props.initialValues}
                                />
                              </div>
                            </div>       
                    </section> 
                  </div>
                </div>


                  <div>
                    <div className="modal fade" id="transaction-modal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Transactions</h4>
                                    <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                
                                <DataTable
                                  // title="Users"
                                    columns={columns}
                                    data={this.props.digitalWalletTransactionsById.data}
                                    progressPending={isFetching}
                                  // conditionalRowStyles={conditionalRowStyles}
                                    highlightOnHover
                                  /// pagination
                                    //paginationServer
                                    //paginationTotalRows={totalRows}
                                  // paginationPerPage={countPerPage}
                                  // paginationComponentOptions={{
                                  //     noRowsPerPage: true
                                  // }}
                                    //onChangePage={page => setPage(page)}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              </div>
            </UserGuard> 
        )
        }else 
        return(
            <div>
              <h1>Loading...</h1>
            </div>
        )    
      }
    
}      

function mapStateToProps(state){
    return {
        auth: state.auth,
        // account: state.account.data,
        // account: state.account.data,
        // digitalWalletAccount: state.digitalWalletAccount.data,
        // digitalWalletAccountBalance: state.digitalWalletAccountBalance.data,

        accountById: state.accountById.data,
        accountDetailsById: state.accountDetailsById.data,
        digitalWalletAccountById: state.digitalWalletAccountById,
        digitalWalletTransactionsById:state.digitalWalletTransactionsById,
        digitalWalletUserAccountBalanceById: state.digitalWalletUserAccountBalanceById.data,
        digitalWalletAccountBalance: state.digitalWalletAccountBalance.data,

        initialValues: {
          email:state.accountById.data.email,
          password: state.accountById.data.password_hash,
          passwordConfirmation:state.accountById.data.password_hash,
          first_name:state.accountById.data.first_name,
          middle_name:state.accountById.data.middle_name,
          last_name:state.accountById.data.last_name,
          cell_phone: state.accountById.data.cell_phone,
          image_link: state.accountById.data.image_link,
          active: state.accountById.data.active,
          blocked: state.accountById.data.blocked,

          agent: state.accountById.data.agent,
          national_card_id: state.accountById.data.national_card_id,
          national_card_image: state.accountById.data.national_card_image,
          card: state.accountById.data.card,



          // home_phone: state.accountDetailsById.data.home_phone,
          address: state.accountDetailsById.data.address||'',
          city: state.accountDetailsById.data.city,
          region: state.accountDetailsById.data.region,
          postal_code: state.accountDetailsById.data.postal_code,
          country: state.accountDetailsById.data.country,
          country: process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?'Bangladesh':state.accountDetailsById.data.country,
          notes: state.accountDetailsById.data.notes,
          holding_no:state.accountDetailsById.data.holding_no,
          road_no:state.accountDetailsById.data.road_no,
          upazila:state.accountDetailsById.data.upazila,
          union_parishad:state.accountDetailsById.data.union_parishad,


          
          wallet_address: state.digitalWalletAccountById.data,
          wallet_balance: state.digitalWalletUserAccountBalanceById.data,
          //wallet_balance: state.selectedDigitalWalletAccount.data.available_balance,
          wallet_address_by_id: state.digitalWalletAccountById.data,

          privateImages: state.privateImages.data
        }
    }
}

export default connect(mapStateToProps)(AccountDetailsById);
