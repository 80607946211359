import { FETCH_CATEGORIES_SUCCESS, 
         FETCH_CATEGORIES_INIT,
         FETCH_CATEGORIES_FAIL, 
         FETCH_CATEGORY_BY_ID_INIT,
         FETCH_CATEGORY_BY_ID_SUCCESS,
         FETCH_CATEGORY_BY_ID_FAIL,
         UPDATE_CATEGORY_BY_ID_SUCCESS,
         UPDATE_CATEGORY_BY_ID_FAIL,
         RESET_CATEGORY_BY_ID_ERRORS,

         
         FETCH_CATEGORY_INIT,
         FETCH_CATEGORY_SUCCESS,
         FETCH_CATEGORY_FAIL,
         UPDATE_CATEGORY_SUCCESS,
         DELETE_CATEGORY_FAIL,
         UPDATE_CATEGORY_FAIL,
         DELETE_CATEGORY_SUCCESS,
         RESET_CATEGORY_ERRORS
      
        } from "../actions/products/types";

const INITIAL_STATE = {
    categories:{
        data:[],
        errors:[]
    },
    category:{
        data:{}
    }   
}

export const categoryReducer = (state=INITIAL_STATE.categories, action) =>{
    switch(action.type){
        case FETCH_CATEGORIES_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_CATEGORIES_SUCCESS:
            return {...state, data: action.categories }
        case FETCH_CATEGORIES_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        case DELETE_CATEGORY_SUCCESS:
            return {...state, 
            data:state.data.filter(x => x.id !== action.categories)
        }
        case DELETE_CATEGORY_FAIL:
            return Object.assign({}, state, {errors: action.errors});

        default:
            return state;
    }
}

export const selectedCategoryReducer = (state=INITIAL_STATE.category, action) =>{
    switch(action.type){
        case FETCH_CATEGORY_BY_ID_INIT:
            return {...state, data: {} }
        case FETCH_CATEGORY_BY_ID_SUCCESS:
            return {...state, data: action.category }
        case FETCH_CATEGORY_BY_ID_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_CATEGORY_BY_ID_SUCCESS:
            return {...state, data: action.category};
        case UPDATE_CATEGORY_BY_ID_FAIL:
            return {...state, errors: action.errors};
        case RESET_CATEGORY_BY_ID_ERRORS:
            return {...state, errors: []};
            
        case FETCH_CATEGORY_INIT:
            return {...state, data: {} }
        case FETCH_CATEGORY_SUCCESS:
            return {...state, data: action.category }
        case FETCH_CATEGORY_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_CATEGORY_SUCCESS:
            return {...state, data: action.category};
        case UPDATE_CATEGORY_FAIL:
            return {...state, errors: action.errors};
        case RESET_CATEGORY_ERRORS:
            return {...state, errors: []};
        default:
            return state;
    }
}