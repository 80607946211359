import React, {Component} from 'react';
import {ServiceCard} from './ServiceCard';

export class ServiceList extends Component{
    
    renderServices(){
        return this.props.services.map((service,i)=>{
            return(
                <ServiceCard key={i}
                    colNum="col-md-4 col-sm-6 col-6"
                    service={service}/>
            )
        })
    }
      
    render(){
        return(
            <div className="container">
                <div className="row">
                    {this.renderServices()}
                </div>
            </div>
            
        )
    }
}
