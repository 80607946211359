import React, {Fragment,useState, useEffect  } from "react";
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import Sidebar from '../shared/Sidebar';
import * as actions from '../../actions';
import * as digitalWalletActions from '../../actions/digital-currency';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import UserUpdateModal from "../shared/Modal/UserUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import {BwmResError} from '../shared/form/BwmResError';
import AccountDetailsById from "../account/account-detail/AccountDetailsById";

  function Agents() {

    const dispatch = useDispatch();
    const [users, setUsers] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedUserLink, setSelectedUserLink] = useState({});
    const [digitalWalletAccountById, setDigitalWalletAccountById] = useState({});
    const [digitalWalletAccountBalance, setDigitalWalletAccountBalance] = useState({});
    const [digitalWalletAccountTransactions_by_id, setDigitalWalletAccountTransactions_by_id] = useState({});
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [searchUsers, setSearchUsers] = useState({});
    const [countPerPage, setCountPerPage] = useState(10);
  
    // const FilterComponent = ({ filterText, onFilter, onClear }) => (
    //   <>
    //     <TextField id="search" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={onFilter} />
    //     <ClearButton type="button" onClick={onClear}>X</ClearButton>
    //   </>
    // );
    const columns = [
      {
        name: 'Avatar',
        cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-4x fa-user"></i>:<img height="60px" width="60px" src={process.env.REACT_APP_FILE_SERVER+'/users/'+row.image_link} />,
      },
      {
        name: 'First Name',
        selector: 'first_name',
      },
      {
        name: 'Last Name',
        selector: 'last_name',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Cell Phone',
        selector: 'cell_phone',
      },
      {
          name: 'Active',
          // selector: 'active',
          cell: row => row.active == true?'Yes': 'No',
      },
      {
          name: 'Blocked',
          // selector: 'blocked',
          cell: row => row.blocked == true?'Yes': 'No',
      },
      {
          name: 'Agent',
          // selector: 'blocked',
          cell: row => row.agent == true?'Yes': 'No',
        },
        {
          name: 'Actions',
          button: true,
          cell: row =>  (
              <Fragment>
                  {/* <button
                      data-toggle="modal"
                      data-target="#update-user-modal"
                      className="btn btn-primary btn-sm"
                      onClick={() => editRecord(row.id)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button> */}
                  {/* <button
                      className="btn btn-danger btn-sm"
                      onClick={() => this.deleteRecord(record)}>
                      <i className="fa fa-trash"></i>
                  </button> */}
                  <Link  to ={`/account/detail/${row.id}`}>
                    <button className="btn btn-primary btn-sm" value="Details"
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>  
                    </button>
                  </Link>
                  <button
                      data-toggle="modal"
                      data-target="#update-wallet"
                      className="btn btn-success btn-sm"
                      onClick={() => walletInfo(row.id)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-money"></i>
                  </button>
                  <button
                      data-toggle="modal"
                      data-target="#walletTransactions"
                      className="btn btn-warning btn-sm"
                      onClick={() => transactionsById(row.id)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-list"></i>
                  </button>
                  
              </Fragment>
          )
        },
    ];

    const conditionalRowStyles = [
      {
        when: row => row.active == false,
        style: {
          
          color: 'orange',
          '&:hover': {
            cursor: 'pointer',
          },
        }
      },
      {
        when: row => row.blocked == true,
        style: {
          
          color: 'red',
          '&:hover': {
            cursor: 'pointer',
          },
        }
      }
    ]

    // function editRecord(id) {
    //   dispatch(actions.fetchAccountUserById(id))
    //     .then((res)=>{
    //       setSelectedUser({ selectedUser: res});
    //     })
    //     .catch() 
    // }
    function walletInfo(id){
      dispatch(actions.fetchAccountUserById(id))
      dispatch(digitalWalletActions.fetchDigitalWalletAccountById(id))
      .catch(error=>toast.error("Something Went Wrong"))   
      dispatch(digitalWalletActions.fetchDigitalWalletAccountBalanceById(id))
    }

    function transactionsById(id){
      dispatch(digitalWalletActions.fetchDigitalWalletTransactionsById(id))
      .catch(error=>toast.error("Something Went Wrong"))     
    }
    
    function renderRow(user) {
      const index = users.findIndex((e)=> e.id === user.id);
      var newStateArray = users.slice();
      if (index === -1) {
          newStateArray.push(user);
      } else {
          newStateArray[index] = user;
          console.log('newStateArray2',newStateArray)
      }
      //setUsers(newStateArray);
      getUserList();
    }

    const handleSubmit=(event)=> {
      event.preventDefault();
      // const data = new FormData(event.target);
      console.log(`data ${searchUsers}`)
        dispatch(actions.searchAccounts(searchUsers))
        .then(res => {
          setUsers(res);
          setCountPerPage(1000);
          setTotalRows(res[0].total_count);
          console.log('reso', res);
        }).catch(err => {
          setUsers({});
        });
    }
    const getUserList = () => {
      //Todo use this function to get search user as well
      // if(Object.keys(searchUsers).length == 0){}
     
      dispatch(actions.fetchAgents(page, countPerPage))
      .then(res => {  
          setUsers(res);
          setCountPerPage(10);
          setTotalRows(res[0].total_count);
      }).catch(err => {
        setUsers({});
      });
    }
  
    useEffect(() => {
      getUserList();
    }, [page]);
  
    // <FilterComponent onFilter={e => setFilterText(e.target.value)}  />;

    return (
        <div className="d-flex" id="wrapper">   
        <Sidebar/>      
        {/* <UserUpdateModal record={selectedUser} action={renderRow}/> */}
        {/* <AccountDetailsById record={selectedUser} action={renderRow}/> */}
                    
         <ToastContainer/>


        <div className="container-fluid">
          <div className="row">
          
            <Link to="/account/create-account"><button className="btn btn-warning" style={{marginLeft:"20px"}} type="button">Add User</button></Link>
              
         
            <div className="ml-auto mr-4">
              <form className="form-inline" onSubmit={handleSubmit}>

                <label className="sr-only">Name</label>
                <input type="text"
                      className="form-control mb-2 mr-sm-2" 
                      id="inlineFormInputName2" placeholder="Name or Phone"
                      onChange={e =>{
                          if (e.target.value.match(/^\d/)) {
                            //Remove hyphens
                            let input = e.target.value.split("-").join("");
                            //Make a new string with the hyphens
                            // Note that we make it into an array, and then join it at the end
                            // This is so that we can use .map() 
                            input = input.split('').map(function(cur, index){
                              if(index == 3 || index == 6)
                                return "-" + cur;
                              else
                                return cur;
                            }).join('');
                            return setSearchUsers(input)
                          }else
                          setSearchUsers(e.target.value)
                        }
                      
                      }
                />
              
                <button type="submit" className="btn btn-primary mb-2">Search</button>
              </form>
            </div>
            
        </div>
        <DataTable
        // title="Users"
          columns={columns}
          data={users}
          conditionalRowStyles={conditionalRowStyles}
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={countPerPage}
          paginationComponentOptions={{
              noRowsPerPage: true
          }}
          onChangePage={page => setPage(page)}
          />
        </div>
      </div>
      
    );
  
  }
//   export default Users;
const mapStateToProps = state => ({
    auth: state.auth,
   // accounts: state.accounts
    // users: state.users,
});
// const mapDispatchToProps = dispatch =>{
//   return {
//     fetchAccountUserById:(id)=>dispatch(fetchAccountUserById(id)),
//     fetchDigitalWalletById:(id)=>dispatch(fetchDigitalWalletById(id))
//   }
// }

// const mapDispatchToProps = dispatch =>{
// return{
//     fetchDigitalWalletAccountById : (id)=>dispatch(digitalWalletActions.fetchDigitalWalletAccountById(id)),
//     fetchTransactionsById:(id)=>dispatch(digitalWalletActions.fetchTransactionsById(id))

// }}
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  // mapDispatchToProps
)(Agents);
