import React, {Component} from 'react';

import { connect } from 'react-redux';
// import ReactBootstrapCarousel from '../../home/Carousel';
import ServiceItemsByCategoryList from './ServiceItemsByCategoryList';
import Consult from '../../home/Consult';
// import Location from '../../home/Location';
import * as actions from '../../../actions';
import GoogleMap from '../../map/GoogleMap';

class ServiceItemsByCategoryListing extends Component{

    componentWillMount(){
        const serviceId = this.props.match.params.id;
        console.log('serviceId: ',serviceId)
        return this.props.dispatch(actions.fetchServiceItemsByCategory(serviceId));
      }

    render(){ 
        return(
            <div className="page-area">
                {/* <ReactBootstrapCarousel/> */}
                <section className="item-listing">
                    <ServiceItemsByCategoryList serviceItemsByCategory = {this.props.serviceItemsByCategory}/>
                </section>

                
                <Consult/>
                <GoogleMap/>
               
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        serviceItemsByCategory: state.serviceItemsByCategory.data
    }
}

export default connect(mapStateToProps)(ServiceItemsByCategoryListing)