import { FETCH_SERVICES_SUCCESS, 
         FETCH_SERVICE_BY_ID_SUCCESS,
         FETCH_SERVICE_BY_ID_INIT,
         FETCH_SERVICES_INIT,
         FETCH_SERVICES_FAIL, 

         FETCH_SERVICE_INIT,
         FETCH_SERVICE_SUCCESS,
         FETCH_SERVICE_FAIL,
         DELETE_SERVICE_SUCCESS,
         DELETE_SERVICE_FAIL,
         UPDATE_SERVICE_SUCCESS,
         UPDATE_SERVICE_FAIL,
         RESET_SERVICE_ERRORS
        } from "../actions/types";

const INITIAL_STATE = {
    services:{
        data:[],
        errors:[]
    },
    service:{
        data:{}
    }    
}

export const serviceReducer = (state=INITIAL_STATE.services, action) =>{
    switch(action.type){
        case FETCH_SERVICES_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_SERVICES_SUCCESS:
            return {...state, data: action.services }
        case FETCH_SERVICES_FAIL:
            return Object.assign({},{errors: action.errors, data:[]})
        case DELETE_SERVICE_SUCCESS:
            return {...state, 
            // data: action.product 
            data:state.data.filter(x => x.id !== action.services)
            }
        case DELETE_SERVICE_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})    
        default:
            return state;
    }
}

export const selectedServiceReducer = (state=INITIAL_STATE.service, action) =>{
    switch(action.type){
        case FETCH_SERVICE_BY_ID_INIT:
            return {...state, data: {} }
        case FETCH_SERVICE_BY_ID_SUCCESS:
            return {...state, data: action.service }
        case FETCH_SERVICE_INIT:
            return {...state, data: {} }     
        case FETCH_SERVICE_SUCCESS:
            return {...state, data: action.service }
        case FETCH_SERVICE_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_SERVICE_SUCCESS:
            return {...state, data: action.service};
        case UPDATE_SERVICE_FAIL:
            return {...state, errors: action.errors};
        case RESET_SERVICE_ERRORS:
            return {...state, errors: []};
        default:
            return state;
    }
}