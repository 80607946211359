import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions/products/index';
import 'react-toastify/dist/ReactToastify.css';
import BrandDetailForm from '../../products/product-listing/BrandDetailForm';

class BrandUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['','true', 'false'];
        this.updateBrand = this.updateBrand.bind(this);
        
    }
   
   

    updateBrand(BrandData){
        console.log('BrandData', BrandData)
        const {brand} = this.props;
        let mergeData = {...brand, ...BrandData};
        this.props.dispatch(actions.updateBrand(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateBrandSuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-brand-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Brand</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <BrandDetailForm  
                                    submitCb={this.updateBrand} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    // console.log('state',state.serviceItem.data)
    // console.log('brand.data',state.brand.data)
    return{
    auth: state.auth,
    errors: state.errors,
    brand: state.brand.data,
    categories: state.categories.data,
    subcategories: state.subcategories.data,
    brands: state.brands.data,
    // brands: state.brands.data,
     initialValues: {
        // sku: state.brand.data.sku,

        name: state.brand.data.name,
        image_link: state.brand.data.image_link,
        active: state.brand.data.active,
        // available: state.brand.data.available,
        short_description: state.brand.data.short_description,
        description: state.brand.data.description,
        updated_at: state.brand.data.updated_at,
        created_at: state.brand.data.created_at,
        // category_name: state.brand.data.category_name,
        // subcategory_name: state.brand.data.subcategory_name,
        // brand_name: state.brand.data.brand_name,
        sorting_no: state.brand.data.sorting_no,
        //  price: state.brand.data.price,
        //  msrp: state.brand.data.msrp,
        //  discount: state.brand.data.discount,
        //  units_stock: state.brand.data.units_stock,
        
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(BrandUpdateModal));
