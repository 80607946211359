import React from 'react';
import BrandCreateForm from './BrandCreateForm';
import * as actions from '../../../actions/products/index';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from '../../shared/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import Back from "../../shared/navigation/Back";
class BrandCreate extends React.Component{
    constructor(){
        super();
        this.state = {
            errors:[],
            services:'',
            redirect: false
        }
        this.booleanValue = ['','true', 'false'];
        this.createBrand = this.createBrand.bind(this);
     }
    // componentWillMount(){
    //     this.props.dispatch(actions.fetchCategories(1,100));
    //     this.props.dispatch(actions.fetchSubcategories(1,100));
    //     this.props.dispatch(actions.fetchBrands(1,100));
    // }

    // categories(){
    //     let result = this.props.categories.map(a=>a.name)
    //     return result
    // }

    // subcategories(){
    //     let result = this.props.subcategories.map(a=>a.name)
    //     return result
    // }

    // brands(){
    //     let result = this.props.brands.map(a=>a.name)
    //     return result
    // }
    createBrand(serviceItemData){
        actions.createBrand(serviceItemData)
        .then(response => {
            if(response.status ==='success'){
                this.setState({errors:[]});
                this.setState({redirect: true}),
                //  <Redirect to={{pathname:'/services'}}/>
                toast.success("Success");}
        },
        (errors) => {
            console.log('error',errors)
            this.setState({errors})
            toast.error('Status code: '+errors.status+' '+errors.data);
        }
        );
    }
    render(){
        // console.log('rrres',this.props.services)
        if(this.state.redirect){
            return <Redirect to={{pathname:'/products'}}/>
        }
        return(
            <div className="d-flex" id="wrapper">
          <Sidebar/>
            <div className="container">
            <section id='newRental'>
                <div className='bwm-form'>
                    <div className='row px-4'>
                        <div className='col-md-12'>
                            <Back />
                                <h1 className='page-title'>Create Brand</h1>
                                <BrandCreateForm submitCb={this.createBrand} 
                                                    options={this.booleanValue}
                                                    errors={this.state.errors}/>
                        </div>
                    </div>
                </div>
            </section>
            </div></div>
        )
    }
}


function mapStateToProps(state){
    return {
        auth: state.auth,
        // brands: state.brands.data
    }
}
export default connect(mapStateToProps)(BrandCreate);


