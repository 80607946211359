import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {getRangeOfDates} from '../../helpers';
import * as moment from 'moment';
import {toast} from 'react-toastify';
import { BookingModal } from './BookingModal';
import * as actions from '../../actions';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {BwmResError} from '../shared/form/BwmResError';
class Booking extends React.Component {

    constructor(){
        super();
        this.bookedOutDates = [];
        this.dateRef = React.createRef();
        this.state = {
            category:'',
            service:'',
            serviceItems:'',
            purchaseType:'',
            startDate:'',
            time:'',
            textMessage: '',
            submitted:false,
            proposedBooking:{
                startAt: '',
                endAt: '',
                guests:''
              
            },
            modal:{
                open: false
            },
            errors:[]
        }
        this.checkInvalidDates = this.checkInvalidDates.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelConfirmation = this.cancelConfirmation.bind(this);
        this.reserveRental = this.reserveRental.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.handleChangeService = this.handleChangeService.bind(this);
        this.handleChangeServiceItems =this.handleChangeServiceItems.bind(this);
        this.categoryList = this.categoryList.bind(this);
        this.serviceItemsList = this.serviceItemsList.bind(this);
        this.handlePurchaseType = this.handlePurchaseType.bind(this); 
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
    }
    // componentWillUpdate(){
    //     return this.props.dispatch(actions.fetchServiceItemsByCategory(1));
    //   }
    getBookedOutDates(){
        const {bookings} = this.props.service;
        //console.log('this.props.service::',this.props.service)
        if(bookings && bookings.length > 0){
            bookings.forEach(booking =>{
                const dateRange = getRangeOfDates(booking.startAt, booking.endAt, 'Y/MM/DD');
                this.bookedOutDates.push(...dateRange);
            });
        }
    }

    checkInvalidDates(date){
        //this.bookedOutDates.includes(date.format('Y/MM/DD')) || //this was unnecessary
        //date.day() === 1 //store closed Monday: [Sunday, Monday, Tues, Wed, Thurs,Fri, Sat]  
        return this.bookedOutDates.includes(date.format('Y/MM/DD')) || date.diff(moment(), 'days') < 0 || date.day() === 1;
    }

    handleApply(event, picker){
        const startAt = picker.startDate.format('LLLL');
        const startHour = picker.startDate.format('HH');
        const endAt = picker.endDate.format('Y/MM/DD');
        // const bookingTime = picker.startAt._d;
        // console.log('1:',moment(moment(startAt).valueOf()))
        // console.log('2:',moment(new Date().getTime()))
        
        // console.log('picker::',picker)
        this.dateRef.current.value = startAt ;
        // this.dateRef.current.value = startAt + ' to '+endAt;

        let timeDifference =  moment(startAt).valueOf() - moment(new Date().getTime());
        
        if(startHour < 10 || startHour >19 || timeDifference < 3600000){
            console.log('Hour error')
            this.setState({
                errors:['Appointment must be within Business Hours and at least 1 hour before curent time or Call to make an appointment within an hour']
            })
        }else this.setState({
            startDate:startAt,
            errors:[]
        })
     
        console.log(this.state)
    }

    selectGuests(event){
        this.setState({
            proposedBooking:{
                ...this.state.proposedBooking,
                guests: parseInt(event.target.value, 10)
            } 
        })
    }

    cancelConfirmation(){
        this.setState({
            modal:{
                open: false
            }
        })
    }
    addNewBookedOutDates(booking){
        const dateRange = getRangeOfDates(booking.startAt, booking.endAt);
        this.bookedOutDates.push(...dateRange);
    }

    resetDate(){
        this.dateRef.current.value ='';
        this.setState({proposedBooking:{guests:''}});
    }
    confirmProposedData(){
        const {startAt, endAt} = this.state.proposedBooking;
        const days = getRangeOfDates(startAt, endAt).length - 1; //not including 1st day
        const {service} = this.props;
        this.setState({
            // proposedBooking:{
            //     ...this.state.proposedBooking,
            //     days,
            //     totalPrice: days * service.dailyRate,
            //     service
            // },
            modal: {
                open: true
            }
        });
        //console.log(this.state.proposedBooking)
    }

    reserveRental(){
          actions.createBooking(this.state.proposedBooking)
            .then(
                (booking)=>{
                    this.addNewBookedOutDates(booking);
                    this.cancelConfirmation();
                    this.resetDate();
                    toast.success('Booking has been successfully created');
                },
                (errors)=>{
                    this.setState({errors})
                }
            )
    }

    handleChangeService(event) {
        this.setState({service: event.target.value});
        this.categoryList(event.target.value)
        //return this.props.dispatch(actions.fetchServiceItemsByCategory(1));
    }

    categoryList(value){
        // console.log('value::',value)
        this.props.dispatch(actions.fetchCategoriesByServiceName(value));
    }


    handleChangeCategory(event) {
        this.setState({category: event.target.value});
        this.serviceItemsList(event.target.value)
    }

    serviceItemsList(value){
        // console.log('value::',value)
        this.props.dispatch(actions.fetchServiceItemsByCategoryName(value));
    }

    handleChangeServiceItems(event) {
        this.setState({serviceItem: event.target.value});
    }
    
    handlePurchaseType(event) {
        this.setState({purchaseType: event.target.value});
    }

    handleSubmit(event) {
        // alert('A name was submitted: ' + this.dateRef.current.value + ' '+ this.state.service+ ' '+ this.state.category+ ' '+ this.state.serviceItems + ' '+ this.state.purchaseType + ' '+ this.state.textMessage);
        event.preventDefault();
        let bookingData = {};
        bookingData.date = this.dateRef.current.value;
        bookingData.service = this.state.service;
        bookingData.category = this.state.category;
        bookingData.serviceItem = this.state.serviceItem;
        bookingData.purchaseType = this.state.purchaseType;
        bookingData.textMessage =this.state.textMessage;
        bookingData.to_email =this.props.auth.email;
        
        console.log('bookingData', bookingData)
        
        // if(this.state.service === '' || this.state.category === '' || this.state.purchaseType === '' || this.state.serviceItem === ''){
        //     this.setState({
        //         errors:['Please Select all fields']
        //     })
        
        // }else this.setState({
        //     errors:[]
        // })

        actions.booking(bookingData)      
        .then(
            sent =>this.setState({errors:[], submitted: true}),
            toast.success("Success"),
            errors =>this.setState({errors})
        );
    }

    handleChangeMessage = (event) =>{
        this.setState({textMessage:event.target.value});
    }

    render() {
// console.log('this.state::',this.state)
// console.log('this.props::',this.props.categories)

        const {services,categories,serviceItems,  auth: { isAuth, email }}=this.props;//auth distructurized from this.props and isAuth from auth
        const {startDate,category,service,serviceItem,purchaseType, submitted}= this.state;
        const errors = this.state.errors;
        let servicesData     = services.map(a => a.name);
        let categoriesData     = categories.map(a => a.name);
        let serviceItemsData = serviceItems.map(a => a.name);
        // console.log('servicesData::',categoriesData)
        // console.log('serviceItemsData::',serviceItemsData)
        var MakeItem = function(X,i) {
            return (<option key={i}>{X}</option>);
        };

        return (
            
        <div className='booking'>
     
            {/* <h3 className='booking-price'>$ {service.dailyRate} <span className='booking-per-night'>per night</span></h3> */}
            <hr></hr>
            { !isAuth &&
                <Link className='btn btn-bwm btn-confirm btn-block' to={{pathname: '/login'}}>
                    Login to book place.
                </Link>
            }
            {   isAuth && !submitted &&
                 <React.Fragment>
                    <form onSubmit={this.handleSubmit}>
                    {errors.length>0 && <div className="alert alert-danger">{errors[0]}</div>}
                    <div className='form-group'>
                        <label htmlFor='dates'>Dates</label>
                        {/* <DateRangePicker onApply={this.handleApply} 
                                            isInvalidDate={this.checkInvalidDates} 
                                            opens='left' 
                                            containerStyles={{display:'block'}}>
                                <input ref={this.dateRef} id='dates' type='text' className='form-control'></input>
                        </DateRangePicker> */}
                        <DateRangePicker onApply={this.handleApply} 
                                            isInvalidDate={this.checkInvalidDates} 
                                            singleDatePicker= {true}
                                            timePicker= {true}
                                            timePickerIncrement={30}
                                            timePicker24Hour={true}
                                            opens='left' 
                                            containerStyles={{display:'block'}}>
                                <input ref={this.dateRef} id='dates' type='text' className='form-control' name="date"></input>
                        </DateRangePicker>
                       
                    </div>
                    {/* <div className='form-group'>
                        <label htmlFor='service'>Service</label>      
                        <select  className="form-control" onChange={this.handleChangeService} >
                            <option value="none" selected disabled hidden></option> 
                            {servicesData.map(MakeItem)}
                        </select>   
                    </div> */}




                    <div className="row">
                        <div className="col-6">
                            <div className='form-group'>
                            <label htmlFor='service'>Service</label>      
                            <select  className="form-control" onChange={this.handleChangeService} >
                                <option value="none" selected disabled hidden></option> 
                                {servicesData.map(MakeItem)}
                            </select>    
                            </div>
                        </div>
                        <div className="col-6"> 
                            <div className='form-group'>
                                <label htmlFor='purchase'>Purchase Type</label>
                            
                                <select  className="form-control" onChange={this.handlePurchaseType} >
                                    <option value="none" selected disabled hidden></option> 
                                    <option value="InStore">InStore</option>
                                    <option value="GroupOn">GroupOn</option>
                                </select>   
                            </div>
                        </div>
                    </div>




                    <div className="row">
                        <div className="col-6">
                            <div className='form-group'>
                                <label htmlFor='category'>Category</label>
                        
                                <select  className="form-control" onChange={this.handleChangeCategory} >
                                    <option value="none" selected disabled hidden></option>  
                                    {categoriesData.map(MakeItem)}
                                </select>   
                            </div>
                        </div>
                        <div className="col-6"> 
                            <div className='form-group'>
                                <label htmlFor='category'>Treatment</label>
                            
                                <select  className="form-control" onChange={this.handleChangeServiceItems} >
                                    <option value="none" selected disabled hidden></option> 
                                    {serviceItemsData.map(MakeItem)}
                                </select>   
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        
                        <div className="col-12"> 
                            <div className='form-group'>
                                <label htmlFor='message'>Message</label>
                                <textarea value={this.state.textMessage}
                                    style={{marginBottom:'10px'}}
                                    onChange={this.handleChangeMessage}
                                    className='form-control'
                                    placeholder='Optional message to your esthetician'
                                 
                                    rows={3}>
                                </textarea>
                            </div>
                        </div>
                    </div>

                    

                    {/* {errors.length>0 && <button disabled={true} className='btn btn-success btn-bwm btn-confirm btn-block' style={{color:'white'}}>Reserve place now</button>} */}
                   
                   {errors.length>0 ?<button disabled={errors.length>0} className='btn btn-success btn-bwm btn-confirm btn-block' style={{color:'white'}}>Request Appointment</button>:
                   <button disabled={!startDate || !category||!service|| !serviceItem||!purchaseType} className='btn btn-success btn-bwm btn-confirm btn-block' style={{color:'white'}}>Request Appointment</button>
                   }
                    {/* <button disabled={!startAt} className='btn btn-success btn-bwm btn-confirm btn-block' style={{color:'white'}}>Reserve place now</button> */}
                    {/* <BwmResError errors={errors} /> */}
                   
                    </form>
                    {/* <button disabled={!startAt || !endAt || !guests} onClick={()=>this.confirmProposedData()} className='btn btn-bwm btn-confirm btn-block'>Reserve place now</button> */}
                </React.Fragment>
            }

            {   isAuth && submitted &&
                <h1>Email Sent Successfully</h1>   
            }

            <hr></hr>
            <p className='booking-note-title'>The Appoinment time that you are requesting here is not confirmed.</p>
            <p className='booking-note-text'>
            You will receive a confirmation within 1 to 2 hours of our Business time.
            </p>
            
            <BookingModal open={this.state.modal.open} 
                            closeModal={this.cancelConfirmation}
                            booking={this.state.proposedBooking}
                            // servicePrice={service.dailyRate}
                            confirmModal={this.reserveRental}
                            errors={this.state.errors}
                            
                            />

        </div>
        )
    }
}
function mapStateToProps(state) {
    return {
      auth: state.auth,
      categories: state.categories.data,
      serviceItems: state.serviceItems.data,
    }
}

export default connect(mapStateToProps)(Booking)

