import React, {Component} from 'react';
import {ServiceItemCard} from './ServiceItemCard';

export default class ServiceItemsByCategoryList extends Component{
    
    renderServiceItems(){
        //console.log(this.props.serviceItemsByCategory)
        let data = this.props.serviceItemsByCategory;
        let groupBy = "category_name";
        let categories={};
       
        for (var i = 0; i < data.length; i++) {
            if (!categories[data[i][groupBy]])
                categories[data[i][groupBy]] = [];
            categories[data[i][groupBy]].push(data[i]);
        };
       // console.log('categories:',categories)
        
        return Object.keys(categories).map((category, index) => {
            console.log('Laser Type ::',category)
            console.log('Laser Type image ::',categories[category][0].category_banner_image_link)
            return(
                <div className="category-list">
                    <div className="container">
                    <div className="row">
                        <img style={{height:'auto',width:'100%'}} src={categories[category][0].category_banner_image_link} alt=""/> 
                    </div>  
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div style={{textAlign:'center', fontSize:'2em', fontFamily:'Oswald', color:'#3c3c3c', margin:'20px'}}>{category}
                            </div>
                            
                            </div>
                        </div>
                    </div>
                   
                    
                    {<ServiceItemCard key={index}
                        categories={categories[category]}/>
                     }
                </div>
                     
            )
        });
    }
      
    render(){
        let data = this.props.serviceItemsByCategory;
        return(
             <div className="container">
            
               {this.renderServiceItems()}
             
          

            </div>
            
        )
    }
}