import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions/products/index';
import 'react-toastify/dist/ReactToastify.css';
import CategoryDetailForm from '../../products/product-listing/CategoryDetailForm';

class CategoryUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['','true', 'false'];
        this.updateCategory = this.updateCategory.bind(this);
        
    }
   
   

    updateCategory(CategoryData){
        console.log('CategoryData', CategoryData)
        const {category} = this.props;
        let mergeData = {...category, ...CategoryData};
        this.props.dispatch(actions.updateCategory(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateCategorySuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-category-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Category</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <CategoryDetailForm  
                                    submitCb={this.updateCategory} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    return{
    auth: state.auth,
    errors: state.errors,
    category: state.category.data,
    categories: state.categories.data,
     initialValues: {
        name: state.category.data.name,
        image_link: state.category.data.image_link,
        active: state.category.data.active,
        short_description: state.category.data.short_description,
        description: state.category.data.description,
        updated_at: state.category.data.updated_at,
        created_at: state.category.data.created_at,
        sorting_no: state.category.data.sorting_no,
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(CategoryUpdateModal));
