import React, {Component} from 'react';
import Events from "./Events";
import Sidebar from '../../shared/Sidebar';
// import {Link} from 'react-router-dom';

class EventListing extends Component{
    constructor() {
        super();
        this.state = {
          name: "React",
          showHideDemo1: true,
          
        //   showHideDemo2: true
        };
        this.hideComponent = this.hideComponent.bind(this);
       
    }

      hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ 
                showHideDemo1: !this.state.showHideDemo1, 
                // showHideDemo2: false,
               
            });
            break;
            //TODO: case left for future service event
        //   case "showHideDemo2":
        //     this.setState({ 
        //         showHideDemo2: !this.state.showHideDemo2,
        //         showHideDemo1: false,
                
        //     });
        //     break;
         
          default:
            null;
        }
      }
    // componentWillMount(){
    //     return this.props.dispatch(actions.fetchProducts());
    //   }
  
    render(){ 
    

            // const props = this.props.products;
            //console.log('props',this.props)
                const { showHideDemo1/*, showHideDemo2*/  } = this.state;
                return (
                    <div className="d-flex" id="wrapper">
                        <Sidebar/>
                        <div  id="page-content-wrapper">
                            <h1 className="text-primary">Events</h1>
                            <div className="container-fluid">
                                <div className="row px-2">
                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-primary text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Events</h5>
                                                <p className="card-text">List of Events</p>
                                                <button className="btn btn-light" onClick={() =>this.hideComponent("showHideDemo1")}>Events</button>
                                        
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-info text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Create Product Event</h5>
                                                <p className="card-text">Create a new Product Event</p>
                                                <button className="btn btn-warning" onClick={() => this.hideComponent("showHideDemo2")}>Category</button>
                                        
                                            </div>
                                        </div>
                                    </div> */}

                                    

                                    
                                </div>
                                <div className="row px-2">
                                    {showHideDemo1 && <Events/> }
                                    <hr />
                                    {/* {showHideDemo2 && <Categories />} */}
                                    {/* <hr /> */}
                                   
                                </div>
                            </div>
                        </div>
                       
                    
                    </div>
                 
                );
              }
             
}

// function mapStateToProps(state){
//     return {
//         products: state.products.data
//     }
// }

// export default connect(mapStateToProps)(ProductListing)
export default EventListing;