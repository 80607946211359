import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmCheckbox,BwmInputDisabled} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
import {BwmSubcategoryImageUpload} from '../../shared/form/BwmSubcategoryImageUpload';

import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,text,numeric,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

let init = {}
const SubcategoryDetailForm = props => {
  const { handleSubmit, pristine,reset, submitting, submitCb, valid, options, categories, errors,initialValues } = props;
  init = initialValues
 console.log('Init', init)
  return (
    <form onSubmit={handleSubmit(submitCb)} >

      <div className="row">
      
        <div className="col-md-6">
          <Field
              name="name"
              type="text"
              label='Subcategory Name'
              className='form-control'
              component={BwmInput}
              validate={[required]}
          />
          <Field
              name="short_description"
              type="text"
              label='Short Description'
              className='form-control'
              component={BwmInput}
              validate={[required]}
          />
          <Field
            name="description"
            type="text"
            label='Description'
            className='form-control'
            component={BwmTextArea}
            validate={[required]}
          />
   
          <Field
              options={options}
              name="active"
              type="text"
              label='Active'
              className='form-control'
              component={BwmSelect}
              values={init.active}
              validate={[required]}
          />
        
          <Field
              options={categories}
              name="category_name"
              type="text"
              label='Category'
              className='form-control'
              component={BwmSelect}
              values={init.category_name}
              validate={[required]}
          />
        
          <Field
              name="updated_at"
              type="text"
              label='Updated'
              className='form-control'
              component={BwmInputDisabled}
          />
          <Field
              name="created_at"
              type="text"
              label='Created'
              className='form-control'
              component={BwmInputDisabled}
          /> 
    
        </div>
        <div className="col-md-6">
          <Field
              name="sorting_no"
              type="number"
              label='Soting No'
              className='form-control'
              component={BwmInput}
                validate={[numeric]}
          />
           
          <Field
              name="image_link"
              type="file"
              label='Image'
              className='form-control'
              component={BwmSubcategoryImageUpload}
          />
        </div>
      </div>
      <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={pristine || submitting}>
      Save 
      </button>
      <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
      <BwmResError errors={errors} />
    </form>
  )
}
  
  export default reduxForm({
    form: 'subcategoryDetailForm',initialValues:{init},enableReinitialize: true
      
  })(SubcategoryDetailForm)