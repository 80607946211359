import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmCheckbox,BwmInputDisabled} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
import {BwmServiceItemImageUpload} from '../../shared/form/BwmServiceItemImageUpload';

import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,text,numeric,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

let init = {}
const ServiceItemDetailForm = props => {
  const { handleSubmit, pristine,reset, submitting, submitCb, valid, options, services,serviceCategories,serviceAreas, errors,initialValues } = props;
  init = initialValues

  return (
    <form onSubmit={handleSubmit(submitCb)} >

      <div className="row">
            <div className="col-md-6">
                <Field
                    name="name"
                    type="text"
                    label='Service Item'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                <Field
                    name="short_description"
                    type="text"
                    label='Short Description'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                  <Field
                    name="description"
                    type="text"
                    label='Description'
                    className='form-control'
                    component={BwmTextArea}
                    validate={[required]}
                />
                <div className="row">
                  <div className="col-6">
                  <Field
                      name="price_package_1"
                      type="number"
                      label='Package 1'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="price_package_2"
                      type="number"
                      label='Package 2'
                      className='form-control'
                      component={BwmInput}
                       validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="price_package_4"
                      type="number"
                      label='Package 4'
                      className='form-control'
                      component={BwmInput}
                      // validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="price_package_6"
                      type="number"
                      label='Package 6'
                      className='form-control'
                      component={BwmInput}
                      // validate={[numeric]}
                  />
                  </div>
                </div>
                
                 <Field
                    options={options}
                    name="active"
                    type="text"
                    label='Active'
                    className='form-control'
                    component={BwmSelect}
                    values={init.active}
                    // validate={[required]}
                />
                <Field
                    options={serviceCategories}
                    name="service_category_name"
                    type="text"
                    label='Service Category'
                    className='form-control'
                    component={BwmSelect}
                    values={init.service_category_name}
                    validate={[required]}
                />
                <Field
                    options={serviceAreas}
                    name="service_area_name"
                    type="text"
                    label='Service Area'
                    className='form-control'
                    component={BwmSelect}
                    values={init.service_area_name}
                    validate={[required]}
                />
                <Field
                    options={services}
                    name="service_name"
                    type="text"
                    label='Service'
                    className='form-control'
                    component={BwmSelect}
                    values={init.service_name}
                    validate={[required]}
                />
                <Field
                    name="updated_at"
                    type="text"
                    label='Updated'
                    className='form-control'
                    component={BwmInputDisabled}
                    
                />
                <Field
                    name="created_at"
                    type="text"
                    label='Created'
                    className='form-control'
                    component={BwmInputDisabled}
                /> 

              
            </div>
            <div className="col-md-6">    
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmServiceItemImageUpload}
                />
            </div>
            </div>
            <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={pristine || submitting}>
          Save 
        </button>
        <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
        <BwmResError errors={errors} />
      </form>
  )
}
  
  export default reduxForm({
    form: 'serviceItemDetailForm',initialValues:{init},enableReinitialize: true
      
  })(ServiceItemDetailForm)