import React, { Component } from 'react';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';

import {Provider} from "react-redux";
import {ToastContainer} from 'react-toastify';
import Header from './components/shared/Header';

import Footer from './components/shared/Footer';
import ServiceListing from './components/services/service-listing/ServiceListing';
import ProductListing from './components/products/product-listing/ProductListing';
import EventListing from './components/events/event-listing/EventListing';
import ServiceItemsByCategoryListing from './components/services/service-item-listing/ServiceItemsByCategoryListing';
// import ServiceSearchListing from './components/services/service-listing/ServiceSearchListing';
//import ServiceDetail  from './components/services/service-detail/ServiceDetail';
import ServiceUpdate  from './components/services/service-detail/ServiceUpdate';
import Register from './components/register/Register';
import ContactUs from './components/contact/ContactUs';
import Booking from './components/booking/BookingPage';
import {ServiceManage} from './components/services/service-manage/ServiceManage';
// import BookingManage from './components/booking/booking-manage/BookingManage';
import AccountDetails from './components/account/account-detail/AccountDetails';
import AccountDetailsById from './components/account/account-detail/AccountDetailsById';
import CreateAccount from './components/account/account-detail/CreateAccount';
import ServiceCreate from './components/services/service-create/ServiceCreate';
import ServiceCategoryCreate from './components/services/service-create/ServiceCategoryCreate';
import ServiceAreaCreate from './components/services/service-create/ServiceAreaCreate';
import ServiceItemCreate from './components/services/service-create/ServiceItemCreate';


import ProductCreate from './components/products/product-create/ProductCreate';
import BrandCreate from './components/products/brand-create/BrandCreate';
import CategoryCreate from './components/products/category-create/CategoryCreate';
import SubcategoryCreate from './components/products/subcategory-create/SubcategoryCreate';
import EventCreate from './components/events/event-create/EventCreate';
import EventDetailsById from './components/events/event-details/EventDetailsById';
import Login from './components/login/Login';
import User from './components/users/Users';
import Agent from './components/agents/Agents';
import Dashboard from './components/dashboard/Dashboard';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import ResetPassword from './components/resetPassword/ResetPassword';
import ActivateAccount from './components/account/ActivateAccount';  
import {ProtectedRoute} from './components/shared/auth/ProtectedRoute';
import {LoggedInRoute} from './components/shared/auth/LoggedInRoute';

import * as actions from './actions';
import './App.css';

const store = require('./reducers').init();

class App extends Component {
  componentWillMount(){
    this.checkAuthState();
  }
  checkAuthState(){
    store.dispatch(actions.checkAuthState());
  }
 
  logout(){
    store.dispatch(actions.logout());
  }
  render() {
 
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className='App'>
            <ToastContainer/>
            
            <Header logout={this.logout}/>
           
            {/* <div className='container'> */}
              <Switch>
                <Route exact path='/' render={()=><Redirect to='/login'/>}/>
                <Route exact path='/dashboard' component={Dashboard}/>
                <ProtectedRoute exact path="/users" component={User} />
                <ProtectedRoute exact path="/agents" component={Agent} />
                {/* <ProtectedRoute exact path="/services" component={ServiceListing} /> */}
                <Route exact path="/services" component={ServiceListing} />
                <Route exact path="/products" component={ProductListing} />
                <Route exact path="/events" component={EventListing} />


                {/* <Route exact path='/services/:city/homes' component={ServiceSearchListing}/> */}
                <Route exact path='/services/find_all_service_items_by_category/:id' component={ServiceItemsByCategoryListing}/>


                <ProtectedRoute exact path='/services/manage' component={ServiceManage}/>
                {/* <ProtectedRoute exact path='/bookings/manage' component={BookingManage}/> */}
                <ProtectedRoute exact path='/account/detail' component={AccountDetails}/>
                <ProtectedRoute exact path='/account/detail/:userId' component={AccountDetailsById}/>
                <ProtectedRoute exact path='/account/create-account' component={CreateAccount}/>


                <ProtectedRoute exact path='/services/create-service' component={ServiceCreate}/>
                <ProtectedRoute exact path='/services/create-service-category' component={ServiceCategoryCreate}/>
                <ProtectedRoute exact path='/services/create-service-area' component={ServiceAreaCreate}/>
                <ProtectedRoute exact path='/services/create-service-item' component={ServiceItemCreate}/>

                <ProtectedRoute exact path='/products/create-product' component={ProductCreate}/>
                <ProtectedRoute exact path='/products/create-brand' component={BrandCreate}/>
                <ProtectedRoute exact path='/products/create-category' component={CategoryCreate}/>
                <ProtectedRoute exact path='/products/create-subcategory' component={SubcategoryCreate}/>
                
                <ProtectedRoute exact path='/events/event_add' component={EventCreate}/>
                <ProtectedRoute exact path='/events/details/:eventId' component={EventDetailsById}/>
                {/* <ProtectedRoute exact path='/products/create-event' component={EventCreate}/> */}
                

                {/* <Route exact path='/services/:id/edit' component={ServiceUpdate}/> */}
                <LoggedInRoute exact path='/register' component={Register}/>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/forgot-password' component={ForgotPassword}/>
                <Route exact path='/contactus' component={ContactUs}/>
                <Route exact path='/booking' component={Booking}/>
                <Route exact path='/reset-password/:activation_key' component={ResetPassword}/>
                <Route exact path='/activation_key/:activation_key' component={ActivateAccount}/>
              </Switch>
            {/* <Footer/>   */}
            {/* </div> */}

          </div>
        </BrowserRouter>
      </Provider>  
    );
  }
}

export default App;
