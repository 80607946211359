import { 
         FETCH_SERVICE_AREA_INIT,
         FETCH_SERVICE_AREA_SUCCESS,
         FETCH_SERVICE_AREA_FAIL,
         UPDATE_SERVICE_AREA_SUCCESS,
         UPDATE_SERVICE_AREA_FAIL,
         RESET_SERVICE_AREA_ERRORS,
         FETCH_SERVICE_AREAS_INIT,
         FETCH_SERVICE_AREAS_SUCCESS,
         FETCH_SERVICE_AREAS_FAIL,
         DELETE_SERVICE_AREA_SUCCESS,
         DELETE_SERVICE_AREA_FAIL
        } from "../actions/types";

const INITIAL_STATE = {
    serviceAreas:{
        data:[],
        errors:[]
    },
    serviceArea:{
        data:{}
    }   
}

export const serviceAreasReducer = (state=INITIAL_STATE.serviceAreas, action) =>{
    switch(action.type){
        case FETCH_SERVICE_AREAS_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_SERVICE_AREAS_SUCCESS:
            return {...state, data: action.serviceAreas }
        case FETCH_SERVICE_AREAS_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        case DELETE_SERVICE_AREA_SUCCESS:
            return {...state, 
                // data: action.product 
                data:state.data.filter(x => x.id !== action.serviceAreas)
            }
        case DELETE_SERVICE_AREA_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]}) 
        default:
            return state;
    }
}

export const selectedServiceAreaReducer = (state=INITIAL_STATE.serviceArea, action) =>{
    switch(action.type){
        case FETCH_SERVICE_AREA_INIT:
            return {...state, data: {} }
        case FETCH_SERVICE_AREA_SUCCESS:
            return {...state, data: action.serviceArea }
        case FETCH_SERVICE_AREA_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_SERVICE_AREA_SUCCESS:
            return {...state, data: action.serviceArea};
        case UPDATE_SERVICE_AREA_FAIL:
            return {...state, errors: action.errors};
        case RESET_SERVICE_AREA_ERRORS:
            return {...state, errors: []};
        default:
            return state;
    }
}