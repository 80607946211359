import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import ServiceAreaUpdateModal from "../../shared/Modal/ServiceAreaUpdateModal";
import ServiceAreaDeleteModal from "../../shared/Modal/ServiceAreaDeleteModal";
import * as actions from '../../../actions';
import {Link} from 'react-router-dom';
export const ServiceArea =({servicesData}) =>{
  const dispatch = useDispatch();

  const [serviceArea, setServiceArea] = useState(servicesData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedServiceArea, setSelectedServiceArea] = useState({});
  

  const columns = [
    {
      name: 'Service Area Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/service-area/'+row.image_link} />,
    },
    {
      name: 'Name',
      selector: 'name',
    },
    {
        name: 'Active',
        // selector: 'active',
        cell: row => row.active == true?'Yes': 'No',
      },
      
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-service-area-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-service-area-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchServiceArea(id))
    .then((res)=>{
      setSelectedServiceArea({ selectedServiceArea: res});
    })
    .catch()    
  }
  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteProduct(id))
    dispatch(actions.fetchServiceArea(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedServiceArea({ selectedServiceArea: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = serviceArea.findIndex((e)=> e.id === service.id);
    var newStateArray = serviceArea.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(serviceArea !== null || serviceArea !== undefined)
      getServiceAreaList(); 
    else return null;
  }

  const getServiceAreaList = () => {  
    dispatch(actions.fetchServiceAreas(page, countPerPage))
    .then(res => {
      setServiceArea(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setServiceArea({});
    });
  }
  
  useEffect(() => {
    getServiceAreaList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/services/create-service-area"><button class="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Service Area</button></Link>
      <div className="row px-4">
        {serviceArea && <ServiceAreaUpdateModal record={selectedServiceArea} action={renderRow}/>}
        {serviceArea && <ServiceAreaDeleteModal record={selectedServiceArea} action={renderRow}/>}
        <ToastContainer/>
        {serviceArea && 
          <DataTable
            title="Services Area"
            columns={columns}
            data={serviceArea}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
          />
        }

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  servicesData: state.services.data
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    fetchServiceArea:(id)=>dispatch(fetchServiceArea(id))
  }
}
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  mapDispatchToProps
)(ServiceArea);
// export default connect(mapStateToProps)(Demo1)