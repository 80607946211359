import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import CategoryUpdateModal from "../../shared/Modal/CategoryUpdateModal";
import CategoryDeleteModal from "../../shared/Modal/CategoryDeleteModal";
import * as actions from '../../../actions/products/index';
import {Link} from 'react-router-dom';
export const Categories =({categoryData}) =>{
  const dispatch = useDispatch();

  const [category, setCategory] = useState(categoryData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState({});
  

  const columns = [
    {
      name: 'Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/category/'+row.image_link} />,
    },
  
    {
      name: 'Category',
      selector: 'name',
    },
    
    {
        name: 'Active',
        cell: row => row.active == true?'Yes': 'No',
      },
      
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-category-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-category-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchCategory(id))
    .then((res)=>{
       console.log('res',res)
      setSelectedCategory({ selectedCategory: res});
    })
    .catch()    
  }

  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteCategory(id))
    dispatch(actions.fetchCategory(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedCategory({ selectedCategory: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = category.findIndex((e)=> e.id === service.id);
    var newStateArray = category.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(category !== null || category !== undefined)
      getCategoryList(); 
    else return null;
  }

  function renderRows() {
    if(category !== null || category !== undefined)
      getCategoryList(); 
    else return null;
  }

  const getCategoryList = () => {  
    dispatch(actions.fetchCategories(page, countPerPage))
    .then(res => {
      setCategory(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setCategory({});
    });
  }
  
  useEffect(() => {
    getCategoryList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/products/create-category"><button className="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Category</button></Link>
      <div className="row px-4">  
        {category && <CategoryUpdateModal record={selectedCategory} action={renderRow}/>}
        {category && <CategoryDeleteModal  record={selectedCategory} action={renderRows}/>}
        <ToastContainer/>
        
        {category && 
        <DataTable
        title="Categories"
        columns={columns}
        data={category}
        // conditionalRowStyles={conditionalRowStyles}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onChangePage={page => setPage(page)}
        />
      }
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  categoryData: state.services.data
  }
}

// const mapDispatchToProps = dispatch =>{
//   return {
//     fetchcategory:(id)=>dispatch(fetchcategory(id))
//   }
// }
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  // mapDispatchToProps
)(Categories);
