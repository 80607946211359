import React from 'react';
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../actions';
import {Link} from 'react-router-dom';

class Login extends React.Component {

  constructor() {
    super();
    this.loginUser = this.loginUser.bind(this);
  }

 loginUser(userData){
   this.props.dispatch(actions.login(userData));
     //console.log(userData)
 }

  render() {
    const {isAuth, errors} = this.props.auth;
    const {successRegister} = this.props.location.state || false;
    const {successActivate} = this.props.location.state || false;
    const {successActivateDanger} = this.props.location.state || false;

    console.log('ErrorNo',errors)
    if(isAuth){
      return <Redirect to={{pathname:'/dashboard'}}/>
    }

    return (
      <div className="d-flex justify-content-center">
        <section id="login">
          <div className="bwm-form">
            <div className="row">
              <div className="col-md-12">
                <h1>Login</h1>
                {
                  successRegister &&
                    <div className='alert alert-success'>
                      <p> Please Check Your Email to Activate Your Account</p>
                    </div>
                } 
                {
                  successActivate &&
                  <div className='alert alert-success'>
                    <p> Your Account Activated. Please Login</p>
                  </div>
                }
                {
                  successActivateDanger &&
                  <div className='alert alert-danger'>
                    <p> Either Activation Key does not exist or it has been expired!</p>
                  </div>
                }
                <LoginForm submitCb={this.loginUser} errors = {errors}/>
                Not registered yet? Please Signup 
                <Link className='' to='/register'> here. </Link>
                Click here 
                <Link className='' to='/forgot-password'> Forgot password?</Link>
              </div>
              
              {/* <div className="col-md-6 ml-auto">
                <div className="image-container">
                  <h2 className="catchphrase">Let's Start What You Always Dreaming for</h2>
                  <img src={process.env.PUBLIC_URL + '/img/page/login.jpg'} alt=""/> 
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    )
  }
} 

function mapStateToProps(state){
  return{
    auth: state.auth
  }
}

export default connect(mapStateToProps)(Login)