import React, {Component} from 'react';
// import {ServiceList} from './ServiceList';
// import { connect } from 'react-redux';
import * as actions from '../../../actions/products/index';
import GoogleMap from '../../map/GoogleMap';

import Sidebar from '../../shared/Sidebar';
import {Link} from 'react-router-dom';
import Brands from "./Brands";
import Categories from "./Categories";
import Subcategories from "./Subcategories";
import Products from "./Products";

class ProductListing extends Component{
    constructor() {
        super();
        this.state = {
          name: "React",
          showHideDemo1: false,
          showHideDemo2: false,
          showHideDemo3: false,
          showHideDemo4: true
        };
        this.hideComponent = this.hideComponent.bind(this);
       
    }

      hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ 
                showHideDemo1: !this.state.showHideDemo1, 
                showHideDemo2: false,
                showHideDemo3: false,
                showHideDemo4: false
            });
            break;
          case "showHideDemo2":
            this.setState({ 
                showHideDemo2: !this.state.showHideDemo2,
                showHideDemo1: false,
                showHideDemo3: false,
                showHideDemo4: false
            });
            break;
          case "showHideDemo3":
            this.setState({ 
                showHideDemo3: !this.state.showHideDemo3,
                showHideDemo1: false,
                showHideDemo2: false,
                showHideDemo4: false
            });
            break;
            case "showHideDemo4":
            this.setState({ 
                showHideDemo4: !this.state.showHideDemo4,
                showHideDemo1: false,
                showHideDemo2: false,
                showHideDemo3: false
            });
            break;
          default:
            null;
        }
      }
    // componentWillMount(){
    //     return this.props.dispatch(actions.fetchProducts());
    //   }
  
    render(){ 
    

            // const props = this.props.products;
            //console.log('props',this.props)
                const { showHideDemo1, showHideDemo2, showHideDemo3, showHideDemo4  } = this.state;
                return (
                    <div className="d-flex" id="wrapper">
                        <Sidebar/>
                        <div  id="page-content-wrapper">
                            <h1 className="text-primary">Products</h1>
                            <div className="container-fluid">
                                <div className="row px-2">
                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-primary text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Brands</h5>
                                                <p className="card-text">Manufacturer of a Product</p>
                                                <button className="btn btn-light" onClick={() =>this.hideComponent("showHideDemo1")}>Brand</button>
                                        
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-info text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Category</h5>
                                                <p className="card-text">Category of Product</p>
                                                <button className="btn btn-warning" onClick={() => this.hideComponent("showHideDemo2")}>Category</button>
                                        
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-dark text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Subcategory</h5>
                                                <p className="card-text">Subcategory of Product</p>
                                                <button className="btn btn-danger" onClick={() => this.hideComponent("showHideDemo3")}>Subcategory</button>
                                        
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-secondary text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Product</h5>
                                                <p className="card-text">Product for sell</p>
                                                <button className="btn btn-success" onClick={() => this.hideComponent("showHideDemo4")}>Product</button>
                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-2">
                                    {showHideDemo1 && <Brands/> }
                                    <hr />
                                    {showHideDemo2 && <Categories />}
                                    <hr />
                                    {showHideDemo3 && <Subcategories/>}
                                    <hr />
                                    {showHideDemo4 && <Products/>}
                                    <hr/>
                                </div>
                            </div>
                        </div>
                       
                    
                    </div>
                 
                );
              }
             
}

// function mapStateToProps(state){
//     return {
//         products: state.products.data
//     }
// }

// export default connect(mapStateToProps)(ProductListing)
export default ProductListing;