import { 

    FETCH_ACCOUNT_INIT,
    FETCH_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAIL,
    RESET_ACCOUNT_ERRORS,
    
    FETCH_PRIVATE_IMAGES_INIT,
    FETCH_PRIVATE_IMAGES_SUCCESS,
    FETCH_PRIVATE_IMAGES_FAIL,
    UPDATE_PRIVATE_IMAGES_SUCCESS,
    RESET_PRIVATE_IMAGES_ERRORS,
    DELETE_PRIVATE_IMAGES_SUCCESS,

    FETCH_PRIVATE_IMAGES_BY_ID_INIT,
    FETCH_PRIVATE_IMAGES_BY_ID_SUCCESS,
    FETCH_PRIVATE_IMAGES_BY_ID_FAIL,
    UPDATE_PRIVATE_IMAGES_BY_ID_SUCCESS,
    RESET_PRIVATE_IMAGES_BY_ID_ERRORS,
    DELETE_PRIVATE_IMAGES_BY_ID_SUCCESS,
    
    FETCH_ACCOUNT_BY_ID_SUCCESS,
    FETCH_ACCOUNT_BY_ID_INIT,
    RESET_ACCOUNT_BY_ID_ERRORS, 
    UPDATE_ACCOUNT_BY_ID_SUCCESS,
    UPDATE_ACCOUNT_BY_ID_FAIL,
    
    
    FETCH_ACCOUNTS_SUCCESS, 
    FETCH_ACCOUNTS_INIT,
    FETCH_ACCOUNTS_FAIL,

    FETCH_ACCOUNT_DETAILS_SUCCESS,
    FETCH_ACCOUNT_DETAILS_INIT,
    UPDATE_ACCOUNT_DETAILS_SUCCESS,
    UPDATE_ACCOUNT_DETAILS_FAIL,
    RESET_ACCOUNT_DETAILS_ERRORS,

    FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS,
    FETCH_ACCOUNT_DETAILS_BY_ID_INIT,
    UPDATE_ACCOUNT_DETAILS_BY_ID_SUCCESS,
    UPDATE_ACCOUNT_DETAILS_BY_ID_FAIL,
    RESET_ACCOUNT_DETAILS_BY_ID_ERRORS,

    SEARCH_ACCOUNTS_INIT,
    SEARCH_ACCOUNTS_SUCCESS,
    SEARCH_ACCOUNTS_FAIL
   } from "../actions/types";

const INITIAL_STATE = {
    accounts:{
        data:[],
        errors:[]
    },
    account:{
        data:{},
    },
    accountDetails:{
        data:{}
    },
    accountDetailsById:{
        data:{}
    },
    users:{
        data:[],
        errors:[]
     },
    //  account:{
    //     data:{},
        
    //  },
     accountById:{
         data:{}
     },
     privateImages:{
        data:[],
        errors:[]
    },
    privateImagesById:{
        data:[],
        errors:[]
    }      
}

export const accountsReducer = (state=INITIAL_STATE.accounts, action) =>{
    switch(action.type){
        case FETCH_ACCOUNTS_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_ACCOUNTS_SUCCESS:
            return {...state, data: action.accounts }
           // return Object.assign({},...state,{data: action.accounts})
        case FETCH_ACCOUNTS_FAIL:
            return Object.assign({}, ...state,{errors: action.errors.details, data:[]})
        default:
            return state;
        }
}

export const accountDetailsReducer = (state=INITIAL_STATE.accountDetails, action) =>{
    switch(action.type){
    case FETCH_ACCOUNT_DETAILS_INIT:
        return {...state, data: {} }
    case FETCH_ACCOUNT_DETAILS_SUCCESS:
        return {...state, data: action.accountDetails }
    case UPDATE_ACCOUNT_DETAILS_SUCCESS:
        return {...state, data: action.accountDetails};
    case UPDATE_ACCOUNT_DETAILS_FAIL:
        return {...state, errors: action.errors};
    case RESET_ACCOUNT_DETAILS_ERRORS:
        return {...state, errors: []};
    default:
        return state;
    }
}

export const accountDetailsByIdReducer = (state=INITIAL_STATE.accountDetailsById, action) =>{
    switch(action.type){
    case FETCH_ACCOUNT_DETAILS_BY_ID_INIT:
        return {...state, data: {} }
    case FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS:
        return {...state, data: action.accountDetailsById[0] }
    case UPDATE_ACCOUNT_DETAILS_BY_ID_SUCCESS:
        return {...state, data: action.accountDetailsById[0]};
    case UPDATE_ACCOUNT_DETAILS_BY_ID_FAIL:
        return {...state, errors: action.errors};
    case RESET_ACCOUNT_DETAILS_BY_ID_ERRORS:
        return {...state, errors: []};
    default:
        return state;
    }
}

export const searchAccountReducer = (state=INITIAL_STATE.account, action) =>{
    switch(action.type){
    case SEARCH_ACCOUNTS_INIT:
        return {...state, data: {} }
    case SEARCH_ACCOUNTS_SUCCESS:
        return {...state, data: action.account }
    case SEARCH_ACCOUNTS_FAIL:
        return {...state, errors: action.errors};
    default:
        return state;
    }
}





// export const accountUserReducer = (state=INITIAL_STATE.users, action) =>{
//     switch(action.type){
//        case FETCH_USER_INIT:
//            return {...state, data:[],errors:[]}
//        case FETCH_ACCOUNT_SUCCESS:
//            return {...state, data: action.users }
//        case FETCH_USER_FAIL:
//            return Object.assign({}, ...state,{errors: action.errors, data:[]})
//        default:
//            return state;
//     }
//     }
    
    export const accountReducer = (state=INITIAL_STATE.account, action) =>{
    switch(action.type){
       case FETCH_ACCOUNT_INIT:
           return {...state, data: {} }
       case FETCH_ACCOUNT_SUCCESS:
           return {...state, data: action.account }
       case UPDATE_ACCOUNT_SUCCESS:
           return {...state, data: action.account};
       case UPDATE_ACCOUNT_FAIL:
           //return {...state, errors: action.errors};
           return Object.assign({}, state, {errors: action.errors});
       case RESET_ACCOUNT_ERRORS:
           return {...state, errors: []};
       default:
           return state;
    }
    }
    
    export const accountByIdReducer = (state=INITIAL_STATE.accountById, action) =>{
        switch(action.type){
           case FETCH_ACCOUNT_BY_ID_INIT:
               return {...state, data: {} }
           case FETCH_ACCOUNT_BY_ID_SUCCESS:
               return {...state, data: action.accountById }
           case UPDATE_ACCOUNT_BY_ID_SUCCESS:
               return {...state, data: action.accountById};
           case UPDATE_ACCOUNT_BY_ID_FAIL:
               //return {...state, errors: action.errors};
               return Object.assign({}, state, {errors: action.errors});
           case RESET_ACCOUNT_BY_ID_ERRORS:
               return {...state, errors: []};
           default:
               return state;
        }
    }

    export const accountPrivateImage = (state=INITIAL_STATE.privateImages, action) =>{
        switch(action.type){
            case FETCH_PRIVATE_IMAGES_INIT:
               return {...state, data: [],errors:[]}
            case FETCH_PRIVATE_IMAGES_SUCCESS:
               return {...state, data: action.privateImages }
            case FETCH_PRIVATE_IMAGES_FAIL:
               return {...state, data:[]};
            case UPDATE_PRIVATE_IMAGES_SUCCESS:
                return {...state, data: action.privateImages};
            case DELETE_PRIVATE_IMAGES_SUCCESS:
                return {...state, 
                    data:state.data.filter(x => x.id !== action.privateImages)
                }
            case RESET_PRIVATE_IMAGES_ERRORS:
               return {...state, errors: [], data:[]};
            default:
               return state;
        }
    }

    export const accountPrivateImageById = (state=INITIAL_STATE.privateImagesById, action) =>{
        switch(action.type){
            case FETCH_PRIVATE_IMAGES_BY_ID_INIT:
               return {...state, data: [],errors:[]}
            case FETCH_PRIVATE_IMAGES_BY_ID_SUCCESS:
               return {...state, data: action.privateImagesById }
            case FETCH_PRIVATE_IMAGES_BY_ID_FAIL:
               return {...state, data:[]};
            case UPDATE_PRIVATE_IMAGES_BY_ID_SUCCESS:
                return {...state, data: action.privateImagesById};
            case DELETE_PRIVATE_IMAGES_BY_ID_SUCCESS:
                return {...state, 
                    data:state.data.filter(x => x.id !== action.privateImagesById)
                }
            case RESET_PRIVATE_IMAGES_ERRORS:
               return {...state, errors: [], data:[]};
            default:
               return state;
        }
    }