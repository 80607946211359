import React, { Component } from 'react';
import ContactUsForm from './ContactUsForm';
import * as actions from '../../actions';
import { toast } from 'react-toastify';
// import { autofill } from 'redux-form';
import GoogleMap from '../map/GoogleMap';
export default class ContactUs extends Component {
    
    constructor(){
        super();
        this.state ={
            errors : []
            
           
        }
        this.contactUs = this.contactUs.bind(this);
    }

    contactUs(emailData){
        actions.contactus(emailData)      
        .then(
            sent =>this.setState({errors:[]}),
            toast.success("Success"),
            errors =>this.setState({errors})
        );
    }

    render() {
        const {errors} = this.state;
       

        return (
            <div className="contact">
                <div className="container-fluid">
                    
                    <img style={{ margin:'-40px 0 20px 0',height:'auto',width:'100%'}} src={process.env.PUBLIC_URL + '/img/page/contact-1.jpg'} alt=""/> 
                    
                    
                </div>
                <div className="container">
                    <section id='contactus'>
                        <div className='bwm-form'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className="contact-shadow-title">Contact us</h1>
                                    <h1 className="contact-title">Contact us</h1>
                                    
                                    <ContactUsForm submitCb={this.contactUs} errors={errors}/>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>
                <GoogleMap/>
               
            </div>
        )
    }
}


