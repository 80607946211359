import React from 'react';
import { Field, change, reduxForm,formValueSelector } from 'redux-form';
import {BwmInput,BwmInputDisabled} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
import BwmReactDateTimeField from '../../shared/form/BwmReactDateTimeField';
// import {BwmFileUpload} from '../../shared/form/BwmFileUpload';BwmServiceImageUpload
import {BwmEventImageUpload} from '../../shared/form/BwmEventImageUpload';
import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,numeric, text,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';
import * as actions from '../../../actions/products/index';
import * as serviceActions from '../../../actions/index';
import { connect } from 'react-redux';

//  let options = ['hree','jree'];
 
// const onChange =(values, dispatch, props, previousValues)=> { 

//   actions.fetchSubcategoriesByCategory(values.category)
//                  //.then(res=>res.map(a=>(subcategories.push(a.name))))
//                 .then(res=>{subcategories=[], res.map(a=>(subcategories.push(a.name)))})
  
//   dispatch( change ('serviceCreateForm', 'subcategories', subcategories))             
 
//   // if(values.name === "guest") {
//   //   dispatch( change ('serviceCreateForm', 'active', options))
//   // }
// }
let subcategories = [];
let products = [];
let serviceCategories = [];
const onChange =(values, dispatch, props, previousValues)=> {
  let getData='';
  let getProducts='';
  subcategories=[];
  products = [];
  serviceCategories = [];
  const functionWithPromise = item => { //a function that returns a promise
    return Promise.resolve(item)
  }
  const anAsyncFunction = async item => {
    return functionWithPromise(item)
  }

  //USE promise here cause array may becomes empty for async nature of JS
  // actions.fetchSubcategoriesByCategory(values.category)
  // .then( res=> res.map(a=>subcategories.push(a.name)))
  //Network calls 2 times here, a flaw remained mistery 
  //Redux-form problem is everytime you change data, onChange is called 
  if(values.category){
    getData = async()=>actions.fetchSubcategoriesByCategory(values.category)
                .then( res=>Promise.all( res.map(a=>anAsyncFunction(subcategories.push(a.name)))))
              
    getData().then(data => {
      let s = new Set(subcategories);
      let it = s.values();
      dispatch( change ('serviceCreateForm', 'subcategories', Array.from(it)))      
    })
  }    
  

  if(values.service){
    console.log(values.service)
    getData = async()=>serviceActions.fetchServiceCategoriesByService(values.service)
                .then( res=>Promise.all( res.map(a=>anAsyncFunction(serviceCategories.push(a.name)))))
              
    getData().then(data => {
      let s = new Set(serviceCategories);
      let it = s.values();
      dispatch( change ('serviceCreateForm', 'serviceCategories', Array.from(it)))      
    })
  }   
  // if(values.category && values.subcategory && values.brand){
  //   getProducts = async()=>actions.fetchProductsByCategorySubcategoryBrand(values.category, values.subcategory, values.brand)
  //               .then( res=>Promise.all( res.map(a=>anAsyncFunction(products.push(a.name)))))
              
  //   getProducts().then(data => {
  //     let s = new Set(products);
  //     let it = s.values();
  //     dispatch( change ('serviceCreateForm', 'products', Array.from(it)))      
  //   })
  // }  
}

let EventCreateForm = props => {
  const { handleSubmit, pristine, submitting, submitCb, valid, options, eventTypes, eventCategories, brands,categories, categoryValue, eventCategory, services, errors } = props
  
  //let getData='';
  //This function could be the best to use as onChange could be called from the Field
  //But Redux form may have an error to get the first selection from dropdown.
  //This function will be left as is now till redux-form get updated
  // function onChangeCategory(categoryValue){
  //   console.log(" Triggered", categoryValue);
  //   subcategories=[];
  //   const functionWithPromise = item => { //a function that returns a promise
  //     return Promise.resolve(item)
  //   }
  //   const anAsyncFunction = async item => {
  //     return functionWithPromise(item)
  //   }

  //   getData = async()=>actions.fetchSubcategoriesByCategory(categoryValue)
  //               .then( res=>Promise.all( res.map(a=>anAsyncFunction(subcategories.push(a.name)))))
  
  //   getData().then(data => {
  //     console.log('data',subcategories)
  //   })

  //   //  actions.fetchSubcategoriesByCategory(categoryValue)
  //   //             .then( res=>{subcategories=[], res.map(a=>(subcategories.push(a.name)))})
       
  //  }

    return (
      <form onSubmit={handleSubmit(submitCb)}>
        <div className="row">
            <div className="col-md-6">
            <Field
                      name="sorting_no"
                      type="number"
                      label='Soting No'
                      className='form-control'
                      component={BwmInput}
                      validate={[required,numeric]}
                  />
                <Field
                    name="name"
                    type="text"
                    label='Event Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                <Field
                    name="short_description"
                    type="text"
                    label='Short Description'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                  <Field
                    name="description"
                    type="text"
                    label='Description'
                    className='form-control'
                    component={BwmTextArea}
                    validate={[required]}
                />

              <div className="row">
                <div className="col-6">
                <Field
                      name="price"
                      type="number"
                      label='Price'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                </div>
                <div className="col-6">
                  <Field
                      name="discount"
                      type="number"
                      label='Discount'
                      className='form-control'
                      component={BwmInput}
                      validate={[numeric]}
                  />
                  </div>
              </div>

              <div className="row">
                <div className="col-6">
                <Field
                      name="coupon_code"
                      type="text"
                      label='Coupon Code'
                      className='form-control'
                      component={BwmInput}
                      // validate={[required]}
                  />
                </div>
                <div className="col-6">
                  <Field
                      name="coupon_total"
                      type="number"
                      label='Total Coupon'
                      className='form-control'
                      component={BwmInput}
                      // validate={[required, numeric]}
                  />
                  </div>
              </div>
              

              <div className="row">
                  <div className="col-6">
                  <Field
                      name="start_date"
                      // type="date"
                      label='Start Date'
                      className='form-control'
                      component={BwmReactDateTimeField}
                      validate={[required]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="end_date"
                      type="date"
                      label='End Date'
                      className='form-control'
                      component={BwmReactDateTimeField}
                      validate={[required]}
                  />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Field
                        options={eventCategories}
                        name="event_category"
                        type="text"
                        label='Event Catagory'
                        className='form-control'
                        component={BwmSelect}
                        validate={[required]}
                    />
                  </div>
                  <div className="col-6">
                  <Field
                      options={eventTypes}
                      name="event_type"
                      type="text"
                      label='Event Type'
                      className='form-control'
                      component={BwmSelect}
                      // validate={[required]}
                  />
                </div>
                </div>
                <div className="row">
                  <div className="col-6">
                  <Field
                      options={options}
                      name="active"
                      type="text"
                      label='Active'
                      className='form-control'
                      component={BwmSelect}
                      validate={[required]}
                  />
                  </div>
                </div>
             { eventCategory =='Product'  && <>
                  <Field
                      options={categories}
                      name="category"
                      type="text"
                      label='Category'
                      className='form-control'
                      component={BwmSelect}
                      // onChange={()=>onChangeCategory(categoryValue)}
                      validate={[required]}
                  />
                  <Field
                      options={subcategories}
                      name="subcategory"
                      type="text"
                      label='Subcategory'
                      className='form-control'
                      component={BwmSelect}
                
                      validate={[required]}
                  />
                  <Field
                      options={brands}
                      name="brand"
                      type="text"
                      label='Brand'
                      className='form-control'
                      component={BwmSelect}
                      validate={[required]}
                  />
                </>
             }
          { eventCategory =='Service'  && <>
                  <Field
                      options={services}
                      name="service"
                      type="text"
                      label='Service'
                      className='form-control'
                      component={BwmSelect}
                      // onChange={()=>onChangeCategory(categoryValue)}
                      validate={[required]}
                  />
                  <Field
                      options={serviceCategories}
                      name="service_category"
                      type="text"
                      label='Service Category'
                      className='form-control'
                      component={BwmSelect}
                      validate={[required]}
                  />
                  {/* <Field
                      options={serviceAreas}
                      name="brand"
                      type="text"
                      label='Brand'
                      className='form-control'
                      component={BwmSelect}
                      validate={[required]}
                  /> */}
                </>
             }
             
            </div>
            <div className="col-md-6">    
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmEventImageUpload}
                />
            </div>
            {/* {categoryValue} */}
            </div>
                
        <button className='btn btn-success btn-form' type="submit" disabled={submitting}>
          Create Event
        </button>
        <BwmResError errors={errors} />
      </form>
    )
  }


  const selector = formValueSelector('serviceCreateForm');

  EventCreateForm= connect(state => {
    const categoryValue = selector(state, 'category');
    const eventCategory = selector(state, 'event_category');
    const serviceValue = selector(state, 'service');
    // const subcategoryValue = selector(state, 'subcategory');
    // const brandValue = selector(state, 'brand');
    return{
      categoryValue,eventCategory,serviceValue
      // subcategoryValue,
      // brandValue
    }
  })(EventCreateForm)

  EventCreateForm= reduxForm({
    form: 'serviceCreateForm',onChange
      //initialValues: { active: 'True'}
  })(EventCreateForm)


  export default EventCreateForm;