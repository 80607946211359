import React, { Component } from 'react';
import RegisterForm from './RegisterForm';
import * as actions from '../../actions';
import {Redirect} from 'react-router-dom';

export default class Register extends Component {
    
    constructor(){
        super();
        this.state ={
            errors : [],
            redirect: false
        }
        this.registerUser = this.registerUser.bind(this);
    }
    registerUser(userData){
        actions.register(userData)
        .then(
            registered =>this.setState({redirect: true}),
            errors =>this.setState({errors})
        );
    }

    render() {
        const {errors,redirect} = this.state;
        if(redirect)
            return <Redirect to ={{pathname:'/login', state:{successRegister: true}}}/>
            // react-router provides this.props.location so state will be inside location in Login.js
        return (
            <div className="container">
            <section id='register'>
                <div className='bwm-form'>
                    <div className='row'>
                    <div className='col-md-5'>
                        <h1>Register</h1>
                        <RegisterForm submitCb={this.registerUser} errors={errors}/>
                    </div>
                    <div className='col-md-6 ml-auto'>
                        <div className='image-container'>
                        <h2 className='catchphrase'>As Our Member You Have Access To The Most Awesome Services</h2>
                        <img src={process.env.PUBLIC_URL+ '/img/page/register.jpg'} alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}


