import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions';
import 'react-toastify/dist/ReactToastify.css';
import ServiceAreaDetailForm from '../../services/service-detail/ServiceAreaDetailForm';
class ServiceAreaUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['true', 'false'];
        this.updateServiceArea = this.updateServiceArea.bind(this);
    }
    
    updateServiceArea(serviceAreaData){
        const {serviceArea} = this.props;
        let mergeData = {...serviceArea, ...serviceAreaData};
        this.props.dispatch(actions.updateServiceArea(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateServiceAreaSuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            // this.setState({errors:response});//
            this.setState({errors:[response.errors.data.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-service-area-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Service Area</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <ServiceAreaDetailForm  
                                    submitCb={this.updateServiceArea} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    return{
    auth: state.auth,
    errors: state.errors,
    serviceArea: state.serviceArea.data,
     initialValues: {
         name: state.serviceArea.data.name,
        image_link: state.serviceArea.data.image_link,
        active: state.serviceArea.data.active,
        short_description: state.serviceArea.data.short_description,
        description: state.serviceArea.data.description,
        updated_at: state.serviceArea.data.updated_at,
        created_at: state.serviceArea.data.created_at
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(ServiceAreaUpdateModal));
