const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength1 = minLength(1);
export const minLength2 = minLength(2);
export const minLength4 = minLength(4);
export const minLength6 = minLength(6);
export const minLength8 = minLength(8);

export const maxLength16 = maxLength(16);
export const maxLength32 = maxLength(32);
export const maxLength64 = maxLength(64);
export const maxLength128 = maxLength(128);
export const maxLength256 = maxLength(256);
export const maxLength512 = maxLength(512);

export const required = value => (value ? undefined : 'This input is required!');

export const email = value=> (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ? undefined : 'Enter a valid Email address');

export const phone = value=> (/^([1-9]\d{2}-\d{3}-\d{4})$/.test(value) ? undefined : 'Phone number pattern: xxx-xxx-xxxx allowed');
export const phone_bd = value=> (/^([0-9]\d{2}-\d{4}-\d{4})$/.test(value) ? undefined : 'Phone number pattern: xxx-xxxx-xxxx allowed');
export const home_phone = value=> value && !(/^([1-9]\d{2}-\d{3}-\d{4})$/.test(value) ? 'Phone number pattern: xxx-xxx-xxxx allowed'  : undefined );


export const text = value => (/^[a-zA-Z ]*$/).test(value)? undefined : 'This input accepts only Text with no symbol';
export const alphanumeric = value =>(/^[a-zA-Z0-9 ]*$/).test(value)? undefined : 'This input accepts only Text or Number';
// export const numeric = value =>value && !(/^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(value)?  'This input accepts only Number with Decimal up to 2 digits, Number can not have white space and can not start with zero': undefined );
// export const numeric = value =>(value<=0?'You must insert Positive value': null);
export const numeric = value =>(value==''?null: (value<0?'insert positive':null));
// export const positive = value =>(value<1?'You must insert Positive value': undefined);

export const checkbox = value => value? undefined : 'You must read terms and conditions and agree on it.'
// /^[1-9]\d{2}-\d{3}-\d{4}
// console.log(/^([1-9]\d{2}-\d{3}-\d{4})$/.test('123-123-1234'))