import React from 'react';
import {ServiceItems} from './ServiceItems';
export function ServiceItemArea(props){
    

    let data = props.categories;
        let groupBy = "area_name";
        let categories={};
        
        for (var i = 0; i < data.length; i++) {
            if (!categories[data[i][groupBy]])
                categories[data[i][groupBy]] = [];
            categories[data[i][groupBy]].push(data[i]);
        };
        console.log('categories:',categories)
        return Object.keys(categories).map((category, index) => {
            console.log('category',category)
            return(

               
                    <table id='price-list' style={{marginBottom:'20px', fontSize:'1em', fontFamily:'Oswald'}}>
                        <tbody>
                        <tr><th>Service Area</th><th>Single Treatment</th><th>Package of Two</th><th>Package of Four</th><th>Package of Six</th></tr>
                        {<ServiceItems key={index}
                colNum="col-md-12"
                serviceItem={categories[category]}/>}
                        </tbody>
                    </table>
                   
       
                     
                    )
        });
}

