import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions';
import 'react-toastify/dist/ReactToastify.css';
// import ProductDetailForm from '../../services/service-listing/ProductDetailForm';
import ServiceDeleteForm from './ServiceDeleteForm';
class ServiceDeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        
        this.deleteService = this.deleteService.bind(this);
       
    }
   
   

    

    deleteService(ServiceData){
        const {service} = this.props;
        // var removeIndex = this.state.record.map(function(service) { return service.id; }).indexOf(service.id);
        // apps.splice(removeIndex, 1);

        
        console.log('service', service.id)
        console.log('ServiceData', ServiceData)
        let mergeData = {...service, ...ServiceData};
        console.log('merge',this.state.record)
        this.props.dispatch(actions.deleteService( service.id))
        .then((response) => {
            if(response === undefined){
                this.setState({errors:[{'detail':'Please Login'}]});
                toast.error("Unauthorized");
                return;
            }
            if(response.status ==='success'){
                this.setState({errors:[]});
                this.props.dispatch(actions.deleteServiceSuccess(service.id));
                console.log('this.state.data',this.state.data)
                //this.setState({record:mergeData});
                this.props.action(this.state.record);
                toast.success("Success");
                return;
            }
            if(response.status ==='empty'){
                //this.setState({errors:[response.errors[0]]});
                //console.log('errors:[response.errors[0]]}',response)
                this.setState({errors:[{'detail':'Data either deleted or not found'}]});
                toast.error("Data not found!");
            }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="delete-service-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Delete Service</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                            <ServiceDeleteForm  
                                    submitCb={this.deleteService} 
                                    errors={errors}
                                  
                                    //initialValues ={this.props.initialValues}
                                />
                                   
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    // console.log('state',state.serviceItem.data)
    // console.log('service.data',state.service.data)
    return{
    auth: state.auth,
    errors: state.errors,
    service: state.service.data,
    // categories: state.categories.data,
    // subcategories: state.subcategories.data,
    // brands: state.brands.data,
    services: state.services.data,
    
    }
};

export default connect(
    mapStateToProps
)(withRouter(ServiceDeleteModal));
