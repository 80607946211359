import React, { Component } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Redirect} from 'react-router-dom';

class ForgotPassword extends Component {
    
    constructor(){
        super();
        this.state ={
            errors : [],
            redirect: false,
            successActivationKey: false
        }
        this.forgotPassword = this.forgotPassword.bind(this);
        this.redirectForgotPassword = this.redirectForgotPassword.bind(this);
    }
    forgotPassword(userData){
        actions.forgotPassword(userData)
        .then(
            forgot =>this.setState({redirect: true, successActivationKey: true}),
            errors =>this.setState({errors})
        );
    }
    redirectForgotPassword(){
        this.props.history.push('/forgot-password');
      }
   
    render() {
        const {isAuth} = this.props.auth;
        //const {successActivationKey} = this.props.location.state || false;
    
        if(isAuth){
          return <Redirect to={{pathname:'/'}}/>
        }

        const {errors} = this.state;
        return (
            <div className="container">
            <section id='register'>
                <div className='bwm-form'>
                    <div className='row'>
                    <div className='col-md-5'>
                        <h1>Forgot Password</h1>
                        {
                        this.state.successActivationKey && this.redirectForgotPassword &&
                            <div className='alert alert-success'>
                            <p> Please Check Your Email to Activate Your Account</p>
                            </div>
                        } 
                        {
                            !this.state.successActivationKey && <ForgotPasswordForm submitCb={this.forgotPassword} errors={errors}/>
                        }
                        
                    </div>
                    <div className='col-md-6 ml-auto'>
                        <div className='image-container'>
                        <h2 className='catchphrase'>As Our Member You Have Access To The Most Awesome Services</h2>
                        <img src={process.env.PUBLIC_URL+ '/img/page/register.jpg'} alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}

function mapStateToProps(state){
    return{
      auth: state.auth
    }
  }
  
  export default withRouter(connect(mapStateToProps)(ForgotPassword));


