import React, {Component} from 'react';
import { connect } from 'react-redux';
// import ServiceMap from './ServiceMap';
import Booking from '../../booking/Booking';
import {UserGuard} from '../../shared/auth/UserGuard';
import {ServiceAssets} from './ServiceAssets';
import { toUpperCase } from '../../../helpers';
import {EditableInput} from '../../shared/editable/EditableInput';
import {EditableText} from '../../shared/editable/EditableText';
import {EditableSelect} from '../../shared/editable/EditableSelect';
import {EditableImage} from '../../shared/editable/EditableImage';

import * as actions from '../../../actions';

class ServiceUpdate extends Component{
    constructor(){
        super();
        this.state ={
        isAllowed: false,
        isFetching: true
        }
        this.updateService = this.updateService.bind(this);
        this.resetServiceErrors = this.resetServiceErrors.bind(this);
        this.verifyServiceOwner = this.verifyServiceOwner.bind(this);
    }
    
    componentWillMount(){
        const serviceId = this.props.match.params.id;
        this.props.dispatch(actions.fetchServiceById(serviceId));
    }

    componentDidMount(){
       this.verifyServiceOwner();  
    }

    updateService(serviceData){
        const {service:{_id}} = this.props;
        this.props.dispatch(actions.updateService(_id, serviceData));
    }

    resetServiceErrors(){
        this.props.dispatch(actions.resetServiceErrors());
    }



    verifyServiceOwner(){
        const serviceId = this.props.match.params.id;
        this.setState({isFetching: true});
    
        return actions.verifyServiceOwner(serviceId)
                    .then(()=>{
                    this.setState({isAllowed: true, isFetching: false})
                    },
                    ()=>{
                    this.setState({isAllowed: false, isFetching: false})
                    })
    }

    render(){
        const {service, errors} = this.props;
        const {isAllowed, isFetching} = this.state;
        if(service._id){
        return(
            <UserGuard isAllowed={isAllowed} isFetching={isFetching}> 
                <section id='serviceDetails'>
                <div className='upper-section'>
                <div className='row'>
                    <div className='col-md-6'>
                        {/* <img src={service.image} alt=''></img> */}
                        <EditableImage entity={service}
                                        entityField={'image'}
                                        errors={errors}
                                        updateEntity={this.updateService}>
                        </EditableImage>
                    </div>
                    <div className='col-md-6'>
                        {/* <ServiceMap location={`${service.city}, ${service.street}`}/> */}
                    </div>
                </div>
                </div>
            
                <div className='details-section'> 
                <div className='row'>
                    <div className='col-md-8'>
                    <div className='service'>
                    
                    <label className={`service-label service-type ${service.category}`}>Shared</label>          
                    <EditableSelect  entity={service} 
                                    entityField={'shared'} 
                                    className={`service-type ${service.category}`}
                                    updateEntity={this.updateService}
                                    options={[true, false]}
                                    containerStyle={{'display':'inline-block'}}
                                    errors={errors}
                                    resetErrors={this.resetServiceErrors}
                                    />

                    <EditableSelect  entity={service} 
                                    entityField={'category'} 
                                    className={`service-type ${service.category}`}
                                    updateEntity={this.updateService}
                                    options={['apartment','house', 'condo']}
                                    errors={errors}
                                    resetErrors={this.resetServiceErrors}
                                    />

                    <div className='service-owner'>
                        <img src='https://api.adorable.io/avatars/285/abott@adorable.png' alt='owner'/>
                        <span>{service.user && service.user.username}</span>
                    </div>
        
        
                    <EditableInput  entity={service} 
                                    entityField={'title'} 
                                    className={'service-title'}
                                    updateEntity={this.updateService}
                                    errors={errors}
                                    resetErrors={this.resetServiceErrors}
                                    />

                    <EditableInput  entity={service} 
                                    entityField={'city'} 
                                    className={'service-city'}
                                    updateEntity={this.updateService}
                                    formatPipe={[toUpperCase]}
                                    errors={errors}
                                    resetErrors={this.resetServiceErrors}
                                    />

                    <EditableInput  entity={service} 
                                    entityField={'street'} 
                                    className={'service-street'}
                                    updateEntity={this.updateService}
                                    
                                    errors={errors}
                                    resetErrors={this.resetServiceErrors}
                                    />
                    <div className="service-room-info">
                        <span><i className='fa fa-building'></i>
                            <EditableInput  entity={service} 
                                            entityField={'bedrooms'} 
                                            className={'service-bedrooms'}
                                            containerStyle={{'display':'inline-block'}}
                                            updateEntity={this.updateService}
                                            errors={errors}
                                            resetErrors={this.resetServiceErrors}
                                            />

                        Bedrooms</span>
                        <span><i className='fa fa-user'></i> {service.bedrooms + 4} guests</span>
                        <span><i className='fa fa-bed'></i> {service.bedrooms + 2} beds</span>
                    </div>
                    <EditableText   entity={service} 
                                    entityField={'description'} 
                                    className={'service-description'}
                                    updateEntity={this.updateService}
                                    rows={6}
                                    cols={50}
                                    errors={errors}
                                    resetErrors={this.resetServiceErrors}
                                    />
                    
                    <hr></hr>
                    <ServiceAssets/>
                </div>
                    </div>
                    <div className='col-md-4'>
                    <Booking service={service}/>
                    </div>
                </div>
                </div>
            </section>
        </UserGuard>
        
        )}else 
            return(
                <div>
                <h1>Loading...</h1>
                </div>
            )
    }
}      

function mapStateToProps(state){
    return {
        service: state.service.data,
        errors: state.service.errors
    }
}

export default connect(mapStateToProps)(ServiceUpdate)