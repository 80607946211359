import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import BrandUpdateModal from "../../shared/Modal/BrandUpdateModal";
import BrandDeleteModal from "../../shared/Modal/BrandDeleteModal";
import * as actions from '../../../actions/products/index';
import {Link} from 'react-router-dom';
export const Brands =({brandData}) =>{
  const dispatch = useDispatch();

  const [brand, setbrand] = useState(brandData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedbrand, setSelectedBrand] = useState({});
  

  const columns = [
    {
      name: 'Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/brand/'+row.image_link} />,
    },
  
    {
      name: 'Brand',
      selector: 'name',
    },
    
    {
        name: 'Active',
        cell: row => row.active == true?'Yes': 'No',
      },
      
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-brand-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-brand-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchBrand(id))
    .then((res)=>{
       console.log('res',res)
      setSelectedBrand({ selectedBrand: res});
    })
    .catch()    
  }

  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteBrand(id))
    dispatch(actions.fetchBrand(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedBrand({ selectedBrand: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = brand.findIndex((e)=> e.id === service.id);
    var newStateArray = brand.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(brand !== null || brand !== undefined)
      getBrandList(); 
    else return null;
  }

  function renderRows() {
    if(brand !== null || brand !== undefined)
      getBrandList();
    else return null;
  }

  const getBrandList = () => {  
    dispatch(actions.fetchBrands(page, countPerPage))
    .then(res => {
      setbrand(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setbrand({});
    });
  }
  
  useEffect(() => {
    getBrandList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/products/create-brand"><button className="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Brand</button></Link>
      <div className="row px-4">  
        {brand && <BrandUpdateModal record={selectedbrand} action={renderRow}/>}
        {brand && <BrandDeleteModal  record={selectedbrand} action={renderRows}/>}
        <ToastContainer/>
        {brand && 
          <DataTable
            title="Brands"
            columns={columns}
            data={brand}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
          />
        }

      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  brandData: state.services.data
  }
}

// const mapDispatchToProps = dispatch =>{
//   return {
//     fetchbrand:(id)=>dispatch(fetchbrand(id))
//   }
// }
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  // mapDispatchToProps
)(Brands);
