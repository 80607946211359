import authService from '../services/auth-service';
import {FETCH_SERVICES_SUCCESS, 
        FETCH_SERVICE_BY_ID_SUCCESS, 
        FETCH_SERVICE_BY_ID_INIT,
        FETCH_SERVICES_INIT,
        FETCH_SERVICES_FAIL,

        FETCH_SERVICE_INIT,
        FETCH_SERVICE_SUCCESS,
        FETCH_SERVICE_FAIL,
        DELETE_SERVICE_SUCCESS,

        LOGIN_SUCCESS,
        LOGIN_FAILURE,
        LOGOUT,
        FETCH_USER_BOOKINGS_SUCCESS,
        FETCH_USER_BOOKINGS_FAIL,
        FETCH_USER_BOOKINGS_INIT,
        UPDATE_SERVICE_SUCCESS,
        UPDATE_SERVICE_FAIL,
        RESET_SERVICE_ERRORS,
        RELOAD_MAP,
        RELOAD_MAP_FINISH,
        UPDATE_BOOKINGS,
        
      
        

        FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT,
        FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_SUCCESS,
        FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_FAIL,

        FETCH_SERVICE_CATEGORY_INIT,
        FETCH_SERVICE_CATEGORY_SUCCESS,
        FETCH_SERVICE_CATEGORY_FAIL,
        DELETE_SERVICE_CATEGORY_FAIL,
        DELETE_SERVICE_CATEGORY_SUCCESS,

        FETCH_SERVICE_CATEGORIES_INIT,
        FETCH_SERVICE_CATEGORIES_SUCCESS,
        FETCH_SERVICE_CATEGORIES_FAIL,

        FETCH_SERVICE_ITEMS_INIT,
        FETCH_SERVICE_ITEMS_SUCCESS,
        FETCH_SERVICE_ITEMS_FAIL,

        FETCH_SERVICE_ITEM_INIT,
        FETCH_SERVICE_ITEM_SUCCESS,
        FETCH_SERVICE_ITEM_FAIL,
        UPDATE_SERVICE_ITEM_FAIL,
        UPDATE_SERVICE_ITEM_SUCCESS,

        DELETE_SERVICE_ITEM_FAIL,
        DELETE_SERVICE_ITEM_SUCCESS,

        FETCH_ACCOUNT_INIT,
        FETCH_ACCOUNT_SUCCESS,
        UPDATE_ACCOUNT_FAIL,
        UPDATE_ACCOUNT_SUCCESS,
        RESET_ACCOUNT_ERRORS,

        FETCH_ACCOUNT_DETAILS_INIT,
        FETCH_ACCOUNT_DETAILS_SUCCESS,

        FETCH_PRIVATE_IMAGES_INIT,
        FETCH_PRIVATE_IMAGES_SUCCESS,
        FETCH_PRIVATE_IMAGES_FAIL,
        UPDATE_PRIVATE_IMAGES_SUCCESS,
        RESET_PRIVATE_IMAGES_ERRORS,
        DELETE_PRIVATE_IMAGES_SUCCESS,

        FETCH_PRIVATE_IMAGES_BY_ID_INIT,
        FETCH_PRIVATE_IMAGES_BY_ID_SUCCESS,
        FETCH_PRIVATE_IMAGES_BY_ID_FAIL,
        UPDATE_PRIVATE_IMAGES_BY_ID_SUCCESS,
        RESET_PRIVATE_IMAGES_BY_ID_ERRORS,
        DELETE_PRIVATE_IMAGES_BY_ID_SUCCESS,

        FETCH_ACCOUNT_BY_ID_INIT,
        FETCH_ACCOUNT_BY_ID_SUCCESS,
        RESET_ACCOUNT_BY_ID_ERRORS,
        UPDATE_ACCOUNT_BY_ID_SUCCESS,
        UPDATE_ACCOUNT_BY_ID_FAIL,
     


        FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS,
        FETCH_ACCOUNT_DETAILS_BY_ID_INIT,
        UPDATE_ACCOUNT_DETAILS_BY_ID_SUCCESS,
        UPDATE_ACCOUNT_DETAILS_BY_ID_FAIL,
        RESET_ACCOUNT_DETAILS_BY_ID_ERRORS,


        FETCH_ACCOUNTS_INIT,
        FETCH_ACCOUNTS_SUCCESS,
        FETCH_ACCOUNTS_FAIL,

        SEARCH_ACCOUNTS_INIT,
        SEARCH_ACCOUNTS_SUCCESS,
        SEARCH_ACCOUNTS_FAIL,

        

        FETCH_SERVICE_CATEGORY_BY_ID_INIT,
         FETCH_SERVICE_CATEGORY_BY_ID_SUCCESS,
         FETCH_SERVICE_CATEGORY_BY_ID_FAIL,
         UPDATE_SERVICE_CATEGORY_BY_ID_FAIL,
         UPDATE_SERVICE_CATEGORY_BY_ID_SUCCESS,

         FETCH_SERVICE_AREAS_SUCCESS,
         FETCH_SERVICE_AREAS_INIT,
         FETCH_SERVICE_AREAS_FAIL,

         FETCH_SERVICE_AREA_INIT,
         FETCH_SERVICE_AREA_SUCCESS,
         FETCH_SERVICE_AREA_FAIL,
         UPDATE_SERVICE_AREA_FAIL,
         UPDATE_SERVICE_AREA_SUCCESS,
         DELETE_SERVICE_AREA_FAIL,
         DELETE_SERVICE_AREA_SUCCESS

      } from './types';
import axiosService from '../services/axios-service';

//Map Action
export const reloadMap = () => {
  return {
    type: RELOAD_MAP
  }
}

export const reloadMapFinish = () => {
  return {
    type: RELOAD_MAP_FINISH
  }
}

const axiosInstance = axiosService.getInstance();



export const getPrivateImages=()=>{
  return function (dispatch){
    dispatch(fetchPrivateImageInit());
    return axiosInstance.get(`/files/file-service/private/uploads/cards`)
      .then(res =>res.data)
      .then(images=>{
        dispatch(fetchPrivateImageSuccess(images));
        return images
      })
  }
}
const fetchPrivateImageInit=()=>{
  return {
    type: FETCH_PRIVATE_IMAGES_INIT,
    
  }
}

const fetchPrivateImageSuccess =(images)=>{
  return {
    type: FETCH_PRIVATE_IMAGES_SUCCESS,
    privateImages:images
  }
}

/**
 * 
 * @returns TODO only get the last image added
 */
const updatePrivateImageSuccess =(images)=>{
  return {
    type: UPDATE_PRIVATE_IMAGES_SUCCESS,
    privateImages:images
  }
}

export const deletePrivateCardImages=(index)=>{
  return function (dispatch){
    return axiosInstance.delete(`/files/file-service/private/cards/delete/${index}`)
      .then(res =>res.data)
      .then(images=>{
        dispatch(deletePrivateImageSuccess(images));
        return images
      })
  }
}

const deletePrivateImageSuccess =(images)=>{
  return {
    type: DELETE_PRIVATE_IMAGES_SUCCESS,
    privateImages:images
  }
}
////////////////////////////////////////////////

export const getPrivateImagesById=(id)=>{
  return function (dispatch){
    dispatch(fetchPrivateImageByIdInit());
    return axiosInstance.get(`/files/file-service/private/uploads/cards/${id}`)
      .then(res =>res.data)
      .then(images=>{
        dispatch(fetchPrivateImageByIdSuccess(images));
        return images
      })
  }
}
const fetchPrivateImageByIdInit=()=>{
  return {
    type: FETCH_PRIVATE_IMAGES_BY_ID_INIT,
    
  }
}

const fetchPrivateImageByIdSuccess =(images)=>{
  return {
    type: FETCH_PRIVATE_IMAGES_BY_ID_SUCCESS,
    privateImagesById:images
  }
}

/**
 * 
 * @returns TODO only get the last image added
 */
const updatePrivateImageByIdSuccess =()=>{
  return {
    type: UPDATE_PRIVATE_IMAGES_BY_ID_SUCCESS,
    privateImagesById:images
  }
}

export const deletePrivateCardByIdImages=(id,index)=>{
  return function (dispatch){
    return axiosInstance.delete(`/files/file-service/private/cards/delete-by-id/${id}/${index}`)
      .then(res =>res.data)
      .then(images=>{
        dispatch(deletePrivateImageByIdSuccess(images));
        return images
      })
  }
}

const deletePrivateImageByIdSuccess =(images)=>{
  return {
    type: DELETE_PRIVATE_IMAGES_BY_ID_SUCCESS,
    privateImagesById:images
  }
}
//////////////////////////////////////////


////////////////////////**account detail**/////////////////////////////
export const fetchAccountDetails = ()=>{

  return function (dispatch){
    dispatch(fetchAccountDetailsInit());
    return axiosInstance.get(`/auth/account_details`)
      .then(res => res.data[0])
      .then(accountDetails=>{
        dispatch(fetchAccountDetailsSuccess(accountDetails));
        return accountDetails
      })
  }
}

const fetchAccountDetailsInit=()=>{
  return {
    type: FETCH_ACCOUNT_DETAILS_INIT,
    
  }
}

const fetchAccountDetailsSuccess =(accountDetails)=>{
  return {
    type: FETCH_ACCOUNT_DETAILS_SUCCESS,
    accountDetails:accountDetails
  }
}

export const updateAccountDetails = (accountData) => dispatch => {
  return axiosInstance.post(`/auth/account_details`, accountData)
    .then(res => res.data)
    // .then(updatedAccount => {
    //   dispatch(updateServiceSuccess(updatedAccount));
    // })
    .catch(({response}) => dispatch(updateAccountDetailsFail(response.data.errors)))
  
}

const updateAccountDetailsFail = (errors) => {
  return {
    type: UPDATE_ACCOUNT_FAIL,
    errors
  }
}

export const resetAccountErrors = () => {
  return {
    type: RESET_ACCOUNT_ERRORS
  }
}


export const updateAccountDetailsSuccess = (updatedAccount) => {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    account: updatedAccount
  }
}

/////////////////////////////////////////////////////

// export const fetchAccounts = (page, countPerPage)=>{
//   return function (dispatch){
//      dispatch(fetchAccountsInit());
//      axiosInstance.get(`/auth/users/fetch-users/${page}/${countPerPage}`)
//        .then(res => res.data)
//        .then(accounts=>dispatch(fetchAccountsSuccess(accounts)))
//        .catch(({response}) => {
//            dispatch(fetchAccountsFail(response.data.errors));
//        })
//   }
// }
////////////////////////////////////////////////////
export const fetchAgents = (page, countPerPage)=>{
  return function (dispatch){
    dispatch(fetchAccountsInit());
    return axiosInstance.get(`/auth/users/fetch-agents/${page}/${countPerPage}`)
      .then(res => res.data)
      .then(accounts=>{
        dispatch(fetchAccountsSuccess(accounts));
        return accounts
      })
      .catch(({response}) => {
        dispatch(fetchAccountsFail(response.data.errors));
      })
  }
}
export const fetchAccounts = (page, countPerPage)=>{
  return function (dispatch){
    dispatch(fetchAccountsInit());
    return axiosInstance.get(`/auth/users/fetch-users/${page}/${countPerPage}`)
      .then(res => res.data)
      .then(accounts=>{
        dispatch(fetchAccountsSuccess(accounts));
        return accounts
      })
      .catch(({response}) => {
        dispatch(fetchAccountsFail(response.data.errors));
      })
  }
}
const fetchAccountsInit=()=>{
  return {
    type: FETCH_ACCOUNTS_INIT,
    
  }
}

const fetchAccountsSuccess =(accounts)=>{
  return {
    type: FETCH_ACCOUNTS_SUCCESS,
    accounts:accounts
  }
}

const fetchAccountsFail = (errors) => {
  console.log('error1:',errors)
  return {
    type: FETCH_ACCOUNTS_FAIL,
    errors
  }
}

//////////////



/////////////////////////////////////////////////////

export const searchAccounts = (data)=>{

  return function (dispatch){
    dispatch(searchAccountsInit());
    return axiosInstance.get(`/auth/users/search/${data}`)
      .then(res => res.data)
      .then(accounts=>{
        dispatch(searchAccountsSuccess(accounts));
        return accounts
      })
      .catch(({response}) => {
        dispatch(searchAccountsFail(response.data.errors));
      })
  }
}
const searchAccountsInit=()=>{
  return {
    type: SEARCH_ACCOUNTS_INIT,
    
  }
}

const searchAccountsSuccess =(account)=>{
  return {
    type: SEARCH_ACCOUNTS_SUCCESS,
    account:account
  }
}

const searchAccountsFail = (errors) => {
  return {
    type: SEARCH_ACCOUNTS_FAIL,
    errors
  }
}

//////////////











/////////////account
export const fetchAccountUserById = (id)=>{
  return function (dispatch){
    dispatch(fetchUserUserByIdInit());
    return axiosInstance.get(`/auth/user_account_by_id/${id}`)
      .then(res => res.data[0])
      .then(accounts=>{
        dispatch(fetchUserUserByIdSuccess(accounts));
        return accounts
      })
  }
}
const fetchUserUserByIdInit=()=>{
  return {
    type: FETCH_ACCOUNT_BY_ID_INIT,
    
  }
}

const fetchUserUserByIdSuccess =(accountById)=>{
  return {
    type: FETCH_ACCOUNT_BY_ID_SUCCESS,
    accountById:accountById
  }
}
//////////////////////////////////////

export const fetchAccountDetailsById = (id)=>{

  return function (dispatch){
    dispatch(fetchAccountDetailsByIdInit());
    return axiosInstance.get(`/auth/account_details/${id}`)
      .then(res => res.data)
      .then(accountDetails=>{
        dispatch(fetchAccountDetailsByIdSuccess(accountDetails));
        return accountDetails
      })
  }
}

const fetchAccountDetailsByIdInit=()=>{
  return {
    type: FETCH_ACCOUNT_DETAILS_BY_ID_INIT,
    
  }
}

const fetchAccountDetailsByIdSuccess =(accountDetails)=>{
  return {
    type: FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS,
    accountDetailsById:accountDetails
  }
}

export const updateAccountDetailsById = (accountData) => dispatch => {
  return axiosInstance.post(`/auth/account_details/`, accountData)
    .then(res => res.data)
    // .then(updatedAccount => {
    //   dispatch(updateServiceSuccess(updatedAccount));
    // })
    .catch(({response}) => dispatch(updateAccountDetailsByIdFail(response.data.errors)))
  
}

const updateAccountDetailsByIdFail = (errors) => {
  return {
    type: UPDATE_ACCOUNT_DETAILS_BY_ID_FAIL,
    errors
  }
}

export const resetAccountDetailsByIdErrors = () => {
  return {
    type: RESET_ACCOUNT_DETAILS_BY_ID_ERRORS
  }
}


export const updateAccountDetailsByIdSuccess = (updatedAccount) => {
  return {
    type: UPDATE_ACCOUNT_DETAILS_BY_ID_SUCCESS,
    accountDetailsById: updatedAccount
  }
}

//////////////////////**account account**////////////////////////////////
export const fetchAccount = ()=>{

  return function (dispatch){
    dispatch(fetchAccountInit());
    return axiosInstance.get(`/auth/account`)
      .then(res => res.data[0])
      .then(account=>{
        dispatch(fetchAccountSuccess(account));
        return account
      })
  }
}

const fetchAccountInit=()=>{
  return {
    type: FETCH_ACCOUNT_INIT,
    
  }
}

const fetchAccountSuccess =(account)=>{
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    account:account
  }
}

export const updateAccount = (userData) => dispatch => {
  return axiosInstance.post(`/auth/account`, userData)
    .then(res => res.data)
    // .then(updatedUser => {
    //    dispatch(updateAccountSuccess(updatedUser));
    //  })
    //if you use above then res will be undefined and lost
    .catch(({response}) => dispatch(updateAccountFail(response.data.errors)))
}

const updateAccountFail = (errors) => {
  return {
    type: UPDATE_ACCOUNT_FAIL,
    errors
  }
}
//------------
export const updateAccountById = (userData) => dispatch => {
 
  return axiosInstance.post(`/auth/user_account_by_id/${userData.id}`, userData)
    .then(res => res.data)
    // .then(updatedUser => {
    //    dispatch(updateAccountSuccess(updatedUser));
    //  })
    //if you use above then res will be undefined and lost
    .catch(({response}) => dispatch(updateAccountByIdFail(response.data.errors)))
}

export const updateAccountByIdSuccess = (updatedUser) => {
  return {
    type: UPDATE_ACCOUNT_BY_ID_SUCCESS,
    accountById: updatedUser
  }
}
//-----------
const updateAccountByIdFail = (errors) => {
  return {
    type: UPDATE_ACCOUNT_BY_ID_FAIL,
    errors
  }
}

export const resetAccountByIdErrors = () => {
  return {
    type: RESET_ACCOUNT_BY_ID_ERRORS
  }
}

export const updateAccountSuccess = (updatedUser) => {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    account: updatedUser
  }
}





//------------------------------------------------------------------

//AUTH ACTIONS
export const register = (userData) =>{
  return axiosInstance.post('/auth/sign-up', userData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}
//ACTIVATE ACCOUNT
export const activateAccount = (activation_key) =>{
  return axiosInstance.get(`/auth/activation_key/${activation_key}`)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}

//FORGOT PASSWORD ACTION
export const forgotPassword = (userData) =>{
  return axiosInstance.post('/auth/forgot_password', userData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}
//RESET PASSWORD 
export const resetPassword = (userData, activation_key) =>{
  return axiosInstance.post(`/auth/reset-password/${activation_key}`, userData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}

//USER BOOKING ACTIONS----------------------------------------------

const fetchUserBookingsInit = () => {
  return {
    type: FETCH_USER_BOOKINGS_INIT
  }
}

const fetchUserBookingsSuccess = (userBookings) => {
  return {
    type: FETCH_USER_BOOKINGS_SUCCESS,
    userBookings
  }
}

const fetchUserBookingsFail = (errors) => {
  return {
    type: FETCH_USER_BOOKINGS_FAIL,
    errors
  }
}

export const fetchUserBookings = () => {
  return dispatch => {
    dispatch(fetchUserBookingsInit());

    axiosInstance.get('/bookings/manage')
      .then(res => res.data )
      .then(userBookings => dispatch(fetchUserBookingsSuccess(userBookings)))
      .catch(({response}) => dispatch(fetchUserBookingsFail(response.data.errors)))
  }
}
//USER SERVICE ACTION------------------------------------------------
export const getUserServices = () =>{
  return axiosInstance.get('/services/manage')
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}


//CONTACTUS ACTION----------------------------------------------------------
export const contactus = (emailData) =>{
  return axiosInstance.post('/mail/contactus', emailData)
    .then(res=> console.log(res.data),
          // err =>Promise.reject(err.response.data.errors)
          err =>console.log(err.response.data.errors)
    )
}

//BOOKING ACTION----------------------------------------------------------
export const booking = (bookingData) =>{
  return axiosInstance.post('/mail/booking', bookingData)
    .then(res=> console.log(res.data),
          // err =>Promise.reject(err.response.data.errors)
          err =>console.log(err.response.data.errors)
    )
}

//AUTH UTILITY ACTION-------------------------------------------------------
export const verifyServiceOwner = (serviceId) => {
  return axiosInstance.get(`/services/${serviceId}/verify-user`);
}

export const verifyAccountOwner = (userId) => {
  return axiosInstance.get(`/auth/${userId}/verify-user`);
}

export const getAccountDetail =() =>{
  return axiosInstance.get(`/auth/account_details`);
}


const loginSuccess = () => {
  const userId = authService.getUserId();
  const firstName = authService.getFirstName();
  const lastName = authService.getLastName();
  const email = authService.getEmail();
  const user_role = authService.getUserRole();
  return {
    type: LOGIN_SUCCESS,
    userId,
    firstName,
    lastName,
    email,
    user_role
  }
}
const loginFailure = (errors) => {
  return {
    type: LOGIN_FAILURE,
    errors
  }
}

export const checkAuthState = () =>{
  return dispatch =>{
    if(authService.isAuthenticated()){
      dispatch(loginSuccess());
    }
  }
}

// export const login = (userData) => {
//   return dispatch => {
//     return axiosInstance.post('/auth/sign-in', userData)
//       .then(res => res.data)
//       .then(token => {
//         authService.saveToken(token);
//         dispatch(loginSuccess());
//       })
//       .catch(({response}) => {
//         // dispatch(loginFailure(response.data.errors));
//         // dispatch(loginFailure(response));
//         console.log('response error', response)
//       })

  

//   }
// }

export const login = (userData) => {
  return dispatch => {
    return axiosInstance.post('/auth/admin/sign-in', userData)
      .then(res => res.data)
      .then(token => {
        authService.saveToken(token);
        dispatch(loginSuccess());
      })
      .catch(({response}) => {
        dispatch(loginFailure(response.data.errors));
      })
  }
}


export const logout =()=>{
  authService.invalidateUser();
  return {
    type: LOGOUT
  }
}

/////////////////////////////////////////////////////
//-----------------------------**SERVICE ACTIONS**-----------------------------------------------

export const createService = (serviceData) =>{
  return axiosInstance.post('/services/service_add', serviceData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}
export const resetServiceErrors = () => {
  return {
    type: RESET_SERVICE_ERRORS
  }
}

export const updateServiceSuccess = (updatedService) => {
  return {
    type: UPDATE_SERVICE_SUCCESS,
    service: updatedService
  }
}

export const updateServiceFail = (errors) => {
  return {
    type: UPDATE_SERVICE_FAIL,
    errors
  }
}

export const updateService = (serviceData) => dispatch => {
  return axiosInstance.post(`/services/service_edit/${serviceData.id}`, serviceData)
    .then(res => res.data)
    // .then(updatedService => {
    //   dispatch(updateServiceSuccess(updatedService));

      // if (serviceData.city || serviceData.street) {
      //   dispatch(reloadMap());
      // }
   // })
    .catch(({response}) => dispatch(updateServiceFail(response.data.errors)))
}




////

// export const fetchServices = (city) =>{
//   const url = city ? `/services?city=${city}`: '/services/find_all_services';//
//   return dispatch =>{
//     dispatch(fetchServicesInit());
//     axiosInstance.get(url)
//     .then(res => res.data)
//     .then(services=>dispatch(fetchServicesSuccess(services)))
//     .catch(({response})=>dispatch(fetchServicesFail(response.data.errors)))
//   }
// }
export const fetchServices = (page, countPerPage)=>{
  const url = (page!=='') ? `/services/find_all_services/${page}/${countPerPage}`: '/services/find_all_services';
  return function (dispatch){
    dispatch(fetchServicesInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(services=>{
        dispatch(fetchServicesSuccess(services));
        return services;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchServicesFail(response.data.errors));
      })
  }
}



const fetchServicesSuccess = (services) =>{
  return {
    type: FETCH_SERVICES_SUCCESS,
    services: services
  }
}
const fetchServicesInit =() =>{//used to reset the data
  return {
    type: FETCH_SERVICES_INIT 
  }
}
const fetchServicesFail =(errors) =>{
  return {
    type:FETCH_SERVICES_FAIL,
    errors
  }
}
////

export const fetchServiceById = (serviceId)=>{
    return function (dispatch){
      dispatch(fetchServiceByIdInit());
      return axiosInstance.get(`/services/service_details/${serviceId}`)
        .then(res => res.data[0])
        .then(service=>{
          dispatch(fetchServiceByIdSuccess(service));
          return service
        })
    }
}

const fetchServiceByIdInit=()=>{
  return {
    type: FETCH_SERVICE_BY_ID_INIT,
    
  }
}
const fetchServiceByIdSuccess =(service)=>{
  return {
    type: FETCH_SERVICE_BY_ID_SUCCESS,
    service:service
  }
}

export const fetchService = (serviceId)=>{
  console.log('itemId',serviceId)
  return function (dispatch){
    dispatch(fetchServiceInit());
    return axiosInstance.get(`/services/service_details/${serviceId}`)
      .then(res => res.data[0])
      .then(service=>{
        dispatch(fetchServiceSuccess(service));
        return service
      })
      .catch(({response}) => {
          dispatch(fetchServiceFail(response.data.errors));
        })
  }
}

const fetchServiceInit=()=>{
  return {
    type: FETCH_SERVICE_INIT,
    
  }
}
const fetchServiceSuccess = (service) =>{
  return {
    type: FETCH_SERVICE_SUCCESS,
    service: service
  }
}
const fetchServiceFail =(errors) =>{
  return {
    type:FETCH_SERVICE_FAIL,
    errors
  }
}


export const deleteService = (id) => dispatch => {
    
  return axiosInstance.delete(`/services/service_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteProductFail(response.data.errors)))
}
export const deleteServiceFail = (errors) => {
  return {
    type: DELETE_SERVICE_FAIL,
    errors
  }
}

export const deleteServiceSuccess = (id) => {
  return {
    type: DELETE_SERVICE_SUCCESS,
    services:id
  }
}
//

//Booking Action-----------------------------------------------------
export const createBooking = (booking)=>{
  return axiosInstance.post('/bookings', booking)
    .then(res=> res.data)
    .catch(({response})=> Promise.reject(response.data.errors))
}

export const updateBookings = (bookings) => {
  return {
    type: UPDATE_BOOKINGS,
    bookings
  }
}

//SERVICE CATEGORY------------------------------------------------------
export const createServiceCategory = (serviceCategoryData) =>{
  return axiosInstance.post('/services/service_category_add', serviceCategoryData)
    .then(res=> res.data,
          err =>Promise.reject(err.response)
    )
}

export const updateServiceCategory = (serviceCategoryData) => dispatch => {
  return axiosInstance.post(`/services/service_category_edit/${serviceCategoryData.id}`, serviceCategoryData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateServiceCategoryFail(response.data.errors)))
}
export const updateServiceCategoryFail = (errors) => {
  return {
    type: UPDATE_SERVICE_CATEGORY_BY_ID_FAIL,
    errors
  }
}

export const updateServiceCategorySuccess = (updatedServiceCategory) => {
  return {
    type: UPDATE_SERVICE_CATEGORY_BY_ID_SUCCESS,
    serviceCategory: updatedServiceCategory
  }
}
//-------------------------------------------------------------------



// export const fetchServicesCategory= (page, countPerPage)=>{
//   return axiosInstance.get(`/services/find_all_service_categories/${page}/${countPerPage}`)
//   // return axiosInstance.get(`/services/${serviceId}/verify-user`);
//     .then(res=> res.data)
//     .catch(({response})=> Promise.reject(response.data.errors))
// }

export const fetchServicesCategory = (page, countPerPage)=>{
  const url = `/services/find_all_service_categories/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchServiceCategoriesInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(serviceCategories=>{
        dispatch(fetchServiceCategoriesSuccess(serviceCategories));
        return serviceCategories
      })
      .catch(({response}) => {
        dispatch(fetchServiceCategoriesFail(response.data.errors));
      })
  }
}


const fetchServiceCategoriesSuccess = (serviceCategories) =>{
  return {
    type: FETCH_SERVICE_CATEGORIES_SUCCESS,
    serviceCategories: serviceCategories
  }
}
const fetchServiceCategoriesInit =() =>{//used to reset the data
  return {
    type: FETCH_SERVICE_CATEGORIES_INIT 
  }
}
const fetchServiceCategoriesFail =(errors) =>{
  return {
    type:FETCH_SERVICE_CATEGORIES_FAIL,
    errors
  }
}

//event create dropdown
export const fetchServiceCategoriesByService = (serviceName) =>{
  const url = `/services/find_all_service_categories_by_service_name/${serviceName}`;
  return axiosInstance.get(url)
    .then(res => res.data)
    // .then(subcategories=>{
    //   return subcategories;
      // })
}



//
// export const fetchCategoriesByServiceName= (serviceName) =>{
//   const url = `/services/find_all_categories_by_serviceName/${serviceName}`;//
//   return dispatch =>{
//     dispatch(fetchCategoriesByServiceNameInit());
//     return axiosInstance.get(url)
//     .then(res => res.data[0])
//     .then(serviceCategoriesByServiceName=>dispatch(fetchCategoriesByServiceNameSuccess(serviceCategoriesByServiceName)))
//     .catch(({response})=>console.log(response))//dispatch(fetchServiceItemByCategoryFail(response.data.errors)))
//   }
// }

export const fetchCategoriesByServiceName = (serviceName)=>{
  return function (dispatch){
    dispatch(fetchCategoriesByServiceNameInit());
    return axiosInstance.get(`/services/find_all_categories_by_serviceName/${serviceName}`)
      .then(res => res.data[0])
      .then(serviceCategory=>{
        dispatch(fetchCategoriesByServiceNameSuccess(serviceCategory));
        return serviceCategory
      })
  }
}

const fetchCategoriesByServiceNameInit=()=>{
  return {
    type: FETCH_SERVICE_CATEGORIES_INIT,
    
  }
}
const fetchCategoriesByServiceNameSuccess = (serviceCategoriesByServiceName) =>{
  return {
    type: FETCH_SERVICE_CATEGORIES_SUCCESS,
    categories: serviceCategoriesByServiceName
  }
}
const fetchCategoriesByServiceNameFail =(errors) =>{
  return {
    type:FETCH_SERVICE_CATEGORIES_FAIL,
    errors
  }
}

export const fetchCategoryById = (categoryId)=>{
  return function (dispatch){
    dispatch(fetchCategoryByIdInit());
    return axiosInstance.get(`/services/category_details/${categoryId}`)
      .then(res => res.data[0])
      .then(serviceCategory=>{
        dispatch(fetchCategoryByIdSuccess(serviceCategory));
        return serviceCategory
      })
  }
}


const fetchCategoryByIdInit=()=>{
  return {
    type: FETCH_SERVICE_CATEGORY_BY_ID_INIT,
    
  }
}
const fetchCategoryByIdSuccess = (serviceCategory) =>{
  return {
    type: FETCH_SERVICE_CATEGORY_BY_ID_SUCCESS,
    serviceCategory: serviceCategory
  }
}
const fetchCategoryByIdFail =(errors) =>{
  return {
    type:FETCH_SERVICE_CATEGORY_BY_ID_FAIL,
    errors
  }
}




export const fetchServiceCategory = (serviceCategoryId)=>{
  console.log('itemId',serviceCategoryId)
  return function (dispatch){
    dispatch(fetchServiceCategoryInit());
    return axiosInstance.get(`/services/category_details/${serviceCategoryId}`)
      .then(res => res.data[0])
      .then(serviceCategory=>{
        dispatch(fetchServiceCategorySuccess(serviceCategory));
        return serviceCategory
      })
      .catch(({response}) => {
          dispatch(fetchServiceCategoryFail(response.data.errors));
        })
  }
}

const fetchServiceCategoryInit=()=>{
  return {
    type: FETCH_SERVICE_CATEGORY_INIT,
    
  }
}
const fetchServiceCategorySuccess = (serviceCategory) =>{
  return {
    type: FETCH_SERVICE_CATEGORY_SUCCESS,
    serviceCategory: serviceCategory
  }
}
const fetchServiceCategoryFail =(errors) =>{
  return {
    type:FETCH_SERVICE_CATEGORY_FAIL,
    errors
  }
}

export const deleteServiceCategory = (id) => dispatch => {
    
  return axiosInstance.delete(`/services/service_category_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteProductFail(response.data.errors)))
}
export const deleteServiceCategoryFail = (errors) => {
  return {
    type: DELETE_SERVICE_CATEGORY_FAIL,
    errors
  }
}

export const deleteServiceCategorySuccess = (id) => {
  return {
    type: DELETE_SERVICE_CATEGORY_SUCCESS,
    serviceCategories:id
  }
}
//


//-----------------------SERVICE AREA-------------------------------------------

export const createServiceArea = (serviceAreaData) =>{
  return axiosInstance.post('/services/service_area_add', serviceAreaData)
    .then(res=> res.data,
          err =>Promise.reject(err.response)
    )
}

export const updateServiceArea = (serviceAreaData) => dispatch => {
  return axiosInstance.post(`/services/service_area_edit/${serviceAreaData.id}`, serviceAreaData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateServiceAreaFail(response)))
}
export const updateServiceAreaFail = (errors) => {
  return {
    type: UPDATE_SERVICE_AREA_FAIL,
    errors
  }
}

export const updateServiceAreaSuccess = (serviceArea) => {
  return {
    type: UPDATE_SERVICE_AREA_SUCCESS,
    serviceArea: serviceArea
  }
}

export const fetchServiceAreas = (page, countPerPage)=>{
  const url = `/services/find_all_service_areas/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchServiceAreasInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(serviceAreas=>{
        dispatch(fetchServiceAreasSuccess(serviceAreas));
        return serviceAreas
      })
      .catch(({response}) => {
        dispatch(fetchServiceAreasFail(response.data.errors));
      })
  }
}


const fetchServiceAreasSuccess = (serviceAreas) =>{
  return {
    type: FETCH_SERVICE_AREAS_SUCCESS,
    serviceAreas: serviceAreas
  }
}
const fetchServiceAreasInit =() =>{//used to reset the data
  return {
    type: FETCH_SERVICE_AREAS_INIT 
  }
}
const fetchServiceAreasFail =(errors) =>{
  return {
    type:FETCH_SERVICE_AREAS_FAIL,
    errors
  }
}

export const fetchServiceArea = (serviceAreaId)=>{
  console.log('itemId',serviceAreaId)
  return function (dispatch){
    dispatch(fetchServiceAreaInit());
    return axiosInstance.get(`/services/service_area_details/${serviceAreaId}`)
      .then(res => res.data[0])
      .then(serviceArea=>{
        dispatch(fetchServiceAreaSuccess(serviceArea));
        return serviceArea
      })
      .catch(({response}) => {
          dispatch(fetchServiceAreaFail(response.data.errors));
        })
  }
}

const fetchServiceAreaInit=()=>{
  return {
    type: FETCH_SERVICE_AREA_INIT,
    
  }
}
const fetchServiceAreaSuccess = (serviceArea) =>{
  return {
    type: FETCH_SERVICE_AREA_SUCCESS,
    serviceArea: serviceArea
  }
}
const fetchServiceAreaFail =(errors) =>{
  return {
    type:FETCH_SERVICE_AREA_FAIL,
    errors
  }
}


export const deleteServiceArea = (id) => dispatch => {
    
  return axiosInstance.delete(`/services/service_area_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteProductFail(response.data.errors)))
}
export const deleteServiceAreaFail = (errors) => {
  return {
    type: DELETE_SERVICE_AREA_FAIL,
    errors
  }
}
export const deleteServiceAreaSuccess = (id) => {
  return {
    type: DELETE_SERVICE_AREA_SUCCESS,
    serviceAreas:id
  }
}

//get category by service

export const categoryListByServiceName = (serviceName)=>{
  return axiosInstance.get(`/services/getCategories/${serviceName}`)
  // return axiosInstance.get(`/services/${serviceId}/verify-user`);
    .then(res=> res.data)
    .catch(({response})=> Promise.reject(response.data.errors))
}
//Image Upload Action------------------------------------------------
export const uploadImage = (image,location) => {
  const formData = new FormData();
  formData.append('image', image);//'image' is same as the server image-upload.js ->upload.single('image') 
console.log('formData', formData)
  return axiosInstance.post(`/files/image-upload/${location}`, formData)
    .then(json => {
      return json.data.imageUrl;
    })
    .catch(({response}) => Promise.reject(response.data.errors[0]))
}


//------------------------------------**service item**------------------------------------------------------------

export const fetchServiceItems = (page, countPerPage)=>{
  const url = `/services/find_all_service_items/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchServiceItemsInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(servicesItems=>{
        dispatch(fetchServiceItemsSuccess(servicesItems));
        return servicesItems;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchServiceItemsFail(response.data.errors));
      })
  }
}



const fetchServiceItemsSuccess = (servicesItems) =>{
  return {
    type: FETCH_SERVICE_ITEMS_SUCCESS,
    serviceItems: servicesItems
  }
}
const fetchServiceItemsInit =() =>{//used to reset the data
  return {
    type: FETCH_SERVICE_ITEMS_INIT 
  }
}
const fetchServiceItemsFail =(errors) =>{
  return {
    type:FETCH_SERVICE_ITEMS_FAIL,
    errors
  }
}






export const fetchServiceItemsByCategoryName= (categoryName) =>{
  const url = `/services/find_all_serviceItems_by_categoryName/${categoryName}`;//
  return dispatch =>{
    dispatch(fetchServiceItemsByCategoryNameInit());
    axiosInstance.get(url)
    .then(res => res.data)
    .then(serviceItemsByCategoryName=>dispatch(fetchServiceItemsByCategoryNameSuccess(serviceItemsByCategoryName)))
    .catch(({response})=>console.log(response))//dispatch(fetchServiceItemByCategoryFail(response.data.errors)))
  }
}

const fetchServiceItemsByCategoryNameInit=()=>{
  return {
    type: FETCH_SERVICE_ITEMS_INIT,
    
  }
}

const fetchServiceItemsByCategoryNameSuccess = (serviceItemsByCategoryName) =>{
  return {
    type: FETCH_SERVICE_ITEMS_SUCCESS,
    serviceItems: serviceItemsByCategoryName
  }
}
const ffetchServiceItemsByCategoryNameFail =(errors) =>{
  return {
    type:FETCH_SERVICE_ITEMS_FAIL,
    errors
  }
}


export const fetchServiceItemsByCategory = (serviceId) =>{
  const url = `/services/find_all_service_items_by_category/${serviceId}`;//
  return dispatch =>{
    dispatch(fetchServiceItemsByCategoryInit());
    axiosInstance.get(url)
    .then(res => res.data)
    .then(serviceItemsByCategory=>dispatch(fetchServiceItemByCategorySuccess(serviceItemsByCategory)))
    .catch(({response})=>console.log(response))//dispatch(fetchServiceItemByCategoryFail(response.data.errors)))
  }
}

const fetchServiceItemsByCategoryInit=()=>{
  return {
    type: FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT,
    
  }
}
const fetchServiceItemByCategorySuccess = (serviceItemsByCategory) =>{
  return {
    type: FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_SUCCESS,
    serviceItemsByCategory: serviceItemsByCategory
  }
}
const fetchServiceItemByCategoryFail =(errors) =>{
  return {
    type:FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_FAIL,
    errors
  }
}

export const createServiceItem = (serviceItemData) =>{
  return axiosInstance.post('/services/service_item_add', serviceItemData)
    .then(res=> res.data,
          err =>Promise.reject(err.response)
    )
}

export const updateServiceItem = (serviceItemData) => dispatch => {
  console.log('serviceItemData',serviceItemData)
  return axiosInstance.post(`/services/service_item_edit/${serviceItemData.id}`, serviceItemData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateServiceItemFail(response.data.errors)))
}
export const updateServiceItemFail = (errors) => {
  return {
    type: UPDATE_SERVICE_ITEM_FAIL,
    errors
  }
}

export const updateServiceItemSuccess = (updatedServiceItem) => {
  return {
    type: UPDATE_SERVICE_ITEM_SUCCESS,
    serviceItem: updatedServiceItem
  }
}

export const fetchServiceItem = (itemId)=>{
  console.log('itemId',itemId)
  return function (dispatch){
    dispatch(fetchServiceItemInit());
    return axiosInstance.get(`/services/service_item_details/${itemId}`)
      .then(res => res.data[0])
      .then(serviceItem=>{
        dispatch(fetchServiceItemSuccess(serviceItem));
        return serviceItem
      })
      .catch(({response}) => {
        dispatch(fetchServiceItemFail(response.data.errors));
      })
  }
}

const fetchServiceItemInit=()=>{
  return {
    type: FETCH_SERVICE_ITEM_INIT,
    
  }
}
const fetchServiceItemSuccess = (serviceItem) =>{
  return {
    type: FETCH_SERVICE_ITEM_SUCCESS,
    serviceItem: serviceItem
  }
}
const fetchServiceItemFail =(errors) =>{
  return {
    type:FETCH_SERVICE_ITEM_FAIL,
    errors
  }
}

export const deleteServiceItem = (id) => dispatch => {
      
  return axiosInstance.delete(`/services/service_item_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteProductFail(response.data.errors)))
}
export const deleteServiceItemFail = (errors) => {
  return {
    type: DELETE_SERVICE_ITEM_FAIL,
    errors
  }
}

export const deleteServiceItemSuccess = (id) => {
  return {
    type: DELETE_SERVICE_ITEM_SUCCESS,
    serviceItems:id
  }
}




//Review Actions-------------------------------------------------------------
export const createReview = (reviewData, bookingId) =>{
  return axiosInstance.post(`/reviews?bookingId=${bookingId}`, reviewData)
    .then(res => res.data)
    .catch(({response})=> Promise.reject(response.data.errors))
}

export const getReviews = (serviceId) =>{
  return axiosInstance.get(`/reviews?serviceId=${serviceId}`)
    .then(res => res.data)
    .catch(({response})=> Promise.reject(response.data.errors))
}