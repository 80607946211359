import React from 'react';
import {Link, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
// import ServiceSearchInput from '../services/ServiceSearchInput';
import TopBar from './TopBar';
import { BookingModal } from '../../components/booking/BookingModal'; 
class Header extends React.Component{
  constructor(){
    super();
 
    this.handleLogout = this.handleLogout.bind(this);
}


  handleLogout(){
    this.props.logout();
    this.props.history.push('/');//the history object is from the HOC ->withRouter
  }
  renderAuthButton(isAuth){
    if(isAuth){
      return <a className='nav-item nav-link clickable' onClick={this.handleLogout}>Logout</a>
    }
   
  }

  renderOwnerSection(isAuth){
    if(isAuth){
      return(
        <React.Fragment>
      
        <div className="nav-item dropdown">
          <a className="nav-link nav-item dropdown-toggle clickable" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Account
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            {/* <Link className="dropdown-item" to="/services/new">Create Appointment</Link>
            <Link className="dropdown-item" to="/services/manage">Manage Appointment</Link> */}
            <Link className="dropdown-item" to="/account/detail">Manage Account</Link>
          </div>
        </div>
        {this.renderAuthButton(isAuth)}
        
        </React.Fragment>
      )
    }
  }

 
  render(){
    const {firstName, isAuth} = this.props.auth;
    // const {errors} = this.state;
    {if (window.location.pathname === '/login') 
    
    return (
      <div>
         <TopBar/>
        
        <div className="d-flex justify-content-center" style={{backgroundColor:'#f8f9fa', width:'100%', height:'200px'}}>
          
                <nav className='bg-light'>
            
                  <h1>Admin Dashboard Incorporate
                   
                      {/* <img src={process.env.PUBLIC_URL + '/img/react-logo.svg'} alt=""/>  */}
                  </h1> 
                
                </nav> 
           
        </div>
     
      </div>
    )

    }
    return (
      <div>
         <TopBar/>
        
        <div className="navigation-area1" style={{backgroundColor:'#f8f9fa', width:'100%'}}>
          
          <div className='container-fluid'>
          
                <nav className='navbar navbar-light navbar-expand-md bg-light'>
            
              
              
                  <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNavAltMarkup' aria-controls='navbarNavAltMarkup' aria-expanded='false' aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                  </button>
                  <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
                    <div className='navbar-nav ml-auto'>  
                     
                      {this.renderOwnerSection(isAuth)}
                      
                     

                    </div>
                  </div>   
                </nav> 
            </div>
         
        </div>
 
      </div>

       
    )
  }
}

function mapStateToProps(state){
  return{
    auth: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(Header));