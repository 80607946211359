import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import moment from 'moment';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
const BwmReactDateTimeField = ({ tooltip, tooltipPlacement, disabled, input, label, placeholder, meta: {valid, touched, error}, ...props}) => {
  const classes = classNames('form-group', {
    'has-error': (touched && !valid),
    'has-success': (touched && valid)
  })
  console.log(input.value)

  return (<div className={classes}>
    {label &&
        <label htmlFor={input.name}>{label}</label>
    }
    <DateTime
      name={input.name}
      //value={input.value}
      locale='en'
      dateFormat='DD/MM/YYYY'
      timeFormat='hh:mm A'
      onChange={param => {
        console.log(param)
        input.onChange(param.format('MM/DD/YYYY hh:mm A'))
      }}
      disabled={disabled}
    />
    {(!valid && touched) &&
      <p className='help-block'>{error}</p>
    }
  </div>)
}

BwmReactDateTimeField.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string
}
export default BwmReactDateTimeField