import { FETCH_PRODUCTS_SUCCESS, 
    FETCH_PRODUCT_BY_ID_SUCCESS,
    FETCH_PRODUCT_BY_ID_INIT,
    FETCH_PRODUCTS_INIT,
    FETCH_PRODUCTS_FAIL, 
    FETCH_PRODUCT_SUCCESS, 
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    RESET_PRODUCT_ERRORS,
    DELETE_PRODUCT_SUCCESS
   } from "../actions/products/types";

const INITIAL_STATE = {
products:{
   data:[],
   errors:[]
},
product:{
   data:{}
}    
}

export const productReducer = (state=INITIAL_STATE.products, action) =>{
switch(action.type){
   case FETCH_PRODUCTS_INIT:
       return {...state, data:[],errors:[]}
   case FETCH_PRODUCTS_SUCCESS:
       return {...state, data: action.products }
   case FETCH_PRODUCTS_FAIL:
       return Object.assign({},{errors: action.errors, data:[]})
   case DELETE_PRODUCT_SUCCESS:
      return {...state, 
      // data: action.product
      
      data:state.data.filter(x => x.id !== action.products)
   }
   default:
       return state;
}
}

export const selectedProductReducer = (state=INITIAL_STATE.product, action) =>{
switch(action.type){
    case FETCH_PRODUCT_BY_ID_INIT:
       return {...state, data: {} }
    case FETCH_PRODUCT_BY_ID_SUCCESS:
       return {...state, data: action.product }
    case FETCH_PRODUCT_SUCCESS:
        return {...state, data: action.product }
    case UPDATE_PRODUCT_SUCCESS:
       return {...state, data: action.product};
    case UPDATE_PRODUCT_FAIL:
       return {...state, errors: action.errors};
   
    case RESET_PRODUCT_ERRORS:
       return {...state, errors: []};
    default:
       return state;
}
}