export const FETCH_DIGITAL_WALLET_INIT ='FETCH_DIGITAL_WALLET_INIT';
export const FETCH_DIGITAL_WALLET_SUCCESS ='FETCH_DIGITAL_WALLET_SUCCESS';
export const FETCH_DIGITAL_WALLET_FAIL = 'FETCH_DIGITAL_WALLET_FAIL';
export const RESET_DIGITAL_WALLET_ERRORS = 'RESET_DIGITAL_WALLET_ERRORS';
export const UPDATE_DIGITAL_WALLET_FAIL='UPDATE_DIGITAL_WALLET_FAIL';
export const UPDATE_DIGITAL_WALLET_SUCCESS='UPDATE_DIGITAL_WALLET_SUCCESS';

export const  FETCH_DIGITAL_WALLET_BALANCE_INIT = 'FETCH_DIGITAL_WALLET_BALANCE_INIT';
export const  FETCH_DIGITAL_WALLET_BALANCE_SUCCESS = 'FETCH_DIGITAL_WALLET_BALANCE_SUCCESS';
export const  FETCH_DIGITAL_WALLET_BALANCE_FAIL = 'FETCH_DIGITAL_WALLET_BALANCE_FAIL';
export const  UPDATE_DIGITAL_WALLET_BALANCE_SUCCESS = 'UPDATE_DIGITAL_WALLET_BALANCE_SUCCESS';
export const  UPDATE_DIGITAL_WALLET_BALANCE_FAIL = 'UPDATE_DIGITAL_WALLET_BALANCE_FAIL';
export const  RESET_DIGITAL_WALLET_BALANCE_ERRORS = 'RESET_DIGITAL_WALLET_BALANCE_ERRORS';


export const FETCH_DIGITAL_WALLET_BY_ID_INIT = 'FETCH_DIGITAL_WALLET_BY_ID_INIT';
export const FETCH_DIGITAL_WALLET_BY_ID_SUCCESS = 'FETCH_DIGITAL_WALLET_BY_ID_SUCCESS';
export const FETCH_DIGITAL_WALLET_BY_ID_FAIL = 'FETCH_DIGITAL_WALLET_BY_ID_FAIL';
export const RESET_DIGITAL_WALLET_BY_ID_ERRORS = 'RESET_DIGITAL_WALLET_BY_ID_ERRORS';
export const UPDATE_DIGITAL_WALLET_BY_ID_FAIL='UPDATE_DIGITAL_WALLET_BY_ID_FAIL';
export const UPDATE_DIGITAL_WALLET_BY_ID_SUCCESS='UPDATE_DIGITAL_WALLET_BY_ID_SUCCESS';


export const  FETCH_DIGITAL_WALLET_BALANCE_BY_ID_INIT = 'FETCH_DIGITAL_WALLET_BALANCE_BY_ID_INIT';
export const  FETCH_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS = 'FETCH_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS';
export const  FETCH_DIGITAL_WALLET_BALANCE_BY_ID_FAIL = 'FETCH_DIGITAL_WALLET_BALANCE_BY_ID_FAIL';
export const  RESET_DIGITAL_WALLET_BALANCE_BY_ID_ERRORS = 'RESET_DIGITAL_WALLET_BALANCE_BY_ID_ERRORS';
export const  UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_FAIL = 'UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_FAIL';
export const  UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS = 'UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS';


export const FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_INIT = 'FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_INIT';
export const FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_SUCCESS = 'FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_SUCCESS';
export const FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_FAIL = 'FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_FAIL';
