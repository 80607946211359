import React, {Component} from 'react';
import {UserGuard} from '../../shared/auth/UserGuard';
import * as actions from '../../../actions';
import * as digitalWalletActions from '../../../actions/digital-currency';
import AccountUserForm from './AccountUserForm';
import AccountDetailsForm from './AccountDetailsForm';
import Sidebar from '../../shared/Sidebar';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Back from "../../shared/navigation/Back";
var QRCode = require('qrcode.react');

class AccountDetails extends Component{
    constructor(){
        super();
        this.state ={
          response: {},
          isAllowed: false,
          isFetching: true,
          data:'',
          isLoading:false,
          errors: [],
          errorsDetail:[],
          redirect: false,
          isOwner: false,
          viewWallet: false,
          wallet_address: '',
          wallet_balance:'',
          transactions:'',
          cards:[],
        
          
        }
        this.genderCategories = ['Female'];//not used yet
        this.options=['true', 'false'];
        this.updateAccountDetails = this.updateAccountDetails.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
        this.resetAccountErrors = this.resetAccountErrors.bind(this);
        this.verifyAccountOwner = this.verifyAccountOwner.bind(this);
        this.getWallet = this.getWallet.bind(this);
        this.createWallet = this.createWallet.bind(this);
        this.getTransactions = this.getTransactions.bind(this);
        this.getPrivateImages = this.getPrivateImages.bind(this);
        this.deletePrivateCardImages = this.deletePrivateCardImages.bind(this);
        // this.getPrivateImage = this.getPrivateImage.bind(this);
       // this.isOwner = this.isOwner.bind(this);
    }
    
    componentWillMount(){
        // const serviceId = this.props.match.params.id;
        this.props.dispatch(actions.fetchAccountDetails())
          .then((account)=>{
            account
          });
        this.props.dispatch(actions.fetchAccount())
          .then((account)=>{
            account
          });

        this.props.dispatch(actions.getPrivateImages())
          .then((images)=>{
            this.setState({cards:images})
          });
        this.props.dispatch(digitalWalletActions.fetchDigitalWallet())
          .then((digitalWalletAccount)=>{
            console.log('digitalWalletAccount',digitalWalletAccount)
            digitalWalletAccount
          });
        this.props.dispatch(digitalWalletActions.fetchDigitalWalletBalance())
        .then((digitalWalletAccountBalance)=>{
          console.log('digitalWalletAccountBalance',digitalWalletAccountBalance)
          digitalWalletAccountBalance
          this.setState({wallet_balance:digitalWalletAccountBalance})
        });
    }
     
    componentDidMount(){
       this.verifyAccountOwner();
       //let user = this.isOwner();
      // this.getAccountDetail()
    }

   
    updateAccountDetails(accountData){
      const {accountDetails} = this.props;
      console.log({accountDetails})
      let mergeData = {...accountDetails, ...accountData}
      console.log('mergeData',mergeData)
      this.props.dispatch(actions.updateAccountDetails(mergeData))
      .then((response) => {
        if(response.status ==='success'){
          toast.success("Success");
          this.setState({errors:[]});
          this.props.dispatch(actions.updateAccountDetailsSuccess(mergeData));
        }else{
          // console.log(response.errors)
          this.setState({errors:[response.errors[0]]})
        }
      })
      
    }

    updateAccount(userData){
      const {account} = this.props;
      //const {errors} = this.props.errors;
      //console.log({account});
      let mergeData = {...account, ...userData};
      this.props.dispatch(actions.updateAccount(mergeData))
      .then((response) => {
        if(response.status ==='success'){
          toast.success("Success");
          this.setState({errors:[]});
          // this.props.dispatch(actions.updateAccountSuccess(mergeData));
          this.props.dispatch(actions.getPrivateImages())
          .then((images)=>{
            this.setState({cards:images})
          });
        }else{
          // console.log(response)
          this.setState({errors:[response.errors[0]]});
        }
      });
      // .catch(errors =>this.setState({errors:[errors.errors[0]]}));
    //}).catch(errors =>console.log(errors.errors[0]))//this.setState({errors:[errors.errors[0]]}));//when you like to have only single array element
        // .then(errors =>this.setState({errors:[...this.state.errors, errors.errors[0]]}))//when you like to add
    }

    createWallet(){
      this.props.dispatch(digitalWalletActions.createWallet())
      .then((response) => {
        if(response.status){
          this.setState({wallet_address: response.wallet_address})
          toast.success("Wallet Successfully Created");
        }else if(response.wallet){
          this.setState({wallet_address: response.wallet_address})
          toast.error(response.errors);
        }else{
          toast.error('Something went wrong. Refresh Page');
        }
    
      });
    }

    resetAccountErrors(){
      this.props.dispatch(actions.resetAccountErrors());
    }

    verifyAccountOwner(){
      const {userId} = this.props.auth;
      this.setState({isFetching: true});
        return actions.verifyAccountOwner(userId)
            .then(()=>{
            this.setState({isAllowed: true, isFetching: false,isOwner:true})
            },
            ()=>{
            this.setState({isAllowed: false, isFetching: false})
            })
    }
    isOwner(){
      
    }

    getWallet(){ 
      this.state.viewWallet==false?this.setState({viewWallet:true}):this.setState({viewWallet:false})
      
    }
    getTransactions() {
      digitalWalletActions.fetchTransactions()
      .then((res)=>{
        // console.log(res)
        this.setState({ transactions: res});
       
      })
      .catch()    
    }

    getPrivateImages(){
        return this.props.dispatch(actions.getPrivateImages())
        .then((images)=>{
          this.setState({cards:images})
        });
    }

    // getPrivateImage(card){
    //   return actions.getPrivateImage(card)
    // }
    deletePrivateCardImages(index){
      this.props.dispatch(actions.deletePrivateCardImages(index))
      .then((response) => {
        if(response.status ==='success'){
          toast.success("Success");
          this.setState({errors:[]});
          this.props.dispatch(actions.getPrivateImages())
          .then((images)=>{
            this.setState({cards:images})
          });
        }
        else if(response.status ==='empty'){
          toast.error("There is no image");
        }
        else{
          console.log(response)
          this.setState({errors:[response.errors[0]]});
          toast.error('Something went wrong. Please Login');
        }
      });
    }



    render(){
        const {isAllowed, isFetching, errors, errorsDetail} = this.state;
        const {accountDetails} = this.props;
       console.log('process.env.REACT_APP_CRYPTO_CURRENCY',process.env.REACT_APP_CRYPTO_CURRENCY)
        const columns = [
          {
            name: 'Date',
            // selector: 'time',
            cell: row =>moment.unix(row.time).format("MM/DD/YYYY hh:mm:ss")
          },
          {
            name: 'Amount Received',
            cell: row =>row.amounts_received[0].amount + 'BTC'
          }]
        if(accountDetails.account_id){
        return(
                
          <UserGuard isAllowed={isAllowed} isFetching={isFetching}> 
            <div className="d-flex" id="wrapper">
              <Sidebar/>
              <div className="container-fluid">
              <div className="row">
                      <div className="col-md-3 col-sm-3">
                      <Back/>
                      </div></div>
                
              
                <div className="accountDetail">
                  <section id='accountUserForm'>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                      
                        <h2>Account User</h2>
                        {this.props.initialValues.image_link && 
                                    <div className="img-preview-container">
                                        <div className="img-preview">
                                            {/* <img src={'http://localhost:3006/users/'+this.props.initialValues.image_link} alt=""/>   */}
                                           
                                            <img style={{height:"150px",width:"150px"}} src={process.env.REACT_APP_FILE_SERVER+'users/'+this.props.initialValues.image_link} alt=""/>         
                                        </div>
                                    </div>
                                }
                      </div>
                      {process.env.REACT_APP_CRYPTO_CURRENCY=="True" && <div className="col-md-6  col-sm-12">
                        <div className="row">
                          <div className="col-5">
                                  {/* {this.state.wallet_address && <div>
                                    <h2>Wallet </h2>
                                    <QRCode value={this.state.wallet_address} />
                                    <br/>
                                    {this.state.viewWallet ? this.state.wallet_address : null }
                                    <input type="submit" value="address" onClick={this.getWallet} />
                                    <input type="submit" value="Transactions" onClick={this.getTransactions} data-toggle="modal" data-target="#transaction-modal" />
                                  </div>} */}

                                  {this.props.initialValues.wallet_address && <div>
                                    <h2>Wallet </h2>
                                    <QRCode value={this.props.initialValues.wallet_address} />
                                    <br/>
                                    { this.state.viewWallet ? this.props.initialValues.wallet_address : null }
                                    <input type="submit" value="Address" onClick={this.getWallet} />
                                    <input type="submit" value="Transactions" onClick={this.getTransactions} data-toggle="modal" data-target="#transaction-modal" />
                                  </div>}

                                  {!this.props.initialValues.wallet_address && <div>
                                    <h2>Wallet </h2>
                                    {/* <QRCode value={this.props.initialValues.wallet_address} /> */}
                                    
                                    Please Create A Wallet Clicking the Button below
                                    <input type="submit" value="Create Wallet" onClick={this.createWallet} />
                                  </div>}
                          </div>
                          <div className="col-1">
                              {/* <h2></h2> */}
                             
                              {this.props.initialValues.wallet_balance && <div>
                                <h2 className="text-right" style={{'marginLeft': '1.5rem'}}>Balance</h2>
                                <h1 style={{'fontSize': '5.5rem'}}> ${this.state.wallet_balance} </h1>   
                                  </div>}
                             

                              {/* { this.state.viewWallet ? this.props.initialValues.digitalWalletAccountBalance : null } */}
                              {/* {this.props.initialValues.wallet_balance } */}
                          </div>
                        </div>
                      </div> 

                      }
                      
                      {process.env.REACT_APP_CRYPTO_CURRENCY=="False" && <div className="col-md-6  col-sm-12">
                          <div className="row">
                            <div className="col-5">

                            </div>
                            <div className="col-1">
                            {this.props.initialValues.wallet_balance && <div>
                                <h2 className="text-right" style={{'marginLeft': '1.5rem'}}>Balance</h2>
                                <h1 style={{'fontSize': '5.5rem'}}> ${this.state.wallet_balance} </h1>   
                                  </div>}
                            </div>
                          </div>
                      </div>
                      }

                    </div> 
                  </section>
                 
                  <section id='accountUserForm'>
                    <div className="row">
                              <div className="col-12">
                              <AccountUserForm  
                                                  submitCb={this.updateAccount} 
                                                  errors={errors}
                                                  isOwner={this.state.isOwner}
                                                  options={this.options}
                                                  initialValues ={this.props.initialValues}
                                                  />
                                                
                            </div>
                          </div>
                  </section>
                                
                     
                  {process.env.REACT_APP_ACCOUNT_DETAILS_FORM?'BD' && <section id="national-card">
                    
                    {this.props.initialValues.national_card_image &&  
                                
                          <div><h2>National Identification Card</h2>
                              <div className="row">
                                  {this.state.cards.map((card,i) => (
                                    
                                      <div key={i} className="col-3 mapImage">
                                          {/* <img className="national-id" src={process.env.REACT_APP_FILE_SERVER+'cards/'+this.props.initialValues.national_card_image[i]} alt="" width="120vh"/> */}
                                          {/* <img className="national-id" src={'http://localhost:8081/api/v1/files/file-service/private/uploads/cards/'+this.props.initialValues.national_card_image[i]} alt="" width="120vh"/> */}
                                          {/* <img className="national-id" src={()=>getPrivateImage(card)} alt="" width="120vh"/> */}
                                          <img className="national-id" 
                                            src={`data:image/jpeg;base64,${card}`} 
                                            alt="" width="120vh"
                                            
                                          />
                                    <a onClick={()=>this.deletePrivateCardImages(i)}>
                                      <span className="fa fa-trash" style={{color:'red'}}>&nbsp;</span>
                                      
                                    </a>
                                      </div>
                              ))}
                              </div>
                          </div>
                          }
                    </section>
                    :<div></div>
                    }
          
                  <section id='accountDetailForm'>
                          <div className="row">
                            <div className="col-12">
                              <h2>Account Details</h2>
                              <AccountDetailsForm  
                                                  submitCb={this.updateAccountDetails} 
                                                  options={this.genderCategories}
                                                  errors={errorsDetail}
                                                  initialValues ={this.props.initialValues}
                                                  />

                            </div>
                          </div>       
                  </section> 
                </div>
              </div>


                <div>
                  <div className="modal fade" id="transaction-modal">
                      <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                              <div className="modal-header">
                                  <h4 className="modal-title">Transactions</h4>
                                  <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button>
                              </div>
                              <div className="modal-body">
                              
                              <DataTable
                                // title="Users"
                                  columns={columns}
                                  data={this.state.transactions}
                                 // conditionalRowStyles={conditionalRowStyles}
                                  highlightOnHover
                                 /// pagination
                                  //paginationServer
                                  //paginationTotalRows={totalRows}
                                 // paginationPerPage={countPerPage}
                                 // paginationComponentOptions={{
                                 //     noRowsPerPage: true
                                 // }}
                                  //onChangePage={page => setPage(page)}
                                  />
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                              
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

            </div>
          </UserGuard>  
        )
        }else 
        return(
            <div>
              <h1>Loading...</h1>
            </div>
        )    
      }
    
}      

function mapStateToProps(state){
    return {
        auth: state.auth,
        accountDetails: state.accountDetails.data,
        account: state.account.data,
        digitalWalletAccount: state.digitalWalletAccount.data,
        digitalWalletAccountBalance: state.digitalWalletAccountBalance.data,
        // errors: state.account.errors,
       // errors: state.account,
       // errors: state.account,
       
        initialValues: {
          email:state.account.data.email,
          password: state.account.data.password_hash,
          passwordConfirmation:state.account.data.password_hash,
          first_name:state.account.data.first_name,
          middle_name:state.account.data.middle_name,
          last_name:state.account.data.last_name,
          cell_phone: state.account.data.cell_phone,
          image_link: state.account.data.image_link,
          active: state.account.data.active,
          blocked: state.account.data.blocked,
          agent: state.account.data.agent,
          national_card_id: state.accountById.data.national_card_id,
          national_card_image: state.account.data.national_card_image,
          card: state.account.data.card,
          
          
          // home_phone: state.accountDetails.data.home_phone,
          address: state.accountDetails.data.address,
          city: state.accountDetails.data.city,
          region: state.accountDetails.data.region,
          postal_code: state.accountDetails.data.postal_code,
          country: state.accountDetails.data.country,
          notes: state.accountDetails.data.notes,

          holding_no:state.accountDetails.data.holding_no,
          road_no:state.accountDetails.data.road_no,
          upazila:state.accountDetails.data.upazila,
          union_parishad:state.accountDetails.data.union_parishad,

          wallet_address: state.digitalWalletAccount.data,
          wallet_balance: state.digitalWalletAccountBalance.data,
          privateImages: state.privateImages.data
          //wallet_balance: state.selectedDigitalWalletAccount.data.available_balance,
        }
    }
}

export default connect(mapStateToProps)(AccountDetails);






// <div className="container">
// <div className="accountDetail">
//   <section id='accountUserForm'>
//         <div className="row">
//           <div className="col-6">
//             <h2>Account User</h2>
//             {this.props.initialValues.image_link && 
//                         <div className="img-preview-container">
//                             <div className="img-preview">
//                                 {/* <img src={'http://localhost:3006/users/'+this.props.initialValues.image_link} alt=""/>   */}
//                                 <img style={{height:"150px"}} src={process.env.REACT_APP_FILE_SERVER+'users/'+this.props.initialValues.image_link} alt=""/>         
//                             </div>
//                         </div>
//                     } 
//             </div>
//             <div> 
//               <div className="col-3">
//                   {this.state.wallet_address && <div className="col-6">
//                     <h2>Wallet </h2>
//                     <QRCode value={this.state.wallet_address} />
//                     <br/>
//                     <p>{ this.state.viewWallet ? this.state.wallet_address : null }</p>
//                     <input type="submit" value="address" onClick={this.onClick} />
//                   </div>}

//                   {this.props.initialValues.wallet_address && <div className="col-6">
//                     <h2>Wallet </h2>
//                     <QRCode value={this.props.initialValues.wallet_address} />
//                     <br/>
//                     <p>{ this.state.viewWallet ? this.props.initialValues.wallet_address : null }</p>
//                     <input type="submit" value="address" onClick={this.onClick} />
//                   </div>}

//                   {!this.props.initialValues.wallet_address && <div className="col-6">
//                     <h2>Wallet </h2>
//                     {/* <QRCode value={this.props.initialValues.wallet_address} /> */}
                    
//                     <p>Please Create A Wallet Clicking the Button below</p>
//                     <input type="submit" value="Create Wallet" onClick={this.createWallet} />
//                   </div>}
//                 </div>
//                 <div className="col-3">
//                   Balance
//                 </div>
//             </div>
//         </div>
//         <div className="row">
//           <div className="col-12">
//             <AccountUserForm  
//                                 submitCb={this.updateAccount} 
//                                 errors={errors}
//                                 isOwner={this.state.isOwner}
//                                 options={this.options}
//                                 initialValues ={this.props.initialValues}
//                                 />
                               
//           </div>
//         </div>
//   </section>
//   <section id='accountDetailForm'>
//         <div className="row">
//           <div className="col-12">
//             <h2>Account Detail</h2>
//             <AccountDetailForm  
//                                 submitCb={this.updateAccountDetails} 
//                                 options={this.genderCategories}
//                                 errors={errorsDetail}
//                                 initialValues ={this.props.initialValues}
//                                 />

//           </div>
//         </div>       
//   </section>
// </div>
// </div>
// </div>



