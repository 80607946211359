import React from 'react';
// export const BwmSelect = props => {
//   const { input,label,className,options,touched, error, warning } = props;
//   return (
//     <select className={className} 
//       {...input}
//       onChange={value => input.onChange(value)}
//       onBlur={() => input.onBlur(input.value)}
//       options={options}
//     >
//       <div className='form-group'>
//        <label>{label}</label>
//      <div className='input-group'>
//          <select {...input} className={className} >
//            {renderOptions()}
//          </select>
//        </div>
//          {touched &&
//            ((error && <div className='alert alert-danger'>{error}</div>))}
//      </div>
//     </select>
//   )
//   function renderOptions() {
//     return options.map((option, index) => {
//       return <option key={index} value={option}> {option} </option>
//     });
//   }
// }

export const BwmSelect = ({
  input,
  label,
  options,
  className,
  values,
  meta: { touched, error, warning }
}) => {

  function renderOptions() {
    if(options =="" || options === null) return '';
    return options.map((option, index) => {
     
      return (
        
      <option key={index} value={option}> {option} </option>)
     
    });
  }
  return (
    <div className='form-group'>
      <label>{label}</label>
      <div className='input-group'>
        <select {...input} className={className} >
        <option> </option>)
          {renderOptions()}
        </select>
      </div>
        {touched &&
          ((error && <div className='alert alert-danger'>{error}</div>))}
    </div>
  )
}