import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import SubcategoryUpdateModal from "../../shared/Modal/SubcategoryUpdateModal";
import SubcategoryDeleteModal from "../../shared/Modal/SubcategoryDeleteModal";
import * as actions from '../../../actions/products/index';
import {Link} from 'react-router-dom';
export const Subcategories =({subcategoryData}) =>{
  const dispatch = useDispatch();

  const [subcategory, setSubcategory] = useState(subcategoryData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedSubcategory, setSelectedSubcategory] = useState({});
  

  const columns = [
    {
      name: 'Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/subcategory/'+row.image_link} />,
    },
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'Category',
      selector: 'category_name',
    },
   
    {
        name: 'Active',
        // selector: 'active',
        cell: row => row.active == true?'Yes': 'No',
      },
      
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-subcategory-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-subcategory-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchSubcategory(id))
    .then((res)=>{
       console.log('res',res)
      setSelectedSubcategory({ selectedSubcategory: res});
    })
    .catch()    
  }

  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteSubcategory(id))
    dispatch(actions.fetchSubcategory(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedSubcategory({ selectedSubcategory: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = subcategory.findIndex((e)=> e.id === service.id);
    var newStateArray = subcategory.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(subcategory !== null || subcategory !== undefined)
      getSubcategoryList(); 
    else return null;
  }

  function renderRows() {
    if(subcategory !== null || subcategory !== undefined)
      getSubcategoryList(); 
    else return null;
  }

  const getSubcategoryList = () => {  
    dispatch(actions.fetchSubcategories(page, countPerPage))
    .then(res => {
      console.log('res:::',res)
      setSubcategory(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setSubcategory({});
    });
  }
  
  useEffect(() => {
    getSubcategoryList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/products/create-subcategory"><button className="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Subcategory</button></Link>
      <div className="row px-4">  
        {subcategory && <SubcategoryUpdateModal record={selectedSubcategory} action={renderRow}/>}
        {subcategory && <SubcategoryDeleteModal  record={selectedSubcategory} action={renderRows}/>}
        <ToastContainer/>
        {subcategory && 
        <DataTable
        title="Subcategories"
        columns={columns}
        data={subcategory}
        // conditionalRowStyles={conditionalRowStyles}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onChangePage={page => setPage(page)}
        />
      }
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth
  }
}

export default connect(
  mapStateToProps, {
    forwardRef: true
  }
)(Subcategories);
