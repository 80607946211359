import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput} from '../shared/form/BwmInput';
import {BwmResError} from '../shared/form/BwmResError';
import {required, minLength8} from '../shared/form/validators';

const LoginForm = props => {
  const { handleSubmit, pristine, submitting, submitCb, valid, errors } = props
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      <Field
        name="email"
        type="email"
        label="Email"
        className="form-control"
        component={BwmInput}
        validate={[required, minLength8]}
      />
        
      <Field
        name="password"
        type="password"
        label="Password"
        className="form-control"
        component={BwmInput}
        validate ={[required]}
      />    

      <button className="btn btn-success btn-bwm btn-form" type="submit" disabled={!valid || pristine || submitting}>
        Submit
      </button>
      <BwmResError errors={errors} />
     
    </form>
  )
}



export default reduxForm({
  form: 'loginForm'
})(LoginForm)