import React from 'react';

class TopBar extends React.Component{

    render(){
        return (
            <div className="top-bar">
            <div className="vertical-align-containers">
              <div className="position-left">
                <div className="position-left-inner">
                  <a className="icon-widget-holder" 
                    href="https://goo.gl/maps/NgP4u3cBAkbbJyNP8" 
                    target="_blank">
                    <span className="icon-element"><i className="fa fa-map-marker" aria-hidden="true"></i></span> 
                    <span className="icon-text ">{process.env.REACT_APP_COMPANY_NAME}</span> 
                  </a>
                  <span className="separator-widget-holder">
                    <span className="separator-element"></span>
                  </span>
    
                  <span className="icon-element"><i className="fa fa-phone" aria-hidden="true"></i></span> 
                  <span className="icon-text ">{process.env.REACT_APP_COMPANY_PHONE}</span> 
                </div>
                
              </div>
              <div className="position-right">
                <div className="position-left-inner">
                  {/* <span className="icon-element"><i className="fa fa-calendar" aria-hidden="true"></i></span> 
                  <span className="icon-text ">Mon - Sat 10:00 am - 8:00 pm</span>  */}
                  {/* <span className="separator-widget-holder">
                    <span className="separator-element"></span>
                  </span> */}
                  <span className="icon-element"><i className="fa fa-question-circle-o" aria-hidden="true"></i></span> 
                  <span className="icon-text ">FAQ</span> 
                </div>
              </div>
            </div>
          </div>
        )
    }

}
export default TopBar;