import React from 'react';

export function ServiceItems(props){
    let categories = props.serviceItem;
    return Object.keys(categories).map((category, index) => {
        console.log('category',category)
        return(
            
               <tr key={index}>
                   <td>{categories[category].item_name}</td>
                   <td>{categories[category].package_1}</td>
                   <td>{categories[category].package_2}</td>
                   <td>{categories[category].package_4}</td>
                   <td>{categories[category].package_6}</td>
               </tr> 
    
                 
                )
    });
}