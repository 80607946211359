import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmInputDisabled,BwmCheckbox} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';


import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,text,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength128,maxLength512} from '../../shared/form/validators';

let init = {}
const AccountDetailsForm = props => {
    const { handleSubmit, pristine,reset, submitting, submitCb, valid, options ,errors,initialValues } = props;
    init = initialValues;
    console.log(process.env.REACT_APP_ACCOUNT_DETAILS_FORM)
    return (  
      <form onSubmit={handleSubmit(submitCb)}>
        {/* <div className="row">
            <div className="col-md-6">
                <Field
                    name="cell_phone"
                    type="text"
                    label='Cell Phone'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, phone]}
                />
            </div>
            <div className="col-md-6">
                <Field
                    name="home_phone"
                    type="text"
                    label='Home Phone'
                    className='form-control'
                    component={BwmInput}
                    validate={[home_phone]}           
                />
            </div>
        </div> */}

        {process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"? 
            <div  className="row">
                <div className="col-md-6">
                    <Field
                        name="holding_no"
                        type="text"
                        label='Holding No'
                        className='form-control'
                        component={BwmInput}
                        validate={[maxLength128]}
                    />
                </div>
                <div className="col-md-6">
                    <Field
                        name="road_no"
                        type="text"
                        label='Road No'
                        className='form-control'
                        component={BwmInput}
                        validate={[maxLength128]}
                    />
                    
                </div>
            </div>
            :
        <Field
            name="address"
            type="text"
            label='Address'
            className='form-control'
            component={BwmInput}
            // validate={[maxLength64]}
        />
        }
        <div className="row">
            <div className="col-md-6">
                <Field
                    name="city"
                    type="text"
                    label='City'
                    className='form-control'
                    component={BwmInput}
                    validate={[maxLength128]}
                />
            </div>
            <div className="col-md-6">
            {process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?
                <Field
                    name="region"
                    type="text"
                    label='District'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, minLength2,maxLength64]}
                />:
                <Field
                    name="region"
                    type="text"
                    label='Province'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, minLength2,maxLength64]}
                />

            }  
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                {process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?
                    <Field
                        name="upazila"
                        type="text"
                        label='Upazila'
                        className='form-control'
                        component={BwmInput}
                        // validate={[alphanumeric,  maxLength128]}
                    />:''
            }
            </div>
            <div className="col-md-6">
                {process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?
                    <Field
                        name="union_parishad"
                        type="text"
                        label='Union'
                        className='form-control'
                        component={BwmInput}
                        validate={[minLength4,maxLength128]}
                    />:''
                }
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <Field
                    name="postal_code"
                    type="text"
                    label='Postal Code'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, alphanumeric, minLength4, maxLength16]}
                />
            </div>
            <div className="col-md-6">
                <Field
                    name="country"
                    type="text"
                    label='Country'
                    className='form-control'
                    component={process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?BwmInputDisabled:BwmInput}
                    values={init.country}
                    validate={[required,minLength4,maxLength16,text]}
                />
            </div>
        </div>
         <Field
            name="notes"
            type="text"
            label='Notes'
            rows='6'
            className='form-control'
            component={BwmTextArea}
            validate={[maxLength512]}
        />
        <Field
            name="acknowledgement"
            type="checkbox"
            label='By Clicking here you ackknowledge that you have read our Terms and Conditions. '
            // className='form-control'
            component={BwmCheckbox}
            validate={[checkbox]}
        />
        <button className='btn  btn-success btn-bwm btn-form' type="submit" disabled={!valid || pristine || submitting}>
          Save 
        </button>
        <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
        {errors}
        <BwmResError errors={errors} />
      </form>
    )
  }
  
  export default reduxForm({
    form: 'accountDetailForm',initialValues:{init}
      
  })(AccountDetailsForm)