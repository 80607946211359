import {
  FETCH_DIGITAL_WALLET_INIT,
  FETCH_DIGITAL_WALLET_SUCCESS,
  FETCH_DIGITAL_WALLET_FAIL,


  FETCH_DIGITAL_WALLET_BALANCE_INIT,
  FETCH_DIGITAL_WALLET_BALANCE_SUCCESS,
  FETCH_DIGITAL_WALLET_BALANCE_FAIL,
  UPDATE_DIGITAL_WALLET_BALANCE_SUCCESS,
  UPDATE_DIGITAL_WALLET_BALANCE_FAIL,
  RESET_DIGITAL_WALLET_BALANCE_ERRORS,

  FETCH_DIGITAL_WALLET_BY_ID_INIT,
  FETCH_DIGITAL_WALLET_BY_ID_SUCCESS,
  FETCH_DIGITAL_WALLET_BY_ID_FAIL,

  FETCH_DIGITAL_WALLET_BALANCE_BY_ID_INIT,
  FETCH_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS,
  FETCH_DIGITAL_WALLET_BALANCE_BY_ID_FAIL,

  FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_INIT,
  FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_SUCCESS,
  FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_FAIL,


} from './types';

import axiosService from '../../services/axios-service';

const axiosInstance = axiosService.getInstance();

/////////////////////////////////////////Brands////////////////////////////////////////////////
export const createWallet = () =>dispatch=>{
  return axiosInstance.post('/digital-wallet/create_wallet')
    .then(res=> res.data)
         // err =>Promise.reject(err.response.data.errors)
    .catch(response => response.data.errors)
}
////////////////////////////////////////////////////

export const createWalletById = (id) =>dispatch=>{
  return axiosInstance.post(`/digital-wallet/create_wallet_by_id/${id}`)
    .then(res=> res.data)
         // err =>Promise.reject(err.response.data.errors)
    .catch(response => response.data.errors)
}
////////////////////////////////////////////////////
export const fetchDigitalWallet = ()=>{
  return function (dispatch){
    dispatch(fetchDigitalWalletInit());
    return axiosInstance.get(`/digital-wallet/get_wallet/`)
      .then(res => res.data)
      .then(wallet=>{
        dispatch(fetchDigitalWalletSuccess(wallet));
        return wallet
      })
      .catch(({response}) => {
          dispatch(fetchDigitalWalletFail(response));
        })
  }
}

const fetchDigitalWalletInit=()=>{
  return {
    type: FETCH_DIGITAL_WALLET_INIT,
    
  }
}
const fetchDigitalWalletSuccess = (wallet) =>{
  return {
    type: FETCH_DIGITAL_WALLET_SUCCESS,
    digitalWalletAccount: wallet
  }
}
const fetchDigitalWalletFail =(errors) =>{
  return {
    type:FETCH_DIGITAL_WALLET_FAIL,
    errors
  }
}



////////////////////////////////////////////////////
export const fetchDigitalWalletAccountById = (id)=>{
  return async function (dispatch){
    dispatch(fetchDigitalWalletByIdInit());
    return axiosInstance.get(`/digital-wallet/get_wallet_by_id/${id}`)
      .then(res => res.data)
      .then(wallet=>{
        dispatch(fetchDigitalWalletByIdSuccess(wallet));
        //return wallet
      })
      .catch(({response}) => {
          dispatch(fetchDigitalWalletByIdFail(response));
        })
  }
}


const fetchDigitalWalletByIdInit=()=>{
  return {
    type: FETCH_DIGITAL_WALLET_BY_ID_INIT,
    
  }
}
const fetchDigitalWalletByIdSuccess = (wallet) =>{
  return {
    type: FETCH_DIGITAL_WALLET_BY_ID_SUCCESS,
    digitalWalletAccountById: wallet
  }
}
const fetchDigitalWalletByIdFail =(errors) =>{
  return {
    type:FETCH_DIGITAL_WALLET_BY_ID_FAIL,
    errors
  }
}
////////////////////////////////////////////////////////////////
export const fetchDigitalWalletAccountBalanceById = (id)=>{
  return async function (dispatch){
    dispatch(fetchDigitalWalletAccountBalanceByIdInit());
    return axiosInstance.get(`/digital-wallet/get_wallet_balance_by_id/${id}`)
      .then(res => res.data.status)
      .then(balance=>{
        dispatch(fetchDigitalWalletAccountBalanceByIdSuccess(balance));
        return balance
      })
      .catch(({response}) => {
          dispatch(fetchDigitalWalletAccountBalanceByIdFail(response));
        })
  }
}


const fetchDigitalWalletAccountBalanceByIdInit=()=>{
  return {
    type: FETCH_DIGITAL_WALLET_BALANCE_BY_ID_INIT,
    
  }
}
const fetchDigitalWalletAccountBalanceByIdSuccess = (balance) =>{
  // console.log('wallet balance',balance)
  return {
    type: FETCH_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS,
    digitalWalletUserAccountBalanceById: balance
  }
}
const fetchDigitalWalletAccountBalanceByIdFail =(errors) =>{
  return {
    type:FETCH_DIGITAL_WALLET_BALANCE_BY_ID_FAIL,
    errors
  }
}




////////////////////////////////////////////////////////////////

///////////////////////
// const fetchDigitalWalletById = (id) =>async (dispatch) => {
//   axiosInstance.get(`/digital-wallet/get_wallet_by_id/${id}`)
//   return dispatch(fetchDigitalWalletById(id))
// }

// const walletInfo = (id) =>async(dispatch)=>{ 
//   await Promise.all([
//     dispatch(fetchDigitalWalletById(id)),
//     dispatch(digitalWalletActions.fetchTransactions_by_id(id))
    
//   ]);

////////////////////////////////////////////////////
export const fetchDigitalWalletBalance = ()=>{
  return function (dispatch){
    dispatch(fetchDigitalWalletBalanceInit());
    return axiosInstance.get(`/digital-wallet/get_wallet_balance/`)
    //res.data.available_balance
      .then(res => res.data.status)
      .then(balance=>{
        
        dispatch(fetchDigitalWalletBalanceSuccess(balance));
        return balance
      })
      .catch((response) => {
        console.log('response', response)
          dispatch(fetchDigitalWalletBalanceFail(response));
        })
  }
}

const fetchDigitalWalletBalanceInit=()=>{
  return {
    type: FETCH_DIGITAL_WALLET_BALANCE_INIT,
    
  }
}
const fetchDigitalWalletBalanceSuccess = (balance) =>{
  console.log('balance', balance)
  return {
    type: FETCH_DIGITAL_WALLET_BALANCE_SUCCESS,
    digitalWalletAccountBalance: balance
  }
}
const fetchDigitalWalletBalanceFail =(errors) =>{
  return {
    type:FETCH_DIGITAL_WALLET_BALANCE_FAIL,
    errors
  }
}
////////////////////////////////////////////////////////////////



export const fetchTransactions = () =>{
  return axiosInstance.get(`/digital-wallet/get_wallet_transactions/`)
    .then(res => res.data)
    .catch(({response})=> Promise.reject(response))
}


// export const fetchTransactionsById = (id) =>{
//   return axiosInstance.get(`/digital-wallet/get_wallet_transactions_by_id/${id}`)
//     .then(res => res.data)
//     .catch(({response})=> Promise.reject(response))
// }



export const fetchDigitalWalletTransactionsById = (id)=>{
  return async function (dispatch){
    dispatch(fetchDigitalWalletTransactionsByIdInit());
    return axiosInstance.get(`/digital-wallet/get_wallet_transactions_by_id/${id}`)
      .then(res => res.data)
      .then(transactions=>{
        dispatch(fetchDigitalWalletTransactionsByIdSuccess(transactions));
        //return wallet
      })
      .catch(({response}) => {
          dispatch(fetchDigitalWalletTransactionsByIdFail(response));
        })
  }
}


const fetchDigitalWalletTransactionsByIdInit=()=>{
  return {
    type: FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_INIT,
    
  }
}
const fetchDigitalWalletTransactionsByIdSuccess = (transactions) =>{
  // console.log('transactions',transactions)
  return {
    type: FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_SUCCESS,
    digitalWalletTransactionsById: transactions
  }
}
const fetchDigitalWalletTransactionsByIdFail =(errors) =>{
  return {
    type:FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_FAIL,
    errors
  }
}


//////////

export const updateBrand = (brandData) => dispatch => {
  console.log('brandData',brandData)
  return axiosInstance.post(`/products/brand_edit/${brandData.id}`, brandData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateBrandFail(response.data.errors)))
}
export const updateBrandFail = (errors) => {
  return {
    type: UPDATE_BRAND_FAIL,
    errors
  }
}

export const updateBrandSuccess = (updatedBrand) => {
  return {
    type: UPDATE_BRAND_SUCCESS,
    brand: updatedBrand
  }
}


export const deleteBrand = (id) => dispatch => {
  
  return axiosInstance.delete(`/products/brand_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteBrandFail(response.data.errors)))
}
export const deleteBrandFail = (errors) => {
  return {
    type: DELETE_BRAND_FAIL,
    errors
  }
}

export const deleteBrandSuccess = (id) => {
  return {
    type: DELETE_BRAND_SUCCESS,
    brands:id
  }
}



///////////////////////////////Product/////////////////////////////////////////////////////////
export const createProduct = (productData) =>{
    return axiosInstance.post('/products/product_add', productData)
      .then(res=> res.data,
            err =>Promise.reject(err.response.data.errors)
      )
  }

  export const fetchProducts = (page, countPerPage)=>{
    const url = `/products/find_all_products/${page}/${countPerPage}`;
    return function (dispatch){
      dispatch(fetchProductsInit());
      return axiosInstance.get(url)
        .then(res => res.data)
        .then(brands=>{
          dispatch(fetchProductsSuccess(brands));
          return brands;//TODO this has to be removed cause service should come from reducer
        })
        .catch(({response}) => {
          dispatch(fetchProductsFail(response.data.errors));
        })
    }
  }

  const fetchProductsSuccess = (products) =>{
    return {
      type: FETCH_PRODUCTS_SUCCESS,
      products: products
    }
  }
  const fetchProductsInit =() =>{//used to reset the data
    return {
      type: FETCH_PRODUCTS_INIT 
    }
  }
  const fetchProductsFail =(errors) =>{
    return {
      type:FETCH_PRODUCTS_FAIL,
      errors
    }
  }

  export const fetchProduct = (itemId)=>{
    console.log('itemId',itemId)
    return function (dispatch){
      dispatch(fetchProductInit());
      return axiosInstance.get(`/products/product_details/${itemId}`)
        .then(res => res.data[0])
        .then(product=>{
          dispatch(fetchProductSuccess(product));
          return product
        })
        .catch(({response}) => {
            dispatch(fetchProductFail(response.data.errors));
          })
    }
  }
  
  const fetchProductInit=()=>{
    return {
      type: FETCH_PRODUCT_INIT,
      
    }
  }
  const fetchProductSuccess = (product) =>{
    return {
      type: FETCH_PRODUCT_SUCCESS,
      product: product
    }
  }
  const fetchProductFail =(errors) =>{
    return {
      type:FETCH_PRODUCT_FAIL,
      errors
    }
  }

  export const updateProduct = (productData) => dispatch => {
    console.log('productData',productData)
    return axiosInstance.post(`/products/product_edit/${productData.id}`, productData)
      .then(res => res.data)
      .catch(({response}) => dispatch(updateProductFail(response.data.errors)))
  }
  export const updateProductFail = (errors) => {
    return {
      type: UPDATE_PRODUCT_FAIL,
      errors
    }
  }
  
  export const updateProductSuccess = (updatedProduct) => {
    return {
      type: UPDATE_PRODUCT_SUCCESS,
      product: updatedProduct
    }
  }
  

  export const deleteProduct = (id) => dispatch => {
    
    return axiosInstance.delete(`/products/product_delete/${id}`)
      .then(res => res.data)
      .catch(({response}) => console.log(response))//dispatch(deleteProductFail(response.data.errors)))
  }
  export const deleteProductFail = (errors) => {
    return {
      type: DELETE_PRODUCT_FAIL,
      errors
    }
  }
  
  export const deleteProductSuccess = (id) => {
    return {
      type: DELETE_PRODUCT_SUCCESS,
      products:id
    }
  }



//----------------------------Category-------------------------------------------------
export const createCategory = (categoryData) =>{
  return axiosInstance.post('/products/category_add', categoryData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}

export const fetchCategories = (page, countPerPage)=>{
  const url = `/products/find_all_categories/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchCategoriesInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(categories=>{
        dispatch(fetchCategoriesSuccess(categories));
        return categories;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchCategoriesFail(response.data.errors));
      })
  }
}

const fetchCategoriesSuccess = (categories) =>{
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    categories: categories
  }
}
const fetchCategoriesInit =() =>{//used to reset the data
  return {
    type: FETCH_CATEGORIES_INIT 
  }
}
const fetchCategoriesFail =(errors) =>{
  return {
    type:FETCH_CATEGORIES_FAIL,
    errors
  }
}

export const fetchCategory = (itemId)=>{
  console.log('itemId',itemId)
  return function (dispatch){
    dispatch(fetchCategoryInit());
    return axiosInstance.get(`/products/category_details/${itemId}`)
      .then(res => res.data[0])
      .then(category=>{
        dispatch(fetchCategorySuccess(category));
        return category
      })
      .catch(({response}) => {
          //dispatch(fetchCategoryFail(response.data.errors));
          console.log("response::", response)
        })
  }
}

const fetchCategoryInit=()=>{
  return {
    type: FETCH_CATEGORY_INIT,
    
  }
}
const fetchCategorySuccess = (category) =>{
  return {
    type: FETCH_CATEGORY_SUCCESS,
    category: category
  }
}
const fetchCategoryFail =(errors) =>{
  return {
    type:FETCH_CATEGORY_FAIL,
    errors
  }
}

export const updateCategory = (categoryData) => dispatch => {
  console.log('categoryData',categoryData)
  return axiosInstance.post(`/products/category_edit/${categoryData.id}`, categoryData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateCategoryFail(response.data.errors)))
}
export const updateCategoryFail = (errors) => {
  return {
    type: UPDATE_CATEGORY_FAIL,
    errors
  }
}

export const updateCategorySuccess = (updatedCategory) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    category: updatedCategory
  }
}


export const deleteCategory = (id) => dispatch => {
  
  return axiosInstance.delete(`/products/category_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => {
      dispatch(deleteCategoryFail(response.data.errors))
    })//console.log(response.data.errors[0].detail))//
    
}
export const deleteCategoryFail = (errors) => {
  return {
    type: DELETE_CATEGORY_FAIL,
    errors
  }
}

export const deleteCategorySuccess = (id) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    categories:id
  }
}




//----------------------------Subcategory-------------------------------------------------
export const createSubcategory = (subcategoryData) =>{
  return axiosInstance.post('/products/subcategory_add', subcategoryData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}

export const fetchSubcategories = (page, countPerPage)=>{
  const url = `/products/find_all_subcategories/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchSubcategoriesInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(subcategories=>{
        dispatch(fetchSubcategoriesSuccess(subcategories));
        return subcategories;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchSubcategoriesFail(response.data.errors));
      })
  }
}

const fetchSubcategoriesSuccess = (subcategories) =>{
  return {
    type: FETCH_SUBCATEGORIES_SUCCESS,
    subcategories: subcategories
  }
}
const fetchSubcategoriesInit =() =>{//used to reset the data
  return {
    type: FETCH_SUBCATEGORIES_INIT 
  }
}
const fetchSubcategoriesFail =(errors) =>{
  return {
    type:FETCH_SUBCATEGORIES_FAIL,
    errors
  }
}

export const fetchSubcategory = (subcategoryId)=>{
  console.log('subcategoryId',subcategoryId)
  return function (dispatch){
    dispatch(fetchSubcategoryInit());
    return axiosInstance.get(`/products/subcategory_details/${subcategoryId}`)
      .then(res => res.data[0])
      .then(subcategory=>{
        dispatch(fetchSubcategorySuccess(subcategory));
        return subcategory
      })
      .catch(({response}) => {
          dispatch(fetchSubcategoryFail(response.data.errors));
        })
  }
}

const fetchSubcategoryInit=()=>{
  return {
    type: FETCH_SUBCATEGORY_INIT,
    
  }
}
const fetchSubcategorySuccess = (subcategory) =>{
  return {
    type: FETCH_SUBCATEGORY_SUCCESS,
    subcategory: subcategory
  }
}
const fetchSubcategoryFail =(errors) =>{
  return {
    type:FETCH_SUBCATEGORY_FAIL,
    errors
  }
}

export const updateSubcategory = (subcategoryData) => dispatch => {
  console.log('subcategoryData',subcategoryData)
  return axiosInstance.post(`/products/subcategory_edit/${subcategoryData.id}`, subcategoryData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateSubcategoryFail(response.data.errors)))
}
export const updateSubcategoryFail = (errors) => {
  return {
    type: UPDATE_SUBCATEGORY_FAIL,
    errors
  }
}

export const updateSubcategorySuccess = (updatedSubcategory) => {
  return {
    type: UPDATE_SUBCATEGORY_SUCCESS,
    subcategory: updatedSubcategory
  }
}


export const deleteSubcategory = (id) => dispatch => {
  
  return axiosInstance.delete(`/products/subcategory_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteSubcategoryFail(response.data.errors)))
}
export const deleteSubcategoryFail = (errors) => {
  return {
    type: DELETE_SUBCATEGORY_FAIL,
    errors
  }
}

export const deleteSubcategorySuccess = (id) => {
  return {
    type: DELETE_SUBCATEGORY_SUCCESS,
    subcategories:id
  }
}

//--------------------------------------------------------------------------------------
 