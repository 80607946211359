import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
// import {BwmFileUpload} from '../../shared/form/BwmFileUpload';BwmServiceImageUpload
import {BwmServiceAreaImageUpload} from '../../shared/form/BwmServiceAreaImageUpload';


import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,text,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

const ServiceAreaCreateForm = props => {
    const { handleSubmit, pristine, submitting, submitCb, valid, options ,errors } = props
    return (
      <form onSubmit={handleSubmit(submitCb)}>
        <Field
            name="name"
            type="text"
            label='Name'
            className='form-control'
            component={BwmInput}
            validate={[required,minLength2,maxLength64]}
        />
        <Field
            name="short_description"
            type="text"
            label='Short Description'
            className='form-control'
            component={BwmInput}
            validate={[required,minLength4,maxLength512]}
        />
        <Field
            name="description"
            type="text"
            label='Description'
            rows='6'
            className='form-control'
            component={BwmTextArea}
            validate={[required,minLength4]}
        />
        
        <Field
            name="active"
            options={options}
            label='Active'
            className='form-control'
            component={BwmSelect}
            validate={[required]}
        />
        <Field
          name="image_link"
          type="file"
          label='Image'
          component={BwmServiceAreaImageUpload}
        />
        
        <button className='btn btn-success btn-form' type="submit" disabled={!valid || pristine || submitting}>
          Create Service Area
        </button>
        <BwmResError errors={errors} />
      </form>
    )
  }
  
  export default reduxForm({
    form: 'serviceAreaCreateForm',
      //initialValues: { active: 'True'}
  })(ServiceAreaCreateForm)