import React from 'react';
import {Link} from 'react-router-dom';
import {ServiceItemArea} from './ServiceItemArea';
//import { serviceType} from '../../../helpers';

export function ServiceItemCard(props){
 // const categories = props.serviceItem;
  let data = props.categories;
  let groupBy = "area_name";
  let categories={};
  
  for (var i = 0; i < data.length; i++) {
      if (!categories[data[i][groupBy]])
          categories[data[i][groupBy]] = [];
      categories[data[i][groupBy]].push(data[i]);
  };
  console.log('categories:',categories)
  
  return Object.keys(categories).map((category, i) => {
   // console.log('categories[obj].item_name: ',categories[i])
    // console.log('categories[obj]::',category)
    return (
      
     <div>
        <div className="row">
          <div style={{margin:'0 0 20px 15px'}}>{category}</div>
        </div>
        {<ServiceItemArea  key={i} categories = {categories[category]}/>}
        </div>
     
    )
})
}



// import React from 'react';
// import {Link} from 'react-router-dom';
// //import { serviceType} from '../../../helpers';

// export function ServiceItemCard(props){
//   const serviceItem = props.serviceItem;
//   console.log('serviceItem:::',serviceItem)
  
//   return(
//           <div className={props.colNum}>
//             {/* `/services/${serviceItem.service_id}` */}
//             {/* <Link className='service-detail-link' to={}> */}
//                {serviceItem &&
//                 <div className=''>
//                   <span className=''>{serviceItem.item_name}</span><span className=''>{serviceItem.package_1}</span> <span className=''>{serviceItem.package_2}</span> <span className=''>{serviceItem.package_4}</span><span className=''>{serviceItem.package_6}</span>
//                   {/* <div className="container"> */}
                    
//                   </div>              
//                 // </div>
//             }
//           </div>
//   )
// }





// <table>
                  
//                   <thead>
//                     <tr className="pricing-top-heading">
//                       <th className="free">FREE</th>
//                       <th className="basic">BASIC</th>
//                       <th className="pro">PRO</th>
//                     </tr>
//                     <tr className="pricing-bottom-heading small">
//                       <th className="free"><strong>Ideal for:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras porttitor pulvinar diam id interdum.</th>
//                       <th className="basic"><strong>Ideal for:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras porttitor pulvinar diam id interdum.</th>
//                       <th className="pro"><strong>Ideal for:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras porttitor pulvinar diam id interdum.</th>
//                     </tr>
//                   </thead>
               
//                 <tbody>
                
//                   <tr>
//                     <td className="collapsible-section-header" colSpan="3">
//                       <a data-toggle="collapse" href="#collapse_section_one">Section 1</a>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="no-padding" colSpan="3">
//                       <div id="collapse_section_one" className="collapse in">
//                         <table style={{width:'100%'}}>
//                         <tbody>
//                           <tr>
//                             <td>Free 1</td>
//                             <td>Basic 1</td>
//                             <td>Pro 1</td>
//                           </tr>
//                           <tr>
//                             <td>Free 2</td>
//                             <td>Basic 2</td>
//                             <td>Pro 2</td>
//                           </tr>
//                           <tr>
//                             <td>Free 3</td>
//                             <td>Basic 3</td>
//                             <td>Pro 3</td>
//                           </tr>
//                           </tbody>
//                         </table>
                        
//                       </div>
//                     </td>
//                   </tr>

                  
//                   <tr>
//                     <td className="collapsible-section-header" colSpan="3">
//                       <a data-toggle="collapse" href="#collapse_section_two">Section 2</a>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td  className="no-padding" colSpan="3">
//                       <div id="collapse_section_two" className="collapse in">
//                         <table style={{width:'100%'}}>
//                           <tbody>
//                           <tr>
//                             <td>Free 4</td>
//                             <td>Basic 4</td>
//                             <td>Pro 4</td>
//                           </tr>
//                           <tr>
//                             <td>Free 5</td>
//                             <td>Basic 5</td>
//                             <td>Pro 5</td>
//                           </tr>
//                           <tr>
//                             <td>Free 6</td>
//                             <td>Basic 6</td>
//                             <td>Pro 6</td>
//                           </tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </td>
//                   </tr>

                
//                   <tr>
//                     <td className="collapsible-section-header" colSpan="3">
//                       <a data-toggle="collapse" href="#collapse_section_three">Section 3</a>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="no-padding" colSpan="3">
//                       <div id="collapse_section_three" className="collapse in">
//                         <table style={{width:'100%'}}>
//                           <tbody>
//                           <tr>
//                             <td>Free 7</td>
//                             <td>Basic 7</td>
//                             <td>Pro 7</td>
//                           </tr>
//                           <tr>
//                             <td>Free 8</td>
//                             <td>Basic 8</td>
//                             <td>Pro 8</td>
//                           </tr>
//                           <tr>
//                             <td>Free 9</td>
//                             <td>Basic 9</td>
//                             <td>Pro 9</td>
//                           </tr>
//                           </tbody>
//                         </table>
//                       </div>
//                     </td>
//                   </tr>

//                 </tbody>
              // </table>