import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {UserGuard} from '../../shared/auth/UserGuard';
import * as actions from '../../../actions';
import RegisterForm from '../../register/RegisterForm';
import Sidebar from '../../shared/Sidebar';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
class CreateAccount extends Component{
    constructor(){
        super();
        this.state ={
          response: {},
          isAllowed: false,
          isFetching: true,
          errors: [],
          errorsDetail:[],
          redirect: false
        }
        
        this.registerUser = this.registerUser.bind(this);
        this.resetAccountErrors = this.resetAccountErrors.bind(this);
        this.verifyAccountOwner = this.verifyAccountOwner.bind(this);
    }
    
    componentWillMount(){
       
        this.props.dispatch(actions.fetchAccountDetails())
          .then((account)=>{
            account
          });
        this.props.dispatch(actions.fetchAccount())
          .then((account)=>{
            account
          });
    }

    componentDidMount(){
       this.verifyAccountOwner();  
      
    }
    registerUser(userData){
      actions.register(userData)
      .then(registered =>{
            this.setState({redirect: true}),
            <Redirect to={{pathname:'/users'}}/>
            toast.success("Success");
        },
          errors =>this.setState({errors})
      );
    }
    resetAccountErrors(){
      this.props.dispatch(actions.resetAccountErrors());
    }

    verifyAccountOwner(){
      const {userId} = this.props.auth;
      this.setState({isFetching: true});
        return actions.verifyAccountOwner(userId)
            .then(()=>{
            this.setState({isAllowed: true, isFetching: false})
            },
            ()=>{
            this.setState({isAllowed: false, isFetching: false})
            })
    }

    render(){
      
        const {isAllowed, isFetching, errors, errorsDetail,redirect} = this.state;
        const {account} = this.props;
        if(redirect)
        return <Redirect to ={{pathname:'/users', state:{successRegister: true}}}/>
        if(account.user_role==='admin'){
        return(
                
          <UserGuard isAllowed={isAllowed} isFetching={isFetching}> 
          <div className="d-flex" id="wrapper">
          <Sidebar/>
          <div className="container">
            <div className="accountDetail">
              <section id='accountUserForm'>
                    <div className="row">
                      <div className="col-12">
                        <h2>Create New Account</h2>
                        <RegisterForm submitCb={this.registerUser} errors={errors}/>    
                      </div>
                    </div>
              </section>
              
            </div>
          </div>
          </div>
          </UserGuard>  
        )
        }else 
        return(
            <div>
              <h1>Loading...</h1>
            </div>
        )    
      }
    
}      

function mapStateToProps(state){
    return {
        auth: state.auth,
        account: state.account.data,
    }
}
export default connect(mapStateToProps)(CreateAccount);


