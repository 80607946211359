import { 
         FETCH_SERVICE_CATEGORY_BY_ID_INIT,
         FETCH_SERVICE_CATEGORY_BY_ID_SUCCESS,
         FETCH_SERVICE_CATEGORY_BY_ID_FAIL,
         UPDATE_SERVICE_CATEGORY_BY_ID_SUCCESS,
         UPDATE_SERVICE_CATEGORY_BY_ID_FAIL,
         RESET_SERVICE_CATEGORY_BY_ID_ERRORS,
         FETCH_SERVICE_CATEGORIES_INIT,
         FETCH_SERVICE_CATEGORIES_SUCCESS,
         FETCH_SERVICE_CATEGORIES_FAIL,
         FETCH_SERVICE_CATEGORY_INIT,
         FETCH_SERVICE_CATEGORY_SUCCESS,
         FETCH_SERVICE_CATEGORY_FAIL,
         DELETE_SERVICE_CATEGORY_FAIL,
         DELETE_SERVICE_CATEGORY_SUCCESS
        } from "../actions/types";

const INITIAL_STATE = {
    serviceCategories:{
        data:[],
        errors:[]
    },
    serviceCategory:{
        data:{}
    }   
}

export const serviceCategoryReducer = (state=INITIAL_STATE.serviceCategories, action) =>{
    switch(action.type){
        case FETCH_SERVICE_CATEGORIES_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_SERVICE_CATEGORIES_SUCCESS:
            return {...state, data: action.serviceCategories }
        case FETCH_SERVICE_CATEGORIES_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        case DELETE_SERVICE_CATEGORY_SUCCESS:
            return {...state, 
            // data: action.product 
            data:state.data.filter(x => x.id !== action.serviceCategories)
            }
        case DELETE_SERVICE_CATEGORY_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        default:
            return state;
    }
}

export const selectedServiceCategoryReducer = (state=INITIAL_STATE.serviceCategory, action) =>{
    switch(action.type){
        case FETCH_SERVICE_CATEGORY_BY_ID_INIT:
            return {...state, data: {} }
        case FETCH_SERVICE_CATEGORY_BY_ID_SUCCESS:
            return {...state, data: action.serviceCategory }
        case FETCH_SERVICE_CATEGORY_BY_ID_FAIL:
            return {...state, errors: action.errors};

        case FETCH_SERVICE_CATEGORY_INIT:
            return {...state, data: {} }     
        case FETCH_SERVICE_CATEGORY_SUCCESS:
            return {...state, data: action.serviceCategory }
        case FETCH_SERVICE_CATEGORY_FAIL:
            return {...state, errors: action.errors};

        case UPDATE_SERVICE_CATEGORY_BY_ID_SUCCESS:
            return {...state, data: action.serviceCategory};
        case UPDATE_SERVICE_CATEGORY_BY_ID_FAIL:
            return {...state, errors: action.errors};
        case RESET_SERVICE_CATEGORY_BY_ID_ERRORS:
            return {...state, errors: []};
        default:
            return state;
    }
}