import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
// import {BwmFileUpload} from '../../shared/form/BwmFileUpload';BwmServiceImageUpload
import {BwmServiceItemImageUpload} from '../../shared/form/BwmServiceItemImageUpload';


import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,numeric, text,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

const ServiceCreateForm = props => {
    const { handleSubmit, pristine, submitting, submitCb, valid, options, services, serviceCategories, serviceAreas, errors } = props
    
//  console.log('services',services)

    return (
      <form onSubmit={handleSubmit(submitCb)}>
        <Field
            name="name"
            type="text"
            label='Name'
            className='form-control'
            component={BwmInput}
            validate={[required,minLength2,maxLength64]}
        />
        <Field
            name="short_description"
            type="text"
            label='Short Description'
            className='form-control'
            component={BwmInput}
            validate={[required,minLength4,maxLength512]}
        />
        <Field
            name="description"
            type="text"
            label='Description'
            rows='6'
            className='form-control'
            component={BwmTextArea}
            validate={[required,minLength4]}
        />
        <div className="row">
                  <div className="col-6">
                  <Field
                      name="price_package_1"
                      type="number"
                      label='Package 1'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="price_package_2"
                      type="number"
                      label='Package 2'
                      className='form-control'
                      component={BwmInput}
                      validate={[ numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="price_package_4"
                      type="number"
                      label='Package 4'
                      className='form-control'
                      component={BwmInput}
                      validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="price_package_6"
                      type="number"
                      label='Package 6'
                      className='form-control'
                      component={BwmInput}
                      validate={[ numeric]}
                  />
                  </div>
                </div>
                
                 <Field
                    options={options}
                    name="active"
                    type="text"
                    label='Active'
                    className='form-control'
                    component={BwmSelect}
                    validate={[required]}
                />
                <Field
                    options={serviceCategories}
                    name="service_category_id"
                    type="text"
                    label='Service Category'
                    className='form-control'
                    component={BwmSelect}
                    validate={[required]}
                />
                <Field
                    options={serviceAreas}
                    name="service_area_id"
                    type="text"
                    label='Service Area'
                    className='form-control'
                    component={BwmSelect}
                    validate={[required]}
                />
                <Field
                    options={services}
                    name="service_id"
                    type="text"
                    label='Service'
                    className='form-control'
                    component={BwmSelect}
                    validate={[required]}
                />

   
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmServiceItemImageUpload}
                />
          
                
        <button className='btn btn-success btn-form' type="submit" disabled={pristine || submitting}>
          Create Item
        </button>
        <BwmResError errors={errors} />
      </form>
    )
  }
  
  export default reduxForm({
    form: 'serviceCreateForm',
      //initialValues: { active: 'True'}
  })(ServiceCreateForm)