import React from 'react';

export function BwmResError(props) {
  const errors = props.errors;
  // console.log('ERROR::',JSON.stringify(errors))
  
  return (
     errors.length > 0 &&
      <div className='alert alert-danger bwm-res-errors'>
        {errors.map((error, index) => <p key={index}> {error.detail} </p>)}
        
      </div>
  )
}