import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import ServiceCategoryUpdateModal from "../../shared/Modal/ServiceCategoryUpdateModal";
import ServiceCategoryDeleteModal from "../../shared/Modal/ServiceCategoryDeleteModal";
import * as actions from '../../../actions';
import {Link} from 'react-router-dom';
export const ServiceCategory =({servicesData}) =>{
  const dispatch = useDispatch();

  const [serviceCategory, setServiceCategory] = useState(servicesData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState({});
  

  const columns = [
    {
      name: 'Service Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/service-category/'+row.image_link} />,
    },
    {
      name: 'Name',
      selector: 'name',
    },
    {
        name: 'Active',
        // selector: 'active',
        cell: row => row.active == true?'Yes': 'No',
      },
      
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-service-category-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-service-category-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchCategoryById(id))
    .then((res)=>{
      setSelectedServiceCategory({ selectedServiceCategory: res});
    })
    .catch()    
  }
  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteProduct(id))
    dispatch(actions.fetchServiceCategory(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedServiceCategory({ selectedServiceCategory: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = serviceCategory.findIndex((e)=> e.id === service.id);
    var newStateArray = serviceCategory.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(serviceCategory !== null || serviceCategory !== undefined)
      getServiceList();
    else return null; 
  }

  const getServiceList = () => {  
    dispatch(actions.fetchServicesCategory(page, countPerPage))
    .then(res => {
      setServiceCategory(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setServiceCategory({});
    });
  }
  
  useEffect(() => {
    getServiceList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/services/create-service-category"><button class="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Service Category</button></Link>
      <div className="row px-4">  
        {serviceCategory && <ServiceCategoryUpdateModal record={selectedServiceCategory} action={renderRow}/>}
        {serviceCategory && <ServiceCategoryDeleteModal record={selectedServiceCategory} action={renderRow}/>}
        <ToastContainer/>
        {serviceCategory && 
          <DataTable
            title="Services Category"
            columns={columns}
            data={serviceCategory}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
          />
        }
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  servicesData: state.services.data
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    fetchCategoryById:(id)=>dispatch(fetchCategoryById(id))
  }
}
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  mapDispatchToProps
)(ServiceCategory);
// export default connect(mapStateToProps)(Demo1)