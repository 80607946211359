import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Sidebar from '../shared/Sidebar';
import {Link} from 'react-router-dom';
import {BwmResError} from '../shared/form/BwmResError';
class Dashboard extends Component{
    constructor(props) {
        super(props);
     
        this.state = {
          errors: this.props.errors || []
        };
      }
    componentWillMount(){
        return this.props.dispatch(actions.fetchServices());
      }

    render(){ 
        const {isAuth, errors} = this.props.auth;
        return(
            <div>
            
            <div className="d-flex" id="wrapper">
                <Sidebar/>
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-2" id="menu-toggle"></button>
                        <BwmResError errors={errors} />
                        <h1 className="text-primary">Dashboard</h1>
                        <div className="row px-2">
                            <div className="col-sm-3 p-sm-2">
                                <div className="card bg-primary text-white shadow-lg">
                                    <div className="card-body">
                                        <h5 className="card-title">Users</h5>
                                        <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                        <Link to="/users" className="btn btn-light">Go to Users</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-sm-2">
                                <div className="card bg-secondary text-white shadow-lg">
                                    <div className="card-body">
                                        <h5 className="card-title">Special title treatment</h5>
                                        <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                        <a href="#" className="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-sm-2">
                                <div className="card bg-info text-white shadow-lg">
                                    <div className="card-body">
                                        <h5 className="card-title">Special title treatment</h5>
                                        <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                        <a href="#" className="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 p-sm-2">
                                <div className="card bg-dark text-white shadow-lg">
                                    <div className="card-body">
                                        <h5 className="card-title">Special title treatment</h5>
                                        <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                        <a href="#" className="btn btn-light">Go somewhere</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state){
    return {
        auth: state.auth,
        services: state.services.data
    }
}

export default connect(mapStateToProps)(Dashboard)