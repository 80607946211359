import { 
    FETCH_EVENTS_INIT,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAIL, 
    DELETE_EVENTS_SUCCESS,
    DELETE_EVENTS_FAIL,
    
    FETCH_EVENT_INIT,
    FETCH_EVENT_SUCCESS,
    FETCH_EVENT_FAIL, 
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    RESET_EVENT_ERRORS,

} from './types';

import axiosService from '../../services/axios-service';
const axiosInstance = axiosService.getInstance();

export const createEvent = (eventData) =>{
    return axiosInstance.post('/events/event_add', eventData)
      .then(res=> res.data,
            err =>Promise.reject(err.response.data.errors)
      )
}

export const fetchEvents = (page, countPerPage)=>{
    const url = `/events/find_all_events/${page}/${countPerPage}`;
    return function (dispatch){
      dispatch(fetchEventsInit());
      return axiosInstance.get(url)
        .then(res => res.data)
        .then(events=>{
          dispatch(fetchEventsSuccess(events));
          return events;//TODO this has to be removed cause service should come from reducer
        })
        .catch(({response}) => {
          dispatch(fetchEventsFail(response.data.errors));
        })
    }
  }

const fetchEventsInit =() =>{//used to reset the data
    return {
      type: FETCH_EVENTS_INIT 
    }
}
  
  
const fetchEventsSuccess = (events) =>{
  return {
    type: FETCH_EVENTS_SUCCESS,
    events: events
  }
}

const fetchEventsFail =(errors) =>{
    return {
      type:FETCH_EVENTS_FAIL,
      errors
    }
}





export const fetchEvent = (itemId)=>{
    console.log('itemId',itemId)
    return function (dispatch){
      dispatch(fetchEventInit());
      return axiosInstance.get(`/events/event_details/${itemId}`)
        .then(res => res.data[0])
        .then(event=>{
          dispatch(fetchEventSuccess(event));
          return event
        })
        .catch(({response}) => {
            dispatch(fetchEventFail(response.data.errors));
          })
    }
  }
  
  const fetchEventInit=()=>{
    return {
      type: FETCH_EVENT_INIT,
      
    }
  }
  const fetchEventSuccess = (event) =>{
    return {
      type: FETCH_EVENT_SUCCESS,
      event: event
    }
  }
  const fetchEventFail =(errors) =>{
    return {
      type:FETCH_EVENT_FAIL,
      errors
    }
  }


  export const updateEvent = (eventData) => dispatch => {
    console.log('eventData',eventData)
    return axiosInstance.post(`/events/event_edit/${eventData.id}`, eventData)
      .then(res => res.data)
      .catch(({response}) => dispatch(updateEventFail(response.data.errors)))
  }
  export const updateEventFail = (errors) => {
    return {
      type: UPDATE_EVENT_FAIL,
      errors
    }
  }
  
  export const updateEventSuccess = (updatedEvent) => {
    return {
      type: UPDATE_EVENT_SUCCESS,
      event: updatedEvent
    }
  }
  
  
  export const deleteEvent = (id) => dispatch => {
    
    return axiosInstance.delete(`/events/event_delete/${id}`)
      .then(res => res.data)
      .then(event=>{
        dispatch(deleteEventSuccess(event));
        return event
      })
      .catch(({response}) => dispatch(deleteEventFail(response.data.errors)))
  }
  export const deleteEventFail = (errors) => {
    return {
      type: DELETE_EVENTS_FAIL,
      errors
    }
  }
  
  export const deleteEventSuccess = (id) => {
    return {
      type: DELETE_EVENTS_SUCCESS,
      events:id
    }
  }