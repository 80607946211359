import React from 'react';
import {Link} from 'react-router-dom';
import { serviceType} from '../../../helpers';

export function ServiceCard(props){
  const service = props.service;
  return(
          <div className={props.colNum}>
            <Link className='service-detail-link' to={`/services/find_all_service_items_by_category/${service.id}`}>
                <div className='card bwm-card'>
                      <img className='card-img' src={service.image_link} alt={service.name}></img>
                      <div className='card-block'>
                    
                        <h4 className='card-title'>{service.name}</h4>
                        
              
                      </div>
                </div>
            </Link>            
          </div>
  )
}