import React, { Component } from 'react';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import {withRouter,Redirect} from 'react-router-dom';

class ActivateAccount extends Component {
    
    constructor(){
        super();
        this.state ={
            errors : [],
            redirect: false,
            successActivate: false
        }
        this.activateAccount = this.activateAccount.bind(this);
       // this.redirectlogin = this.redirectlogin.bind(this);
    }
    // activateAccount(){
    //     return this.setState({
    //         redirect: true
    //     })
    // }
    activateAccount(){
        actions.activateAccount(this.props.match.params.activation_key)
            .then(
                activate =>this.setState({redirect: true, successActivate: true}),
                errors =>this.setState({successActivate: false,redirect: true,errors})
            );
    }
   
    render() {
        const {errors,redirect,successActivate} = this.state;
        
        if(redirect && successActivate)
            return <Redirect to ={{pathname:'/login', state:{successRegister:false,successActivate: true, errors}}}/>
        if(redirect && !successActivate) 
        return <Redirect to ={{pathname:'/login', state:{successRegister:false, successActivate: false,successActivateDanger: true, errors}}}/>
        return(
          <div>{this.activateAccount()}</div>
          
      )  
    }
}

function mapStateToProps(state){
    return{
      auth: state.auth
    }
  }
  
  export default withRouter(connect(mapStateToProps)(ActivateAccount));


