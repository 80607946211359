import React from 'react';
import ProductCreateForm from './ProductCreateForm';
import * as actions from '../../../actions/products/index';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from '../../shared/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import Back from "../../shared/navigation/Back";
class ProductCreate extends React.Component{
    constructor(){
        super();

        this.state = {
            errors:[],
            services:'',
            redirect: false
        }
        this.booleanValue = ['','true', 'false'];
        this.createProduct = this.createProduct.bind(this);
        this.categories = this.categories.bind(this);
        this.subcategories = this.subcategories.bind(this);
        this.brands = this.brands.bind(this);
     }

    componentWillMount(){
        this.props.dispatch(actions.fetchCategories(1,100));
        this.props.dispatch(actions.fetchSubcategories(1,100));
        this.props.dispatch(actions.fetchBrands(1,100));
    }

    categories(){
        if(this.props.categories === null || this.props.categories=="") 
            return null;
        let result = this.props.categories.map(a=>a.name)
        return result;
    }

    subcategories(){
        if(this.props.subcategories === null || this.props.subcategories=="") 
        return null;
        let result = this.props.subcategories.map(a=>a.name)
        return result;
    }

    brands(){
        if(this.props.brands === null || this.props.brands=="") 
            return null;
        let result = this.props.brands.map(a=>a.name)
        return result;
    }
    createProduct(serviceItemData){
        actions.createProduct(serviceItemData)
        .then(response => {
            if(response.status ==='success'){
                this.setState({errors:[]});
                this.setState({redirect: true}),
                //  <Redirect to={{pathname:'/services'}}/>
                toast.success("Success");}
        },
        (errors) => {
            console.log('error',errors)
            this.setState({errors})
            toast.error('Status code: '+errors.status+' '+errors.data);
        }
        );
    }
    render(){
        // console.log('rrres',this.props.services)
        if(this.state.redirect){
            return <Redirect to={{pathname:'/products'}}/>
        }
        return(
            <div className="d-flex" id="wrapper">
          <Sidebar/>
            <div className="container">
            <section id='newRental'>
                <div className='bwm-form'>
                    <div className='row px-4'>
                    <div className='col-md-12'>
                    <Back />
                        <h1 className='page-title'>Create Product</h1>
                        <ProductCreateForm submitCb={this.createProduct} 
                                            options={this.booleanValue}
                                            categories = {this.categories()}
                                            subcategories = {this.subcategories()}
                                            brands={this.brands()}
                                            errors={this.state.errors}/>
                    </div>
                    {/* <div className='col-md-6 ml-auto'>
                        <div className='image-container'>
                        <h2 className='catchphrase'>Hundreds of awesome places in reach of few clicks.</h2>
                        <img src={process.env.PUBLIC_URL + '/img/create-service.jpg'} alt=''/>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>
            </div></div>
        )
    }
}


function mapStateToProps(state){
    return {
        auth: state.auth,
        categories: state.categories.data,
        subcategories: state.subcategories.data,
        brands: state.brands.data
    }
}
export default connect(mapStateToProps)(ProductCreate);


