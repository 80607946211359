import {    FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT,
            FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_SUCCESS,
            FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_FAIL,

            RESET_SERVICE_ITEMS_BY_CATEGORY_ID_ERRORS,
            FETCH_SERVICE_ITEMS_INIT,
            FETCH_SERVICE_ITEMS_SUCCESS,
            FETCH_SERVICE_ITEMS_FAIL,

            FETCH_SERVICE_ITEM_INIT,
            FETCH_SERVICE_ITEM_SUCCESS,
            FETCH_SERVICE_ITEM_FAIL,
            UPDATE_SERVICE_ITEM_SUCCESS,
            UPDATE_SERVICE_ITEM_FAIL,
            RESET_SERVICE_ITEM_ERRORS,

            DELETE_SERVICE_ITEM_FAIL,
            DELETE_SERVICE_ITEM_SUCCESS
        } from "../actions/types";

const INITIAL_STATE = {
    serviceItems:{
        data:[],
        errors:[]
    },
    serviceItem:{
        data:{}
    },
    serviceItemsByCategory:{
        data:{}
    }    
}
export const serviceItemsReducer = (state=INITIAL_STATE.serviceItems, action) =>{
    switch(action.type){
        case FETCH_SERVICE_ITEMS_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_SERVICE_ITEMS_SUCCESS:
            return {...state, data: action.serviceItems }
        case FETCH_SERVICE_ITEMS_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        
        case DELETE_SERVICE_ITEM_SUCCESS:
            return {...state, 
                // data: action.product 
                data:state.data.filter(x => x.id !== action.serviceItems)
            }
        case DELETE_SERVICE_ITEM_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})    
        default:
            return state;
    }
}
export const serviceItemsByCategoryReducer = (state=INITIAL_STATE.serviceItems, action) =>{
    switch(action.type){
        case FETCH_SERVICE_ITEMS_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_SERVICE_ITEMS_SUCCESS:
            return {...state, data: action.serviceItems }
        case FETCH_SERVICE_ITEMS_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        default:
            return state;
    }
}

export const selectedServiceItemsByCategoryReducer = (state=INITIAL_STATE.serviceItemsByCategory, action) =>{
    switch(action.type){
        case FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT:
            return {...state, data: {} }
        case FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_SUCCESS:
            return {...state, data: action.serviceItemsByCategory }
        // case UPDATE_SERVICE_SUCCESS:
        //     return {...state, data: action.service};
        // case UPDATE_SERVICE_FAIL:
         //   return {...state, errors: action.errors};
        case FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_FAIL:
            return {...state, errors: []};
        default:
            return state;
    }
}

export const selectedServiceItemReducer = (state=INITIAL_STATE.serviceItem, action) =>{
    switch(action.type){
        case FETCH_SERVICE_ITEM_INIT:
            return {...state, data: {} }
        case FETCH_SERVICE_ITEM_SUCCESS:
            return {...state, data: action.serviceItem }
        case FETCH_SERVICE_ITEM_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_SERVICE_ITEM_SUCCESS:
            return {...state, data: action.serviceItem};
        case UPDATE_SERVICE_ITEM_FAIL:
            return {...state, errors: action.errors};
        case RESET_SERVICE_ITEM_ERRORS:
            return {...state, errors: []};
        default:
            return state;
    }
}