import React, { Component } from 'react';
import ResetPasswordForm from './ResetPasswordForm';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Redirect} from 'react-router-dom';

class ResetPassword extends Component {
    
    constructor(props){
        super(props);
        this.state ={
            errors : [],
            redirect: false,
            successReset: false
        }
        this.resetPassword = this.resetPassword.bind(this);
        this.redirectResetPassword = this.redirectResetPassword.bind(this);
    }
    resetPassword(userData){
        actions.resetPassword(userData, this.props.match.params.activation_key)
        .then(
            forgot =>this.setState({redirect: true, successReset: true}),
            errors =>this.setState({errors})
        );
    }
    redirectResetPassword(){
        this.props.history.push('/reset-password');
      }
   
    render() {
        const {isAuth} = this.props.auth;
        //const {successActivationKey} = this.props.location.state || false;
    
        if(isAuth){
          return <Redirect to={{pathname:'/'}}/>
        }

        const {errors} = this.state;
        // if(redirect)
        //     return <Redirect to ={'/forgot-password'}/>
            // react-router provides this.props.location so state will be inside location in Login.js
        return (
            <div className="container">
            <section id='register'>
                <div className='bwm-form'>
                    <div className='row'>
                    <div className='col-md-5'>
                        <h1>Forgot Password</h1>
                        {
                        this.state.successReset && this.redirectResetPassword &&
                            <div className='alert alert-success'>
                            <p> Password has been shuccessfully changed</p>
                            </div>
                        } 
                        {
                            !this.state.successReset && <ResetPasswordForm submitCb={this.resetPassword} errors={errors}/>
                        }
                        
                    </div>
                    <div className='col-md-6 ml-auto'>
                        <div className='image-container'>
                        <h2 className='catchphrase'>As Our Member You Have Access To The Most Awesome Services</h2>
                        <img src={process.env.PUBLIC_URL+ '/img/page/register.jpg'} alt=""/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            </div>
        )
    }
}

function mapStateToProps(state){
    return{
      auth: state.auth
    }
  }
  
  export default withRouter(connect(mapStateToProps)(ResetPassword));


