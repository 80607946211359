import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions/products/index';
import 'react-toastify/dist/ReactToastify.css';
import ProductDetailForm from '../../products/product-listing/ProductDetailForm';

class ProductUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['','true', 'false'];
        this.updateProduct = this.updateProduct.bind(this);
        //this.products = this.products.bind(this);
        this.categories = this.categories.bind(this);
        this.subcategories = this.subcategories.bind(this);
        this.brands = this.brands.bind(this);
    }
    componentWillMount(){
        this.props.dispatch(actions.fetchProducts(1,100));
        this.props.dispatch(actions.fetchCategories(1,100));
        this.props.dispatch(actions.fetchSubcategories(1,100));
        this.props.dispatch(actions.fetchBrands(1,100));
    }
    // products(){
    //     let result = this.props.products.map(a=>a.name);
    //     console.log('Products', this.props.products)
    //     return result;
    // }
    categories(){
        if(this.props.categories === null || this.props.categories=="") 
            return null;
        let result = this.props.categories.map(a=>a.name)
            return result;
    }

    subcategories(){
        if(this.props.subcategories === null || this.props.subcategories=="") 
            return null;
        let result = this.props.subcategories.map(a=>a.name)
            return result;
    }

    brands(){
        if(this.props.brands === null || this.props.brands=="") 
            return null;
        let result = this.props.brands.map(a=>a.name)
        return result;
    }

    updateProduct(ProductData){
        console.log('ProductData', ProductData)
        const {product} = this.props;
        let mergeData = {...product, ...ProductData};
        this.props.dispatch(actions.updateProduct(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateProductSuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-product-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Product</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <ProductDetailForm  
                                    submitCb={this.updateProduct} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    // products = {this.products()}
                                    categories = {this.categories()}
                                    subcategories = {this.subcategories()}
                                    brands={this.brands()}
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    // console.log('state',state.serviceItem.data)
    // console.log('product.data',state.product.data)
    return{
    auth: state.auth,
    errors: state.errors,
    product: state.product.data,
    categories: state.categories.data,
    subcategories: state.subcategories.data,
    brands: state.brands.data,
    products: state.products.data,
     initialValues: {
        sku: state.product.data.sku,

        name: state.product.data.name,
        image_link: state.product.data.image_link,
        active: state.product.data.active,
        available: state.product.data.available,
        short_description: state.product.data.short_description,
        description: state.product.data.description,
        updated_at: state.product.data.updated_at,
        created_at: state.product.data.created_at,
        category_name: state.product.data.category_name,
        subcategory_name: state.product.data.subcategory_name,
        brand_name: state.product.data.brand_name,
        sorting_no: state.product.data.sorting_no,
         price: state.product.data.price,
         msrp: state.product.data.msrp,
         discount: state.product.data.discount,
         units_stock: state.product.data.units_stock,
        
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(ProductUpdateModal));
