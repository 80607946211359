import React from 'react';
import ServiceAreaCreateForm from './ServiceAreaCreateForm';
import * as actions from '../../../actions';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from '../../shared/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
class ServiceAreaCreate extends React.Component{
    constructor(){
        super();
        this.state = {
            errors:[],
            services:'',
            redirect: false
        }
        this.booleanValue = ['','True', 'False'];
        this.createServiceArea = this.createServiceArea.bind(this);
        // this.services = this.services.bind(this);
    }

    // componentWillMount(){
    //     this.props.dispatch(actions.fetchServices(1,1000))
        
    // }

    // services(){
    //     let result = this.props.services.map(a=>a.name)
    //     return result
    // }
    createServiceArea(serviceAreaData){
        actions.createServiceArea(serviceAreaData)
        .then(service => {
                this.setState({redirect: true}),
            //  <Redirect to={{pathname:'/services'}}/>
            toast.success("Success");
        },
        (errors) => {
            this.setState({errors})
            toast.error('Status code: '+errors.status+' '+errors.data);
        });
    }
    render(){
        // console.log('rrres',this.props.services)
        if(this.state.redirect){
            return <Redirect to={{pathname:'/services'}}/>
        }
        return(
            <div className="d-flex" id="wrapper">
            <Sidebar/>
                <div className="container">
                    <section id='newRental'>
                        <div className='bwm-form'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h1 className='page-title'>Create Service Area</h1>
                                    <ServiceAreaCreateForm submitCb={this.createServiceArea} 
                                                        options={this.booleanValue}
                                                        errors={this.state.errors}/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state){
    return {
        auth: state.auth,
        services: state.services.data,
    }
}
export default connect(mapStateToProps)(ServiceAreaCreate);


