import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput} from '../shared/form/BwmInput';
import {BwmResError} from '../shared/form/BwmResError';


const ForgotPasswordForm = props => {
  const { handleSubmit, pristine, submitting, submitCb, valid, errors } = props
  return (
    <form onSubmit={handleSubmit(submitCb)}>

    
      <Field
        name="email"
        type="email"
        label="Email"
        className="form-control"
        component={BwmInput}
      />
        
      <Field
        name="emailConfirmation"
        type="email"
        label="Confirm Email"
        className="form-control"
        component={BwmInput}
      />    
      
    
 
      <button className="btn btn-success btn-bwm btn-form" type="submit" disabled={!valid || pristine || submitting}>
        Submit
      </button>
      <BwmResError errors = {errors} />
    </form>
  )
}

const validate = values => {
  const errors = {}

  // if (values.username && values.username.length < 4) {
  //   errors.username = 'Username min length is 4 characters!';
  // }

  if (!values.email) {
    errors.email = 'Please enter email!';
  }

  if (!values.emailConfirmation) {
    errors.emailConfirmation = 'Please enter email confirmation';
  }

  if (values.email !== values.emailConfirmation) {
    errors.password = 'Email Doesnot match';
  }
  return errors
}

export default reduxForm({
  form: 'forgotPasswordForm', // a unique identifier for this form
  validate
})(ForgotPasswordForm)