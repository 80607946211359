import axios from 'axios';
import authService from './auth-service';

class AxiosService {

  axiosInstance = {};

  constructor() {
    this.initInstance();
  }

  initInstance() {
    this.axiosInstance = axios.create({
      // baseURL: 'http://localhost:8080/api/v1/',
      baseURL: process.env.REACT_APP_API_VERSION_1,
      timeout: 10000
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = authService.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          //console.log('config.headers.Authorization',config.headers.Authorization)
        }
        return config;
      },
      (error)=>{
        return Promise.reject(error);
      }
    );

    return this.axiosInstance;
  }

  getInstance() {
    return this.axiosInstance || this.initInstance();
  }
}

export default new AxiosService();