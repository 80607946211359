import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import ServiceItemUpdateModal from "../../shared/Modal/ServiceItemUpdateModal";
import ServiceItemDeleteModal from "../../shared/Modal/ServiceItemDeleteModal";
import * as actions from '../../../actions';
import {Link} from 'react-router-dom';
export const ServiceItem =({servicesData}) =>{
  const dispatch = useDispatch();

  const [serviceItem, setServiceItem] = useState(servicesData);
  const [selectedUser, setSelectedUser] = useState({});
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [searchUsers, setSearchUsers] = useState({});
  const [countPerPage, setCountPerPage] = useState(10);
  const [selectedServiceItem, setSelectedServiceItem] = useState({});
  

  const columns = [
    {
      name: 'Service Image',
      cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/service-item/'+row.image_link} />,
    },
    {
      name: 'Treatment',
      selector: 'name',
    },
    {
      name: 'Service Area',
      selector: 'service_area_name',
    },
    {
      name: 'Category',
      selector: 'service_category_name',
    },
    
    {
      name: 'Service',
      selector: 'service_name',
    },
    {
        name: 'Active',
        // selector: 'active',
        cell: row => row.active == true?'Yes': 'No',
      },
      {
        name: 'Package 1',
        selector: 'price_package_1',
      },
      {
        name: 'Package 2',
        selector: 'price_package_2',
      },
      {
        name: 'Package 4',
        selector: 'price_package_4',
      },
      {
        name: 'Package 6',
        selector: 'price_package_6',
      },
      {
        name: 'Actions',
        button: true,
        cell: row =>  (
            <Fragment>
                <button
                    data-toggle="modal"
                    data-target="#update-service-item-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                </button>
                <button
                    data-toggle="modal"
                    data-target="#delete-service-item-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                </button>
            </Fragment>
        )
      },
  ];

  function editRecord(id) {
    dispatch(actions.fetchServiceItem(id))
    .then((res)=>{
      // console.log('res',res)
      setSelectedServiceItem({ selectedServiceItem: res});
    })
    .catch()    
  }
  function deleteRecord(id) {
    console.log('id::',id)
    // dispatch(actions.deleteProduct(id))
    dispatch(actions.fetchServiceItem(id))
    .then((res)=>{
       console.log('res',res)
       setSelectedServiceItem({ selectedServiceItem: res});
    })
    .catch()    
  }

  function renderRow(service) {
    const index = serviceItem.findIndex((e)=> e.id === service.id);
    var newStateArray = serviceItem.slice();
    if (index === -1) {
        newStateArray.push(service);
    } else {
        newStateArray[index] = service;
    }
    if(serviceItem !== null || serviceItem !== undefined)
      getServiceItemList();
    else return null; 
  }

  const getServiceItemList = () => {  
    dispatch(actions.fetchServiceItems(page, countPerPage))
    .then(res => {
      setServiceItem(res);
      setCountPerPage(10);
      setTotalRows(res[0].total_count);
    }).catch(err => {
      setServiceItem({});
    });
  }
  
  useEffect(() => {
    getServiceItemList();
  }, [page]);
  return (
    <div className="container-fluid">
      <Link to="/services/create-service-item"><button class="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Service Item</button></Link>
      <div className="row px-4">  
        {serviceItem && <ServiceItemUpdateModal record={selectedServiceItem} action={renderRow}/>}
        {serviceItem && <ServiceItemDeleteModal record={selectedServiceItem} action={renderRow}/>}
        <ToastContainer/>
        {serviceItem && 
          <DataTable
            title="Services Item"
            columns={columns}
            data={serviceItem}
            // conditionalRowStyles={conditionalRowStyles}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
                noRowsPerPage: true
            }}
            onChangePage={page => setPage(page)}
          />
        }
      </div>
    </div>
  )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    //  servicesData: state.services.data
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    fetchServiceItem:(id)=>dispatch(fetchServiceItem(id))
  }
}
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  mapDispatchToProps
)(ServiceItem);
// export default connect(mapStateToProps)(Demo1)