import { 
         FETCH_BRAND_INIT,
         FETCH_BRAND_SUCCESS,
         FETCH_BRAND_FAIL,
         UPDATE_BRAND_SUCCESS,
         UPDATE_BRAND_FAIL,
         RESET_BRAND_ERRORS,
         DELETE_BRAND_SUCCESS,
         FETCH_BRANDS_INIT,
         FETCH_BRANDS_SUCCESS,
         FETCH_BRANDS_FAIL
        } from "../actions/products/types";

const INITIAL_STATE = {
    brands:{
        data:[],
        errors:[]
    },
    brand:{
        data:{}
    }   
}

export const brandsReducer = (state=INITIAL_STATE.brands, action) =>{
    switch(action.type){
        case FETCH_BRANDS_INIT:
            return {...state, data:[],errors:[]}
        case FETCH_BRANDS_SUCCESS:
            return {...state, data: action.brands }
        case FETCH_BRANDS_FAIL:
            return Object.assign({}, ...state,{errors: action.errors, data:[]})
        case DELETE_BRAND_SUCCESS:
            return {...state, 
            data:state.data.filter(x => x.id !== action.brands)
        }
        default:
            return state;
    }
}

export const selectedBrandReducer = (state=INITIAL_STATE.brand, action) =>{
    switch(action.type){
        case FETCH_BRAND_INIT:
            return {...state, data: {} }
        case FETCH_BRAND_SUCCESS:
            return {...state, data: action.brand }
        case FETCH_BRAND_FAIL:
            return {...state, errors: action.errors};
        case UPDATE_BRAND_SUCCESS:
            return {...state, data: action.brand};
        case UPDATE_BRAND_FAIL:
            return {...state, errors: action.errors};
        case RESET_BRAND_ERRORS:
            return {...state, errors: []};
        default:
            return state;
    }
}