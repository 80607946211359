import React from 'react';
import { Field,FieldArray, reduxForm } from 'redux-form';
import {BwmInput} from '../../shared/form/BwmInput';
import {BwmSelect} from '../../shared/form/BwmSelect';
import {BwmFileUpload} from '../../shared/form/BwmFileUpload';
import {BwmCardUpload} from '../../shared/form/BwmCardUpload';

import {BwmResError} from '../../shared/form/BwmResError';
import {required,email,phone,phone_bd, text,minLength1, minLength2,minLength6, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';
var QRCode = require('qrcode.react');
let init = {}
const AccountUserForm = props => {
    const { handleSubmit, pristine,reset, submitting,options,isOwner, submitCb, valid ,errors,initialValues} = props;
    init = initialValues;
    // let owner = true;
// console.log('init', init)



// const renderCards = ({ fields, meta: { error, submitFailed } }) => (
//     <div>
//         <button className='btn btn-default btn-upload' style={{border:'1px solid black'}} type="button" onClick={() => fields.push({})} >
//           Add National ID
//         </button>
       
    
//       {fields.map((member, index) => (
//         <div key={index}>
//             <button className='btn btn-danger btn-upload'  style={{border:'1px solid black'}} type="button" onClick={() => fields.remove(index)}>
//                 Remove Card
//             </button>
//           <h4>ID Card #{index + 1}</h4>
//           {/* <Field
//             // name={`${member}.firstName`}
//             type="text"
//             component={BwmFileUpload}
//             label="First Name"
//           /> */}
//            <Field
//                     name={`${member}`}
//                     type="file"
//                     label='Image'
//                     className='form-control'
//                     component={BwmCardUpload}
//                 />
         
//          </div>
//       ))}
//    </div>
//   )
  

  const renderImages = ({ fields, meta: { error, submitFailed } }) => (
    <div>   <br/><br/><br/><br/> <br/><br/><br/><br/><br/><br/>
        <h2>National ID</h2>
        <Field
            name={`card`}
            type="file"
            label='Image'
            className='form-control'
            component={BwmCardUpload}
        />   
   </div>
  )

    return (
        
      <form onSubmit={handleSubmit(submitCb)}>

        <div className="row">
            <div className="col-md-6">
                <Field
                    name="email"
                    type="email"
                    label='Email'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, email]}
                />
                <Field
                    name="password"
                    type="password"
                    label='Password'
                    className='form-control'
                    component={BwmInput}
                    validate={[required, minLength6,maxLength512 ]}
                />
                {process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?
                    <Field
                        name="cell_phone"
                        type="text"
                        label='Cell Phone'
                        className='form-control'
                        component={BwmInput}
                        validate={[required, phone_bd]}
                    />:
                    <Field
                        name="cell_phone"
                        type="text"
                        label='Cell Phone'
                        className='form-control'
                        component={BwmInput}
                        validate={[required, phone]}
                    />
                }
                <Field
                    name="first_name"
                    type="text"
                    label='First Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required,minLength1, maxLength32]}
                />
                <Field
                    name="middle_name"
                    type="text"
                    label='Middle Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[minLength2,maxLength32,text]}
                />
                 <Field
                    name="last_name"
                    type="text"
                    label='Last Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required,minLength2,maxLength32,text]}
                />
                <div className="row">
                    <div className="col-6">
                        {isOwner&&<Field
                            options={options}
                            name="agent"
                            type="text"
                            label='Agent'
                            className='form-control'
                            component={BwmSelect}
                            values={init.agent}
                            // validate={[required]}
                        />}
                    </div>
                    <div className="col-6">
                        {isOwner&&<Field
                            
                            name="national_card_id"
                            type="text"
                            label='National ID'
                            className='form-control'
                            component={BwmInput}
                            values={init.national_card_id}
                            // validate={[required]}
                        />}
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-6">
                 {isOwner&&<Field
                    options={options}
                    name="blocked"
                    type="text"
                    label='Blocked'
                    className='form-control'
                    component={BwmSelect}
                    values={init.blocked}
                    // validate={[required]}
                />}
                </div>
                <div className="col-6">
                 {isOwner&&<Field
                    options={options}
                    name="active"
                    type="text"
                    label='Active'
                    className='form-control'
                    component={BwmSelect}
                    values={init.active}
                    // validate={[required]}
                />}
                </div></div>
                
                 
            </div>
            {/* <img src={'http://placehold.it/85x85'} alt=""/> */}

            <div className="col-md-6">
                <div className="row">
                <div className="col-12">
            {/* {initialValues.image_link && <div class="img-preview-container">
                    <div class="img-preview">
                    <img src={'http://localhost:3006/users/'+initialValues.image_link} alt="" height="300vh"/>
                    
                    </div>
                </div>} */}
                <h2>User Photo</h2>
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmFileUpload}
                />
                </div>
                </div>
                <div className="row">

                    <div className="col-12">
                    <FieldArray name="national_card_image" component={renderImages} />
                    </div>
                </div>
                
                {/* <Field
                    name="national_card_image"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmFileUpload}
                />*/}
                
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
              
                        {/* {initialValues.national_card_image && 
                        
                        <div style={{marginTop:"140px"}}>
                            <div className="row">
                                
                                {initialValues.national_card_image.map((card,i) => (
                                 
                                    <div key={i} className="col-3">
                                        <img className="national-id" src={'http://localhost:3006/cards/'+initialValues.national_card_image[i]} alt="" width="120vh"/>
                                    </div>
                                    
                                
                            ))}
                            </div>
                        </div>
                        } */}
                
                    
                    
                    {/* <div className="col-6">
                        {initialValues.national_card_image[1] && <div className="img-preview-container">
                        <div className="img-preview">
                            <img src={'http://localhost:3006/users/'+initialValues.national_card_image[1]} alt="" height="300vh"/>
                        
                        </div>
                        </div>}
                    </div> */}
               
                
</div>
            
           


        </div>
        <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={!valid || pristine || submitting}>
          Save 
        </button>
        <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
        <BwmResError errors={errors} />
      </form>
    )
  }
  
  export default reduxForm({
    form: 'accountUserForm',initialValues:{init},enableReinitialize: true
      
  })(AccountUserForm)