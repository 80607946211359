import React from 'react';
import ServiceCategoryCreateForm from './ServiceCategoryCreateForm';
import * as actions from '../../../actions';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from '../../shared/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import Back from "../../shared/navigation/Back";
class ServiceCategoryCreate extends React.Component{
    constructor(){
        super();

        this.state = {
            errors:[],
            services:'',
            redirect: false
        }
        this.booleanValue = ['','True', 'False'];
        this.createServiceCategory = this.createServiceCategory.bind(this);
        this.services = this.services.bind(this);
    }

    componentWillMount(){
        this.props.dispatch(actions.fetchServices(1,1000))
        
    }

    services(){
        if(this.props.services === null || this.props.services=="") 
            return null;
        let result = this.props.services.map(a=>a.name)
        return result
    }
    createServiceCategory(serviceCategoryData){
        actions.createServiceCategory(serviceCategoryData)
        .then(service => {
             this.setState({redirect: true}),
            //  <Redirect to={{pathname:'/services'}}/>
            toast.success("Success");
        },
        (errors) => {
            console.log('error',errors)
            this.setState({errors})
            toast.error('Status code: '+errors.status+' '+errors.data);
        }
        );
    }
    render(){
        // console.log('rrres',this.props.services)
        if(this.state.redirect){
            return <Redirect to={{pathname:'/services'}}/>
        }
        return(
            <div className="d-flex" id="wrapper">
          <Sidebar/>
            <div className="container">
            <section id='newRental'>
                <div className='bwm-form'>
                    <div className='row px-4'>
                    <div className='col-md-12'>
                    <Back />
                        <h1 className='page-title'>Create Service Category</h1>
                        <ServiceCategoryCreateForm submitCb={this.createServiceCategory} 
                                            options={this.booleanValue}
                                            services = {this.services()}
                                            errors={this.state.errors}/>
                    </div>
                    {/* <div className='col-md-6 ml-auto'>
                        <div className='image-container'>
                        <h2 className='catchphrase'>Hundreds of awesome places in reach of few clicks.</h2>
                        <img src={process.env.PUBLIC_URL + '/img/create-service.jpg'} alt=''/>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>
            </div></div>
        )
    }
}


function mapStateToProps(state){
    return {
        auth: state.auth,
        services: state.services.data,
    }
}
export default connect(mapStateToProps)(ServiceCategoryCreate);


