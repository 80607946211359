import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmInputDisabled} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
// import {BwmFileUpload} from '../../shared/form/BwmFileUpload';BwmServiceImageUpload
import {BwmProductImageUpload} from '../../shared/form/BwmProductImageUpload';


import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,numeric, text,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

const ServiceCreateForm = props => {
    const { handleSubmit, pristine, submitting, submitCb, valid, options,brands, categories, subcategories, errors } = props
    

    return (
      <form onSubmit={handleSubmit(submitCb)}>
        <div className="row">
        <div className="col-md-6">
        <Field
                    name="sku"
                    type="text"
                    label='SKU ID'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
        </div>
        <div className="col-md-6">
        <Field
                      name="sorting_no"
                      type="number"
                      label='Soting No'
                      className='form-control'
                      component={BwmInput}
                      validate={[required,numeric]}
                  />
        </div>
            <div className="col-md-6">
                <Field
                    name="name"
                    type="text"
                    label='Product Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                <Field
                    name="short_description"
                    type="text"
                    label='Short Description'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                  <Field
                    name="description"
                    type="text"
                    label='Description'
                    className='form-control'
                    component={BwmTextArea}
                    validate={[required]}
                />

              <div className="row">
                <div className="col-6">
                  <Field
                      options={options}
                      name="active"
                      type="text"
                      label='Active'
                      className='form-control'
                      component={BwmSelect}
                      validate={[required]}
                  />
                </div>
                <div className="col-6">
                  <Field
                    options={options}
                    name="available"
                    type="text"
                    label='Available'
                    className='form-control'
                    component={BwmSelect}
                    validate={[required]}
                  />
                </div>
              </div>

              <div className="row">
                  <div className="col-6">
                  <Field
                      name="price"
                      type="number"
                      label='Price'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="msrp"
                      type="number"
                      label='MSRP'
                      className='form-control'
                      component={BwmInput}
                      validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="discount"
                      type="number"
                      label='Discount'
                      className='form-control'
                      component={BwmInput}
                      validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                  <Field
                      name="units_stock"
                      type="number"
                      label='Units Stock'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                  </div>
                </div>
                <Field
                    options={brands}
                    name="brand_name"
                    type="text"
                    label='Brand'
                    className='form-control'
                    component={BwmSelect}
                    validate={[required]}
                />
                <Field
                    options={categories}
                    name="category_name"
                    type="text"
                    label='Category'
                    className='form-control'
                    component={BwmSelect}
                   
                    validate={[required]}
                />
                <Field
                    options={subcategories}
                    name="subcategory_name"
                    type="text"
                    label='Subcategory'
                    className='form-control'
                    component={BwmSelect}
               
                    validate={[required]}
                />

              
            </div>
            <div className="col-md-6">    
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmProductImageUpload}
                />
            </div>
            </div>
                
        <button className='btn btn-success btn-form' type="submit" disabled={!valid  || submitting}>
          Create Product
        </button>
        <BwmResError errors={errors} />
      </form>
    )
  }
  
  export default reduxForm({
    form: 'serviceCreateForm',
      //initialValues: { active: 'True'}
  })(ServiceCreateForm)