import React,{Fragment,useState,useEffect} from "react";
import {useDispatch} from 'react-redux';
import { connect } from 'react-redux';
import { toast, ToastContainer} from "react-toastify";
import DataTable from 'react-data-table-component';
import ServiceUpdateModal from "../../shared/Modal/ServiceUpdateModal";
import ServiceDeleteModal from "../../shared/Modal/ServiceDeleteModal";
import * as actions from '../../../actions';
import {Link} from 'react-router-dom';
export const Services =({servicesData}) =>{
  const dispatch = useDispatch();

    const [services, setServices] = useState(servicesData);
    const [selectedUser, setSelectedUser] = useState({});
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [searchUsers, setSearchUsers] = useState({});
    const [countPerPage, setCountPerPage] = useState(10);
    const [selectedService, setSelectedService] = useState({});
  

    const columns = [
      {
        name: 'Service Image',
        cell: row => row.image_link==null ||row.image_link==''?<i className="fa fa-5x fa-image"></i>:<img height="100px" width="100px" src={process.env.REACT_APP_FILE_SERVER+'/services/'+row.image_link} />,
      },
      {
        name: 'Name',
        selector: 'name',
      },
      {
          name: 'Active',
          // selector: 'active',
          cell: row => row.active == true?'Yes': 'No',
        },
        
        {
          name: 'Actions',
          button: true,
          cell: row =>  (
              <Fragment>
                  <button
                    data-toggle="modal"
                    data-target="#update-service-modal"
                    className="btn btn-primary btn-sm"
                    onClick={() => editRecord(row.id)}
                    style={{marginRight: '5px'}}>
                    <i className="fa fa-edit"></i>
                  </button>
                  <button
                    data-toggle="modal"
                    data-target="#delete-service-modal"
                    className="btn btn-danger btn-sm"
                    onClick={() => deleteRecord(row.id)}>
                    <i className="fa fa-trash"></i>
                  </button>
              </Fragment>
          )
        },
    ];

    function editRecord(id) {
      dispatch(actions.fetchServiceById(id))
      .then((res)=>{
        setSelectedService({ selectedService: res});
      })
      .catch()    
    }

    function deleteRecord(id) {
      console.log('id::',id)
      // dispatch(actions.deleteProduct(id))
      dispatch(actions.fetchService(id))
      .then((res)=>{
         console.log('res',res)
         setSelectedService({ selectedService: res});
      })
      .catch()    
    }

    function renderRow(service) {
      const index = services.findIndex((e)=> e.id === service.id);
      var newStateArray = services.slice();
      if (index === -1) {
          newStateArray.push(service);
      } else {
          newStateArray[index] = service;
      }
      if(services !== null || services !== undefined)
        getServiceList();
      else return null;
    }

    const getServiceList = () => {  
      dispatch(actions.fetchServices(page, countPerPage))
      .then(res => {
        setServices(res);
        setCountPerPage(10);
        setTotalRows(res[0].total_count);
        // console.log('res', res.data);
      }).catch(err => {
        setServices({});
      });
    }
  
    useEffect(() => {
      getServiceList();
    }, [page]);
    return (
      
      <div className="container-fluid">
        <div className="row px-4">
        
        <Link to="/services/create-service"><button class="btn btn-warning" style={{marginTop:"20px",marginLeft:"5px"}} type="button">Add Service</button></Link>       
          {services && <ServiceUpdateModal record={selectedService} action={renderRow}/>}
          {services &&<ServiceDeleteModal  record={selectedService} action={renderRow}/>}
          <ToastContainer/>
          {services && 
            <DataTable
              title="Services"
              columns={columns}
              data={services}
              // conditionalRowStyles={conditionalRowStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                  noRowsPerPage: true
              }}
              onChangePage={page => setPage(page)}
            />
          }
</div>
       
      </div>
    )
}

function mapStateToProps(state){
  return {
    auth: state.auth,
    // servicesData: state.services.data
  }
}

// const mapDispatchToProps = dispatch =>{
//   return {
//     fetchServiceById:(id)=>dispatch(fetchServiceById(id))
//   }
// }
export default connect(
  mapStateToProps, {
    forwardRef: true
  },
  //mapDispatchToProps
)(Services);
// export default connect(mapStateToProps)(Demo1)