import React from 'react';
import {Link} from 'react-router-dom';
import * as actions from '../../../actions';
import {ServiceManageCard} from './ServiceManageCard';
import {ServiceManageModal} from './ServiceManageModal';
import {ToastContainer, toast} from 'react-toastify';

export class ServiceManage extends React.Component{
    constructor(){
        super();
        this.state ={
            userServices:[],
            errors: [],
            isFetching: false
        }
        this.deleteService= this.deleteService.bind(this);
    }

    componentWillMount(){
        this.setState({isFetching:true});
        actions.getUserServices().then(
            userServices => this.setState({userServices, isFetching: false}),
            errors => this.setState({errors, isFetching: false})
        )
    }

    renderRentalCards(services){
        return services.map((service, index)=>
            <ServiceManageCard modal={<ServiceManageModal bookings={service.bookings}/>} 
                                key={index} 
                                service={service}
                                serviceIndex={index}
                                deleteServiceCb={this.deleteService}/>)
    }

    deleteService(serviceId, serviceIndex) {
        actions.deleteService(serviceId).then(
          () => this.deleteServiceFromList(serviceIndex),
          errors => toast.error(errors[0].detail))
      }
    
      deleteServiceFromList(serviceIndex) {
        const userRentals = this.state.userRentals.slice();
        userRentals.splice(serviceIndex, 1);
    
        this.setState({userRentals});
      }

    render(){
        const {userRentals, isFetching} = this.state;

        return(
            <section id='userRentals'>
                <ToastContainer/>
                <h1 className='page-title'>My Rentals</h1>
                <div className='row'>

                    {this.renderRentalCards(userRentals)}

                </div>
                {!isFetching && userRentals.length ===0 &&
                <div className='alert alert-warning'>
                    You dont have any services currenty created. If you want advertised your property
                    please follow this link.
                    <Link style={{'marginLeft': '10px'}} className='btn btn-bwm' to='create form'>Register Rental</Link>
                </div>}
            </section>
        )
    }
}