import React, {Component} from 'react';
import {ServiceList} from './ServiceList';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import GoogleMap from '../../map/GoogleMap';

import Sidebar from '../../shared/Sidebar';
import {Link} from 'react-router-dom';
import Services from "./Services";
import ServiceCategory from "./ServiceCategory";
import ServiceArea from "./ServiceArea";
import ServiceItem from "./ServiceItem";

class ServiceListing extends Component{
    constructor() {
        super();
        this.state = {
          showHideDemo1: true,
          showHideDemo2: false,
          showHideDemo3: false,
          showHideDemo4: false
        };
        this.hideComponent = this.hideComponent.bind(this);
      }
      hideComponent(name) {
        console.log(name);
        switch (name) {
          case "showHideDemo1":
            this.setState({ 
                showHideDemo1: !this.state.showHideDemo1, 
                showHideDemo2: false,
                showHideDemo3: false,
                showHideDemo4: false
            });
            break;
          case "showHideDemo2":
            this.setState({ 
                showHideDemo2: !this.state.showHideDemo2,
                showHideDemo1: false,
                showHideDemo3: false,
                showHideDemo4: false
            });
            break;
          case "showHideDemo3":
            this.setState({ 
                showHideDemo3: !this.state.showHideDemo3,
                showHideDemo1: false,
                showHideDemo2: false,
                showHideDemo4: false
            });
            break;
            case "showHideDemo4":
            this.setState({ 
                showHideDemo4: !this.state.showHideDemo4,
                showHideDemo1: false,
                showHideDemo2: false,
                showHideDemo3: false
            });
            break;
          default:
            null;
        }
      }
    // componentWillMount(){
    //     return this.props.dispatch(actions.fetchServices());
    //   }
  
    render(){ 
    

            // const services = this.props.services;
            // console.log(services)
                const { showHideDemo1, showHideDemo2, showHideDemo3, showHideDemo4  } = this.state;
                return (
                    <div className="d-flex" id="wrapper">
                        <Sidebar/>
                        <div  id="page-content-wrapper">
                            {/* <h1 className="text-primary">Services</h1> */}
                            <div className="container-fluid">
                                <div className="row px-2">
                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-primary text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Services</h5>
                                                <p className="card-text">Services are the main Category of Service</p>
                                                <button className="btn btn-light" onClick={() =>this.hideComponent("showHideDemo1")}>Service Main</button>
                                        
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-info text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Services Category</h5>
                                                <p className="card-text">Service Category is the branch of Service</p>
                                                <button className="btn btn-warning" onClick={() => this.hideComponent("showHideDemo2")}>Services Category</button>
                                        
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-dark text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Service Area</h5>
                                                <p className="card-text">Service Area is treatment area of Service</p>
                                                <button className="btn btn-danger" onClick={() => this.hideComponent("showHideDemo3")}>Service Area</button>
                                        
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-3 p-sm-2">                                     
                                        <div className="card bg-secondary text-white shadow-lg">
                                            <div className="card-body">
                                                <h5 className="card-title">Service Item</h5>
                                                <p className="card-text">Service Item is the treatment of a Service</p>
                                                <button className="btn btn-success" onClick={() => this.hideComponent("showHideDemo4")}>Service Item</button>
                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                               
                                    
                                    {showHideDemo1 && <Services />}
                                   
                                    {showHideDemo2 && <ServiceCategory />}
                                   
                                    {showHideDemo3 && <ServiceArea />}
                                 
                                    {showHideDemo4 && <ServiceItem />}
                                  
                                
                               
                            </div>
                        </div>
                       
                    
                    </div>
                 
                );
              }
             
}

// function mapStateToProps(state){
//     return {
//         services: state.services.data
//     }
// }

// export default connect(mapStateToProps)(ServiceListing)
export default ServiceListing;