export const FETCH_EVENT_INIT = 'FETCH_EVENT_INIT';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAIL = 'FETCH_EVENT_FAIL';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';
export const RESET_EVENT_ERRORS = 'RESET_EVENT_ERRORS';

export const FETCH_EVENTS_INIT = 'FETCH_EVENTS_INIT';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAIL = 'DELETE_EVENTS_FAIL';
