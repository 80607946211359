import React, {Component} from 'react';
import {UserGuard} from '../shared/auth/UserGuard';
import * as actions from '../../actions';
import Booking from '../booking/Booking';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
class BookingPage extends Component{
    constructor(){
        super();
        this.state ={
          response: {},
          isAllowed: false,
          isFetching: true,
          errors: [],
          errorsDetail:[],
          redirect: false
        }
        // this.genderCategories = ['Female'];//not used yet
        // this.updateAccount = this.updateAccount.bind(this);
        // this.updateAccount = this.updateAccount.bind(this);
        // this.resetAccountErrors = this.resetAccountErrors.bind(this);
        // this.verifyAccountOwner = this.verifyAccountOwner.bind(this);
    }
    componentWillMount(){
        return this.props.dispatch(actions.fetchServices());
      }
    render(){
        return(
            <div className="contact">
            <div className="container-fluid">
                
                <img style={{ margin:'-40px 0 20px 0',height:'auto',width:'100%'}} src={process.env.PUBLIC_URL + '/img/page/booking-1.jpg'} alt=""/> 
                
                
            </div>
            <div className="container">
                <section id='contactus'>
                    <div className='bwm-form'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h1 className="contact-shadow-title">Request an Appointment</h1>
                                <h1 className="contact-title">Request an Appointment</h1>
                                
                                {/* <ContactUsForm submitCb={this.contactUs} errors={errors}/> */}
                                <Booking services = {this.props.services}/>
                            </div>
                            
                        </div>
                    </div>
                </section>
            </div>
           
           
        </div>
        )
    }

}

function mapStateToProps(state){
    return {
        auth: state.auth,
        account: state.account.data,
        account: state.account.data,
        services: state.services.data,
        // errors: state.account.errors,
       // errors: state.account,
       // errors: state.account,
        initialValues: {
         
        }
    }
}

export default connect(mapStateToProps)(BookingPage);