import React from 'react';
import ServiceCreateForm from './ServiceCreateForm';
import * as actions from '../../../actions';
import {Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from '../../shared/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
class ServiceCreate extends React.Component{
    constructor(){
        super();
        this.state = {
            errors:[],
            redirect: false
        }
        this.booleanValue = ['','True', 'False'];
        this.createService = this.createService.bind(this);
    }

    createService(serviceData){
        actions.createService(serviceData)
        .then(service => {
            this.setState({redirect: true}),
            // <Redirect to={{pathname:'/services'}}/>
            toast.success("Success");     
        },
        (errors) => {
            this.setState({errors});
            toast.error('Status code: '+errors.status+' '+errors.data);
        });
    }
    render(){
        if(this.state.redirect){
            return <Redirect to={{pathname:'/services'}}/>
        }
        return(
            <div className="d-flex" id="wrapper">
          <Sidebar/>
            <div className="container">
            <section id='newRental'>
                <div className='bwm-form'>
                    <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='page-title'>Create Service</h1>
                        
                        <ServiceCreateForm submitCb={this.createService} 
                                            options={this.booleanValue}
                                            errors={this.state.errors}/>
                    </div>
                    {/* <div className='col-md-6 ml-auto'>
                        <div className='image-container'>
                        <h2 className='catchphrase'>Hundreds of awesome places in reach of few clicks.</h2>
                        <img src={process.env.PUBLIC_URL + '/img/create-service.jpg'} alt=''/>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>
            </div></div>
        )
    }
}


function mapStateToProps(state){
    return {
        auth: state.auth,
        
    }
}
export default connect(mapStateToProps)(ServiceCreate);


