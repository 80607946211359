import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmCheckbox} from '../shared/form/BwmInput';
import {BwmResError} from '../shared/form/BwmResError';
import {required, email, phone,home_phone,phone_bd,text,alphanumeric,checkbox, minLength2,minLength4, minLength6, maxLength16, maxLength32, maxLength64,maxLength512} from '../shared/form/validators';


const RegisterForm = props => {
  const { handleSubmit, pristine, submitting, submitCb, valid, errors } = props
  return (
    <form onSubmit={handleSubmit(submitCb)}>
      {/* <Field
        name="username"
        type="text"
        label="Username"
        className="form-control"
        component={BwmInput}
      />
   */}
     <Field
        name="first_name"
        type="text"
        label="First Name"
        className="form-control"
        component={BwmInput}
        validate={[required,text, minLength2, maxLength32]}
      />
      <Field
        name="last_name"
        type="text"
        label="Last Name"
        className="form-control"
        component={BwmInput}
        validate={[required,minLength2,maxLength32,text]}
      />
      <Field
        name="middle_name"
        type="text"
        label="Middle Name"
        className="form-control"
        component={BwmInput}
        validate={[minLength2,maxLength32,text]}
      />
      <Field
        name="cell_phone"
        type="text"
        label='Cell Phone'
        className='form-control'
        component={BwmInput}
        validate={[required, process.env.REACT_APP_ACCOUNT_DETAILS_FORM=="BD"?phone_bd:phone]}
      />
      <Field
        name="email"
        type="email"
        label="Email"
        className="form-control"
        component={BwmInput}
        validate={[required, email]}
      />
        
      <Field
        name="password"
        type="password"
        label="Password"
        className="form-control"
        component={BwmInput}
        validate={[required, minLength6,maxLength32 ]}
      />    
      
      <Field
        name="passwordConfirmation"
        type="password"
        label="Password Confirmation"
        className="form-control"
        component={BwmInput}
        validate={[required, minLength6,maxLength32 ]}
      />
      <Field
        name="acknowledgement"
        type="checkbox"
        label='By Clicking here you ackknowledge that our company is intended to serve only females with no exception
        and You also here by acknowledhe that you have read our Terms and Conditions. '
        // className='form-control'
        component={BwmCheckbox} 
        validate={[checkbox]}
      />
      <button className="btn btn-success btn-bwm btn-form" type="submit" disabled={!valid || pristine || submitting}>
        Register
      </button>
      <BwmResError errors = {errors} />
    </form>
  )
}

const validate = values => {
  const errors = {}

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Please enter password confirmation!';
  }

  if (values.password !== values.passwordConfirmation) {
    errors.password = 'Passwords must be the same';
  }

  return errors
}

export default reduxForm({
  form: 'registerForm', // a unique identifier for this form
  validate
})(RegisterForm)