export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_INIT = 'FETCH_SERVICES_INIT';
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL';



export const FETCH_SERVICE_INIT = 'FETCH_SERVICE_INIT';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_FAIL = 'FETCH_SERVICE_FAIL';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';

export const FETCH_SERVICE_BY_ID_SUCCESS = 'FETCH_SERVICE_BY_ID_SUCCESS';
export const FETCH_SERVICE_BY_ID_INIT = 'FETCH_SERVICE_BY_ID_INIT';

export const FETCH_USER_BOOKINGS_SUCCESS = 'FETCH_USER_BOOKINGS_SUCCESS';
export const FETCH_USER_BOOKINGS_FAIL = 'FETCH_USER_BOOKINGS_FAIL';
export const FETCH_USER_BOOKINGS_INIT = 'FETCH_USER_BOOKINGS_INIT';
export const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS';

export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL';
export const RESET_SERVICE_ERRORS = 'RESET_SERVICE_ERRORS';

export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAILURE='LOGIN_FAILURE';
export const LOGOUT='LOGOUT';

export const RELOAD_MAP = 'RELOAD_MAP';
export const RELOAD_MAP_FINISH = 'RELOAD_MAP_FINISH';

export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_INIT = 'FETCH_ACCOUNT_INIT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL';
export const RESET_ACCOUNT_ERRORS = 'RESET_ACCOUNT_ERRORS';


export const FETCH_ACCOUNT_DETAILS_INIT = 'FETCH_ACCOUNT_DETAILS_INIT';
export const FETCH_ACCOUNT_DETAILS_SUCCESS = 'FETCH_ACCOUNT_DETAILS_SUCCESS';
export const UPDATE_ACCOUNT_DETAILS_SUCCESS = 'UPDATE_ACCOUNT_DETAILS_SUCCESS';
export const UPDATE_ACCOUNT_DETAILS_FAIL = 'UPDATE_ACCOUNT_DETAILS_FAIL';
export const RESET_ACCOUNT_DETAILS_ERRORS = 'RESET_ACCOUNT_DETAILS_ERRORS';

// export const FETCH_ACCOUNT_DETAILS_BY_ID_INIT = 'FETCH_ACCOUNT_DETAILS_BY_ID_INIT';
// export const FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS = 'FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS'

export const FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS='FETCH_ACCOUNT_DETAILS_BY_ID_SUCCESS';
export const FETCH_ACCOUNT_DETAILS_BY_ID_INIT='FETCH_ACCOUNT_DETAILS_BY_ID_INIT';
export const UPDATE_ACCOUNT_DETAILS_BY_ID_SUCCESS='UPDATE_ACCOUNT_DETAILS_BY_ID_SUCCESS';
export const UPDATE_ACCOUNT_DETAILS_BY_ID_FAIL='UPDATE_ACCOUNT_DETAILS_BY_ID_FAIL';
export const RESET_ACCOUNT_DETAILS_BY_ID_ERRORS='RESET_ACCOUNT_DETAILS_BY_ID_ERRORS';



export const FETCH_SERVICE_CATEGORIES_SUCCESS = 'FETCH_SERVICE_CATEGORIES_SUCCESS';
export const FETCH_SERVICE_CATEGORIES_INIT = 'FETCH_SERVICE_CATEGORIES_INIT';
export const FETCH_SERVICE_CATEGORIES_FAIL = 'FETCH_SERVICE_CATEGORIES_FAIL';

export const FETCH_SERVICE_CATEGORY_INIT = 'FETCH_SERVICE_CATEGORY_INIT';
export const FETCH_SERVICE_CATEGORY_SUCCESS = 'FETCH_SERVICE_CATEGORY_SUCCESS';
export const FETCH_SERVICE_CATEGORY_FAIL = 'FETCH_SERVICE_CATEGORY_FAIL';
export const DELETE_SERVICE_CATEGORY_FAIL = 'DELETE_SERVICE_CATEGORY_FAIL';
export const DELETE_SERVICE_CATEGORY_SUCCESS = 'DELETE_SERVICE_CATEGORY_SUCCESS';

export const FETCH_SERVICE_ITEMS_INIT = 'FETCH_SERVICE_ITEMS_INIT';
export const FETCH_SERVICE_ITEMS_SUCCESS = 'FETCH_SERVICE_ITEMS_SUCCESS';
export const FETCH_SERVICE_ITEMS_FAIL = 'FETCH_SERVICE_ITEMS_FAIL';

export const UPDATE_SERVICE_ITEM_SUCCESS = 'UPDATE_SERVICE_ITEM_SUCCESS';
export const UPDATE_SERVICE_ITEM_FAIL = 'UPDATE_SERVICE_ITEM_FAIL';
export const RESET_SERVICE_ITEM_ERRORS = 'RESET_SERVICE_ITEM_ERRORS';

export const DELETE_SERVICE_ITEM_FAIL = 'DELETE_SERVICE_ITEM_FAIL';
export const DELETE_SERVICE_ITEM_SUCCESS = 'DELETE_SERVICE_ITEM_SUCCESS';

export const FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT = 'FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT';
export const FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_SUCCESS = 'FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT_SUCCESS';
export const FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_FAIL = 'FETCH_SERVICE_ITEMS_BY_CATEGORY_ID_INIT_FAIL';

export const FETCH_SERVICE_ITEM_INIT = 'FETCH_SERVICE_ITEM_INIT';
export const FETCH_SERVICE_ITEM_SUCCESS = 'FETCH_SERVICE_ITEM_SUCCESS';
export const FETCH_SERVICE_ITEM_FAIL = 'FETCH_SERVICE_ITEM_FAIL';

export const FETCH_ACCOUNTS_INIT = 'FETCH_ACCOUNTS_INIT';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL';

export const FETCH_ACCOUNT_BY_ID_INIT = 'FETCH_ACCOUNT_BY_ID_INIT';
export const FETCH_ACCOUNT_BY_ID_SUCCESS = 'FETCH_ACCOUNT_BY_ID_SUCCESS';
export const RESET_ACCOUNT_BY_ID_ERRORS='RESET_ACCOUNT_BY_ID_ERRORS';
export const UPDATE_ACCOUNT_BY_ID_SUCCESS = 'UPDATE_ACCOUNT_BY_ID_SUCCESS';
export const UPDATE_ACCOUNT_BY_ID_FAIL = 'UPDATE_ACCOUNT_BY_ID_FAIL';

export const  SEARCH_ACCOUNTS_INIT='SEARCH_ACCOUNTS_INIT';
export const  SEARCH_ACCOUNTS_SUCCESS='SEARCH_ACCOUNTS_SUCCESS';
export const  SEARCH_ACCOUNTS_FAIL='SEARCH_ACCOUNTS_FAIL';

export const  FETCH_SERVICE_CATEGORY_BY_ID_INIT='FETCH_SERVICE_CATEGORY_BY_ID_INIT';
export const  FETCH_SERVICE_CATEGORY_BY_ID_SUCCESS='FETCH_SERVICE_CATEGORY_BY_ID_SUCCESS';
export const  FETCH_SERVICE_CATEGORY_BY_ID_FAIL='FETCH_SERVICE_CATEGORY_BY_ID_FAIL';

export const  UPDATE_SERVICE_CATEGORY_BY_ID_FAIL='UPDATE_SERVICE_CATEGORY_BY_ID_FAIL';
export const  UPDATE_SERVICE_CATEGORY_BY_ID_SUCCESS='UPDATE_SERVICE_CATEGORY_BY_ID_SUCCESS';
export const  RESET_SERVICE_CATEGORY_BY_ID_ERRORS='RESET_SERVICE_CATEGORY_BY_ID_ERRORS';
     
export const  FETCH_SERVICE_AREAS_SUCCESS='FETCH_SERVICE_AREAS_SUCCESS';
export const  FETCH_SERVICE_AREAS_INIT='FETCH_SERVICE_AREAS_INIT';
export const  FETCH_SERVICE_AREAS_FAIL='FETCH_SERVICE_AREAS_FAIL';


export const  FETCH_SERVICE_AREA_INIT='FETCH_SERVICE_AREA_INIT';
export const  FETCH_SERVICE_AREA_SUCCESS='FETCH_SERVICE_AREA_SUCCESS';
export const  FETCH_SERVICE_AREA_FAIL='FETCH_SERVICE_AREA_FAIL';

export const  UPDATE_SERVICE_AREA_SUCCESS='UPDATE_SERVICE_AREA_SUCCESS';
export const  UPDATE_SERVICE_AREA_FAIL='UPDATE_SERVICE_AREA_FAIL';
export const  RESET_SERVICE_AREA_ERRORS='RESET_SERVICE_AREA_ERRORS';
export const  DELETE_SERVICE_AREA_FAIL='DELETE_SERVICE_AREA_FAIL';
export const  DELETE_SERVICE_AREA_SUCCESS='DELETE_SERVICE_AREA_SUCCESS';


export const FETCH_PRIVATE_IMAGES_INIT = 'FETCH_PRIVATE_IMAGES_INIT';
export const FETCH_PRIVATE_IMAGES_SUCCESS = 'FETCH_PRIVATE_IMAGES_SUCCESS';
export const FETCH_PRIVATE_IMAGES_FAIL = 'FETCH_PRIVATE_IMAGES_FAIL';
export const UPDATE_PRIVATE_IMAGES_SUCCESS='UPDATE_PRIVATE_IMAGES_SUCCESS';
export const DELETE_PRIVATE_IMAGES_SUCCESS='DELETE_PRIVATE_IMAGES_SUCCESS';
export const RESET_PRIVATE_IMAGES_ERRORS='RESET_PRIVATE_IMAGES_ERRORS';

export const FETCH_PRIVATE_IMAGES_BY_ID_INIT='FETCH_PRIVATE_IMAGES_BY_ID_INIT';
export const FETCH_PRIVATE_IMAGES_BY_ID_SUCCESS='FETCH_PRIVATE_IMAGES_BY_ID_SUCCESS';
export const FETCH_PRIVATE_IMAGES_BY_ID_FAIL='FETCH_PRIVATE_IMAGES_BY_ID_FAIL';
export const UPDATE_PRIVATE_IMAGES_BY_ID_SUCCESS='UPDATE_PRIVATE_IMAGES_BY_ID_SUCCESS';
export const RESET_PRIVATE_IMAGES_BY_ID_ERRORS='RESET_PRIVATE_IMAGES_BY_ID_ERRORS';
export const DELETE_PRIVATE_IMAGES_BY_ID_SUCCESS='DELETE_PRIVATE_IMAGES_BY_ID_SUCCESS';

