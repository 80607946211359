import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmCheckbox,BwmInputDisabled} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
import {BwmProductImageUpload} from '../../shared/form/BwmProductImageUpload';

import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,text,numeric,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

let init = {}
const ProductDetailForm = props => {
  const { handleSubmit, pristine,reset, submitting, submitCb, valid, options,brands, categories, subcategories, errors,initialValues } = props;
  init = initialValues
// console.log('Init', init)
  return (
    <form onSubmit={handleSubmit(submitCb)} >

      <div className="row">
        <div className="col-md-6">
        <Field
                    name="sku"
                    type="text"
                    label='SKU ID'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
        </div>
        <div className="col-md-6">
        <Field
                      name="sorting_no"
                      type="number"
                      label='Soting No'
                      className='form-control'
                      component={BwmInput}
                       validate={[numeric]}
                  />
        </div>
            <div className="col-md-6">
                <Field
                    name="name"
                    type="text"
                    label='Product Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                <Field
                    name="short_description"
                    type="text"
                    label='Short Description'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                  <Field
                    name="description"
                    type="text"
                    label='Description'
                    className='form-control'
                    component={BwmTextArea}
                    validate={[required]}
                />

              <div className="row">
                <div className="col-6">
                  <Field
                      options={options}
                      name="active"
                      type="text"
                      label='Active'
                      className='form-control'
                      component={BwmSelect}
                      values={init.active}
                      validate={[required]}
                  />
                </div>
                <div className="col-6">
                  <Field
                    options={options}
                    name="available"
                    type="text"
                    label='Available'
                    className='form-control'
                    component={BwmSelect}
                    values={init.available}
                    validate={[required]}
                  />
                </div>
              </div>

              <div className="row">
                  <div className="col-6">
                  <Field
                      name="price"
                      type="number"
                      label='Price'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="msrp"
                      type="number"
                      label='MSRP'
                      className='form-control'
                      component={BwmInput}
                       validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                    <Field
                      name="discount"
                      type="number"
                      label='Discount'
                      className='form-control'
                      component={BwmInput}
                       validate={[numeric]}
                  />
                  </div>
                  <div className="col-6">
                  <Field
                      name="units_stock"
                      type="number"
                      label='Units Stock'
                      className='form-control'
                      component={BwmInput}
                      validate={[required, numeric]}
                  />
                  </div>
                </div>
                <Field
                    options={brands}
                    name="brand_name"
                    type="text"
                    label='Brand'
                    className='form-control'
                    component={BwmSelect}
                    values={init.brand_name}
                    validate={[required]}
                />
                <Field
                    options={categories}
                    name="category_name"
                    type="text"
                    label='Subcategory'
                    className='form-control'
                    component={BwmSelect}
                    values={init.category_name}
                    validate={[required]}
                />
                <Field
                    options={subcategories}
                    name="subcategory_name"
                    type="text"
                    label='Subcategory'
                    className='form-control'
                    component={BwmSelect}
                    values={init.subcategory_name}
                    validate={[required]}
                />


                <Field
                    name="updated_at"
                    type="text"
                    label='Updated'
                    className='form-control'
                    component={BwmInputDisabled}
                    
                />
                <Field
                    name="created_at"
                    type="text"
                    label='Created'
                    className='form-control'
                    component={BwmInputDisabled}
                /> 

              
            </div>
            <div className="col-md-6">    
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmProductImageUpload}
                />
            </div>
            </div>
            <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={pristine || submitting}>
          Save 
        </button>
        <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
        <BwmResError errors={errors} />
      </form>
  )
}
  
  export default reduxForm({
    form: 'productDetailForm',initialValues:{init},enableReinitialize: true
      
  })(ProductDetailForm)