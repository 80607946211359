import React from 'react';

function Footer(){
    {if (window.location.pathname === '/login') 
    
   return null;

    else{
        return(
            <div>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footer-widget">
                                    <h3>Stay in touch</h3>
                                    <div className="footer-widget-content">
                                        {/* <a href="mailto:contact@eminentBeautyCentre.com" className="contact-link">contact@eminentBeautyCentre.com</a> */}
                                        <a href="mailto:support@example.com" className="contact-link red"> contact@eminentBeautyCentre.com </a>
                                        <a href="tel:0121234" className="contact-link">(647) 456-789</a>
                                        <div>Eminent Beauty Centre Inc.</div>
                                        <div>2434 Danforth Ave, Toronto,</div>
                                        <div>ON M4C 1K9, Canada</div>
                                        
                                        <div className="footer-social">
                                            <ul>
                                            <li><a href=""><i className="fa fa-facebook"></i></a></li>
                                            <li><a href=""><i className="fa fa-twitter"></i></a></li>
                                            <li><a href=""><i className="fa fa-linkedin"></i></a></li>
                                            <li><a href=""><i className="fa fa-youtube"></i></a></li>
                                            <li><a href=""><i className="fa fa-rss"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                    <div className="footer-widget">
                                        <h3>Latest Events</h3>
                                        <div className="footer-widget-content">
                                            <div className="media">
                                                <div className="media-left">
                                                    <a href=""><img className="media-object" src={"http://placehold.it/60x60"} alt=""></img></a>
                                                </div>
                                                <div className="media-body">
                                                    <p><a href="">Grand Opening. </a></p>
                                                    <span>August 15, 2020 </span>
                                                </div>
                                            </div>
                                            <div className="media">
                                                <div className="media-left">
                                                <a href=""><img className="media-object" src={"http://placehold.it/60x60"} alt=""></img></a>
                                                </div>
                                                <div className="media-body">
                                                    <p><a href="">Get up to 50 Points. </a></p>
                                                    <span>September 30, 2020 </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                            <div className="col-md-3">
                                <div className="footer-widget">
                                    <h3>Opening Hour</h3>
                                    <div className="footer-widget-content">
                                        <div className="open-time ">
                                            <ul className="opening-time">
                                                <li><span><i className="fa fa-times"></i></span><p className="clock-time"><strong>monday :</strong> closed</p>
                                                </li>
                                                <li><span><i className="fa fa-check"></i></span><p><strong>tue -fri :</strong>10:00 am - 8:00 pm</p></li>
                                                <li><span><i className="fa fa-check"></i></span><p><strong>sat-sun :</strong> 10:00 am - 8:00 pm</p></li>
                                                <li><span><i className="fa fa-check"></i></span><p><strong>holydays :</strong>By Appointment</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                    <div className="footer-widget">
                                        <h3>Latest Events</h3>
                                        <div className="footer-widget-content">
                                            <div className="images-gellary">
                                                <ul>
                                                    <li><a href=""><img src="http://placehold.it/85x85" alt="Instagram 01"/></a></li>
                                                    <li><a href=""><img src="http://placehold.it/85x85" alt="Instagram 02"/></a></li>
                                                    <li><a href=""><img src="http://placehold.it/85x85" alt="Instagram 03"/></a></li>
                                                    <li><a href=""><img src="http://placehold.it/85x85" alt="Instagram 04"/></a></li>
                                                    <li><a href=""><img src="http://placehold.it/85x85" alt="Instagram 05"/></a></li>
                                                    <li><a href=""><img src="http://placehold.it/85x85" alt="Instagram 06"/></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    
                    <div className="footer-bar">
                            &#9400;2020-2026 All Rights Reserved By Eninent Beauty Centre Inc.
                        </div>
                    </div>
                
                        
                
                </div>
        )

    }}
    

}
export default Footer;