import React from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import * as actions from '../../../actions';
import 'react-toastify/dist/ReactToastify.css';
import ServiceCategoryDetailForm from '../../services/service-detail/ServiceCategoryDetailForm';
class ServiceCategoryUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record:this.props.record,
            id: this.props.record.id,
            name: this.props.record.name,
            errors: {},
        };
        this.booleanValue = ['true', 'false'];
        this.updateServiceCategory = this.updateServiceCategory.bind(this);
        this.services = this.services.bind(this);
    }
    componentWillMount(){
        this.props.dispatch(actions.fetchServices(1,1000))
        
    }

    services(){
        if(this.props.services === null || this.props.services=="") 
            return null;
        let result = this.props.services.map(a=>a.name)
        return result
    }

    updateServiceCategory(serviceCategoryData){
        const {serviceCategory} = this.props;
        let mergeData = {...serviceCategory, ...serviceCategoryData};
        console.log('merge', mergeData)
        this.props.dispatch(actions.updateServiceCategory(mergeData))
        .then((response) => {
            console.log('status', response)
          if(response.status ==='success'){
            this.setState({errors:[]});
            this.props.dispatch(actions.updateServiceCategorySuccess(mergeData));
            this.setState({record:mergeData});
            this.props.action(this.state.record);
            toast.success("Success");
          }else{
            this.setState({errors:[response.errors[0]]});
          }
        });
    }
 
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-service-category-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Service Category</h4>
                                {/* <button type="button" onClick="javascript:window.location.reload()" className="close" data-dismiss="modal">&times;</button> */}
                            </div>
                            <div className="modal-body">
                                <ServiceCategoryDetailForm  
                                    submitCb={this.updateServiceCategory} 
                                    errors={errors}
                                    options={this.booleanValue}
                                    services = {this.services()}
                                    initialValues ={this.props.initialValues}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}

function mapStateToProps (state) {
    return{
    auth: state.auth,
    errors: state.errors,
    services: state.services.data,
    serviceCategory: state.serviceCategory.data,
     initialValues: {
         name: state.serviceCategory.data.name,
        image_link: state.serviceCategory.data.image_link,
        active: state.serviceCategory.data.active,
        service_name:state.serviceCategory.data.service_name,
        short_description: state.serviceCategory.data.short_description,
        description: state.serviceCategory.data.description,
        updated_at: state.serviceCategory.data.updated_at,
        created_at: state.serviceCategory.data.created_at
      }
    }
};

export default connect(
    mapStateToProps
)(withRouter(ServiceCategoryUpdateModal));
