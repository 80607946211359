import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from "../actions/types";

const INITIAL_STATE = {
    isAuth: false,
    user_role:'',
    errors:[],
    // username:'',
    userId:'',
    firstName:'',
    lastName:'',
    email:''
}

export const authReducer = (state=INITIAL_STATE, action) =>{
    switch(action.type){
        case LOGIN_SUCCESS:
            //return {...state, data: action.services }
            return Object.assign({}, state, {isAuth: true, user_role: action.user_role, errors:[], userId: action.userId, firstName: action.firstName, lastName: action.lastName, email: action.email});

        case LOGIN_FAILURE:
            return Object.assign({}, state, {errors: action.errors});
        
        case LOGOUT:
            return Object.assign({}, state, {isAuth: false,user_role:'', userId: '', firstName: '', lastName: '', email: ''});
        default:
            return state;
    }
}
