import React from 'react';
export const BwmInput = ({
    input,
    label,
    type,  
    symbol,
    className,
    meta: { touched, error, warning }
  }) => (
    <div className='form-group'>
      <label>{label}</label>
      <div className='input-group'>
        { symbol &&
          <div className='input-group-prepend'>
            <div className='input-group-text'>{symbol}</div>
          </div>
        }
        
        <input {...input} type={type} className={className}/>
      </div>  
      {touched &&
      ((error && <div className='alert alert-danger'>{error}</div>))}
      
    </div>
  )
  
  export const BwmInputDisabled = ({
    input,
    label,
    type,  
    symbol,
    className
  }) => (
    <div className='form-group'>
      <label>{label}</label>
      <div className='input-group'>
        { symbol &&
          <div className='input-group-prepend'>
            <div className='input-group-text'>{symbol}</div>
          </div>
        }
        
        <input disabled {...input} type={type} className={className}/>
      </div>  
      
    </div>
  )




  export const BwmCheckbox = ({
    input,
    label,
    type,  
    symbol,
    className,
    meta: { touched, error, warning }
  }) => (
    <div>
        { symbol  }
        <input {...input} type={type} className={className}/>
        <label  style={{fontSize:"1em", textAlign:"justify"}}>{label}</label>
      
      {touched &&
      ((error && <div className='alert alert-danger'>{error}</div>))}
      
    </div>
  )
  