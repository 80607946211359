import { 
   FETCH_EVENTS_INIT,
   FETCH_EVENTS_SUCCESS,
   FETCH_EVENTS_FAIL, 
   DELETE_EVENTS_SUCCESS,
   DELETE_EVENTS_FAIL,

   FETCH_EVENT_INIT,
   FETCH_EVENT_SUCCESS,
   FETCH_EVENT_FAIL, 
   UPDATE_EVENT_SUCCESS,
   UPDATE_EVENT_FAIL,
   RESET_EVENT_ERRORS
   
   } from "../actions/events/types";

const INITIAL_STATE = {
   events:{
   data:[],
   errors:[]
},
event:{
   data:{}
}    
}

export const eventsReducer = (state=INITIAL_STATE.events, action) =>{
   switch(action.type){
      case FETCH_EVENTS_INIT:
         return {...state, data:[],errors:[]}
      case FETCH_EVENTS_SUCCESS:
         return {...state, data: action.events }
      case FETCH_EVENTS_FAIL:
         return Object.assign({},{errors: action.errors, data:[]})
      case DELETE_EVENTS_SUCCESS:
         return {...state, data:state.data.filter(x => x.id !== action.events)
      }
      case DELETE_EVENTS_FAIL:
         return Object.assign({},{errors: action.errors, data:[]})
      default:
         return state;
   }
}

export const eventReducer = (state=INITIAL_STATE.event, action) =>{
   switch(action.type){
      case FETCH_EVENT_INIT:
         return {...state, data: {} }
      case FETCH_EVENT_SUCCESS:
         return {...state, data: action.event }
      case FETCH_EVENT_FAIL:
         return Object.assign({},{errors: action.errors, data:[]})
      case UPDATE_EVENT_SUCCESS:
         return {...state, data: action.event};
      case UPDATE_EVENT_FAIL:
         return {...state, errors: action.errors};
      case RESET_EVENT_ERRORS:
         return {...state, errors: []};
      default:
         return state;
   }
}