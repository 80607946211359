import {FETCH_BRANDS_SUCCESS, 
        FETCH_BRANDS_INIT,
        FETCH_BRANDS_FAIL,

        FETCH_BRAND_SUCCESS,
        FETCH_BRAND_INIT,
        FETCH_BRAND_FAIL,

        UPDATE_BRAND_SUCCESS,
        UPDATE_BRAND_FAIL,

        DELETE_BRAND_SUCCESS,
        DELETE_BRAND_FAIL,

        FETCH_PRODUCTS_SUCCESS,
        FETCH_PRODUCTS_INIT,
        FETCH_PRODUCTS_FAIL,

        FETCH_PRODUCT_SUCCESS,
        FETCH_PRODUCT_INIT,
        FETCH_PRODUCT_FAIL,

        UPDATE_PRODUCT_SUCCESS,
        UPDATE_PRODUCT_FAIL,
        RESET_PRODUCT_ERRORS,
        FETCH_PRODUCT_BY_ID_SUCCESS,
        FETCH_PRODUCT_BY_ID_INIT,

        DELETE_PRODUCT_SUCCESS,
        DELETE_PRODUCT_FAIL,

        FETCH_CATEGORIES_SUCCESS, 
        FETCH_CATEGORIES_INIT,
        FETCH_CATEGORIES_FAIL, 
        FETCH_CATEGORY_INIT,
        FETCH_CATEGORY_SUCCESS,
        FETCH_CATEGORY_FAIL,
        UPDATE_CATEGORY_SUCCESS,
        DELETE_CATEGORY_FAIL,
        DELETE_CATEGORY_SUCCESS,


        FETCH_SUBCATEGORIES_SUCCESS,
        FETCH_SUBCATEGORIES_INIT,
        FETCH_SUBCATEGORIES_FAIL,

        FETCH_SUBCATEGORY_INIT,
        FETCH_SUBCATEGORY_SUCCESS,
        FETCH_SUBCATEGORY_FAIL,
        UPDATE_SUBCATEGORY_SUCCESS, 
        UPDATE_SUBCATEGORY_FAIL,
        DELETE_SUBCATEGORY_FAIL,
        DELETE_SUBCATEGORY_SUCCESS, 
        RESET_SUBCATEGORY_ERRORS, 

} from './types';

import axiosService from '../../services/axios-service';

const axiosInstance = axiosService.getInstance();

// export const fetchBrands = (page, countPerPage)=>{
//     const url = `/products/find_all_brands/${page}/${countPerPage}`;
//     return function (dispatch){
//       dispatch(fetchBrandsInit());
//       return axiosInstance.get(url)
//         .then(res => res.data)
//         .then(brands=>{
//           dispatch(fetchBrandsSuccess(brands));
//           return brands;//TODO this has to be removed cause service should come from reducer
//         })
//         .catch(({response}) => {
//           dispatch(fetchBrandsFail(response.data.errors));
//         })
//     }
//   }


//   const fetchBrandsSuccess = (brands) =>{
//     return {
//       type: FETCH_BRANDS_SUCCESS,
//       brands: brands
//     }
//   }
//   const fetchBrandsInit =() =>{//used to reset the data
//     return {
//       type: FETCH_BRANDS_INIT 
//     }
//   }
//   const fetchBrandsFail =(errors) =>{
//     return {
//       type:FETCH_BRANDS_FAIL,
//       errors
//     }
//   }

/////////////////////////////////////////Brands////////////////////////////////////////////////
export const createBrand = (brandData) =>{
  return axiosInstance.post('/products/brand_add', brandData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}

export const fetchBrands = (page, countPerPage)=>{
  const url = `/products/find_all_brands/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchBrandsInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(brands=>{
        dispatch(fetchBrandsSuccess(brands));
        return brands;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchBrandsFail(response.data.errors));
      })
  }
}

const fetchBrandsSuccess = (brands) =>{
  return {
    type: FETCH_BRANDS_SUCCESS,
    brands: brands
  }
}
const fetchBrandsInit =() =>{//used to reset the data
  return {
    type: FETCH_BRANDS_INIT 
  }
}
const fetchBrandsFail =(errors) =>{
  return {
    type:FETCH_BRANDS_FAIL,
    errors
  }
}

export const fetchBrand = (itemId)=>{
  console.log('itemId',itemId)
  return function (dispatch){
    dispatch(fetchBrandInit());
    return axiosInstance.get(`/products/brand_details/${itemId}`)
      .then(res => res.data[0])
      .then(brand=>{
        dispatch(fetchBrandSuccess(brand));
        return brand
      })
      .catch(({response}) => {
          dispatch(fetchBrandFail(response.data.errors));
        })
  }
}

const fetchBrandInit=()=>{
  return {
    type: FETCH_BRAND_INIT,
    
  }
}
const fetchBrandSuccess = (brand) =>{
  return {
    type: FETCH_BRAND_SUCCESS,
    brand: brand
  }
}
const fetchBrandFail =(errors) =>{
  return {
    type:FETCH_BRAND_FAIL,
    errors
  }
}

export const updateBrand = (brandData) => dispatch => {
  console.log('brandData',brandData)
  return axiosInstance.post(`/products/brand_edit/${brandData.id}`, brandData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateBrandFail(response.data.errors)))
}
export const updateBrandFail = (errors) => {
  return {
    type: UPDATE_BRAND_FAIL,
    errors
  }
}

export const updateBrandSuccess = (updatedBrand) => {
  return {
    type: UPDATE_BRAND_SUCCESS,
    brand: updatedBrand
  }
}


export const deleteBrand = (id) => dispatch => {
  
  return axiosInstance.delete(`/products/brand_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteBrandFail(response.data.errors)))
}
export const deleteBrandFail = (errors) => {
  return {
    type: DELETE_BRAND_FAIL,
    errors
  }
}

export const deleteBrandSuccess = (id) => {
  return {
    type: DELETE_BRAND_SUCCESS,
    brands:id
  }
}



///////////////////////////////Product/////////////////////////////////////////////////////////
export const createProduct = (productData) =>{
    return axiosInstance.post('/products/product_add', productData)
      .then(res=> res.data,
            err =>Promise.reject(err.response.data.errors)
      )
  }

  export const fetchProducts = (page, countPerPage)=>{
    const url = `/products/find_all_products/${page}/${countPerPage}`;
    return function (dispatch){
      dispatch(fetchProductsInit());
      return axiosInstance.get(url)
        .then(res => res.data)
        .then(brands=>{
          dispatch(fetchProductsSuccess(brands));
          return brands;//TODO this has to be removed cause service should come from reducer
        })
        .catch(({response}) => {
          dispatch(fetchProductsFail(response.data.errors));
        })
    }
  }

  const fetchProductsSuccess = (products) =>{
    return {
      type: FETCH_PRODUCTS_SUCCESS,
      products: products
    }
  }
  const fetchProductsInit =() =>{//used to reset the data
    return {
      type: FETCH_PRODUCTS_INIT 
    }
  }
  const fetchProductsFail =(errors) =>{
    return {
      type:FETCH_PRODUCTS_FAIL,
      errors
    }
  }

  export const fetchProduct = (itemId)=>{
    console.log('itemId',itemId)
    return function (dispatch){
      dispatch(fetchProductInit());
      return axiosInstance.get(`/products/product_details/${itemId}`)
        .then(res => res.data[0])
        .then(product=>{
          dispatch(fetchProductSuccess(product));
          return product
        })
        .catch(({response}) => {
            dispatch(fetchProductFail(response.data.errors));
          })
    }
  }
  
  const fetchProductInit=()=>{
    return {
      type: FETCH_PRODUCT_INIT,
      
    }
  }
  const fetchProductSuccess = (product) =>{
    return {
      type: FETCH_PRODUCT_SUCCESS,
      product: product
    }
  }
  const fetchProductFail =(errors) =>{
    return {
      type:FETCH_PRODUCT_FAIL,
      errors
    }
  }

  export const updateProduct = (productData) => dispatch => {
    console.log('productData',productData)
    return axiosInstance.post(`/products/product_edit/${productData.id}`, productData)
      .then(res => res.data)
      .catch(({response}) => dispatch(updateProductFail(response.data.errors)))
  }
  export const updateProductFail = (errors) => {
    return {
      type: UPDATE_PRODUCT_FAIL,
      errors
    }
  }
  
  export const updateProductSuccess = (updatedProduct) => {
    return {
      type: UPDATE_PRODUCT_SUCCESS,
      product: updatedProduct
    }
  }
  

  export const deleteProduct = (id) => dispatch => {
    
    return axiosInstance.delete(`/products/product_delete/${id}`)
      .then(res => res.data)
      .catch(({response}) => console.log(response))//dispatch(deleteProductFail(response.data.errors)))
  }
  export const deleteProductFail = (errors) => {
    return {
      type: DELETE_PRODUCT_FAIL,
      errors
    }
  }
  
  export const deleteProductSuccess = (id) => {
    return {
      type: DELETE_PRODUCT_SUCCESS,
      products:id
    }
  }



//----------------------------Category-------------------------------------------------
export const createCategory = (categoryData) =>{
  return axiosInstance.post('/products/category_add', categoryData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}

export const fetchCategories = (page, countPerPage)=>{
  const url = `/products/find_all_categories/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchCategoriesInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(categories=>{
        dispatch(fetchCategoriesSuccess(categories));
        return categories;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchCategoriesFail(response.data.errors));
      })
  }
}

const fetchCategoriesSuccess = (categories) =>{
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    categories: categories
  }
}
const fetchCategoriesInit =() =>{//used to reset the data
  return {
    type: FETCH_CATEGORIES_INIT 
  }
}
const fetchCategoriesFail =(errors) =>{
  return {
    type:FETCH_CATEGORIES_FAIL,
    errors
  }
}

export const fetchCategory = (itemId)=>{
  console.log('itemId',itemId)
  return function (dispatch){
    dispatch(fetchCategoryInit());
    return axiosInstance.get(`/products/category_details/${itemId}`)
      .then(res => res.data[0])
      .then(category=>{
        dispatch(fetchCategorySuccess(category));
        return category
      })
      .catch(({response}) => {
          //dispatch(fetchCategoryFail(response.data.errors));
          console.log("response::", response)
        })
  }
}

const fetchCategoryInit=()=>{
  return {
    type: FETCH_CATEGORY_INIT,
    
  }
}
const fetchCategorySuccess = (category) =>{
  return {
    type: FETCH_CATEGORY_SUCCESS,
    category: category
  }
}
const fetchCategoryFail =(errors) =>{
  return {
    type:FETCH_CATEGORY_FAIL,
    errors
  }
}

export const updateCategory = (categoryData) => dispatch => {
  console.log('categoryData',categoryData)
  return axiosInstance.post(`/products/category_edit/${categoryData.id}`, categoryData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateCategoryFail(response.data.errors)))
}
export const updateCategoryFail = (errors) => {
  return {
    type: UPDATE_CATEGORY_FAIL,
    errors
  }
}

export const updateCategorySuccess = (updatedCategory) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    category: updatedCategory
  }
}


export const deleteCategory = (id) => dispatch => {
  
  return axiosInstance.delete(`/products/category_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => {
      dispatch(deleteCategoryFail(response.data.errors))
    })//console.log(response.data.errors[0].detail))//
    
}
export const deleteCategoryFail = (errors) => {
  return {
    type: DELETE_CATEGORY_FAIL,
    errors
  }
}

export const deleteCategorySuccess = (id) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    categories:id
  }
}




//----------------------------Subcategory-------------------------------------------------
export const createSubcategory = (subcategoryData) =>{
  return axiosInstance.post('/products/subcategory_add', subcategoryData)
    .then(res=> res.data,
          err =>Promise.reject(err.response.data.errors)
    )
}
///
export const fetchSubcategoriesByCategory = (categoryName) =>{
  const url = `/products/find_all_subcategories_by_category_name/${categoryName}`;
  return axiosInstance.get(url)
    .then(res => res.data)
    // .then(subcategories=>{
    //   return subcategories;
      // })
}



export const fetchProductsByCategorySubcategoryBrand = (categoryName, subcategoryName, brandName) =>{
  const url = `/products/find_all_products_by_category_name_subcategory_name_brand_name/${categoryName}/${subcategoryName}/${brandName}`;
  return axiosInstance.get(url)
    .then(res => res.data)
    // .then(subcategories=>{
    //   return subcategories;
      // })
}
///
export const fetchSubcategories = (page, countPerPage)=>{
  const url = `/products/find_all_subcategories/${page}/${countPerPage}`;
  return function (dispatch){
    dispatch(fetchSubcategoriesInit());
    return axiosInstance.get(url)
      .then(res => res.data)
      .then(subcategories=>{
        dispatch(fetchSubcategoriesSuccess(subcategories));
        return subcategories;//TODO this has to be removed cause service should come from reducer
      })
      .catch(({response}) => {
        dispatch(fetchSubcategoriesFail(response.data.errors));
      })
  }
}

const fetchSubcategoriesSuccess = (subcategories) =>{
  return {
    type: FETCH_SUBCATEGORIES_SUCCESS,
    subcategories: subcategories
  }
}
const fetchSubcategoriesInit =() =>{//used to reset the data
  return {
    type: FETCH_SUBCATEGORIES_INIT 
  }
}
const fetchSubcategoriesFail =(errors) =>{
  return {
    type:FETCH_SUBCATEGORIES_FAIL,
    errors
  }
}

export const fetchSubcategory = (subcategoryId)=>{
  console.log('subcategoryId',subcategoryId)
  return function (dispatch){
    dispatch(fetchSubcategoryInit());
    return axiosInstance.get(`/products/subcategory_details/${subcategoryId}`)
      .then(res => res.data[0])
      .then(subcategory=>{
        dispatch(fetchSubcategorySuccess(subcategory));
        return subcategory
      })
      .catch(({response}) => {
          dispatch(fetchSubcategoryFail(response.data.errors));
        })
  }
}

const fetchSubcategoryInit=()=>{
  return {
    type: FETCH_SUBCATEGORY_INIT,
    
  }
}
const fetchSubcategorySuccess = (subcategory) =>{
  return {
    type: FETCH_SUBCATEGORY_SUCCESS,
    subcategory: subcategory
  }
}
const fetchSubcategoryFail =(errors) =>{
  return {
    type:FETCH_SUBCATEGORY_FAIL,
    errors
  }
}

export const updateSubcategory = (subcategoryData) => dispatch => {
  console.log('subcategoryData',subcategoryData)
  return axiosInstance.post(`/products/subcategory_edit/${subcategoryData.id}`, subcategoryData)
    .then(res => res.data)
    .catch(({response}) => dispatch(updateSubcategoryFail(response.data.errors)))
}
export const updateSubcategoryFail = (errors) => {
  return {
    type: UPDATE_SUBCATEGORY_FAIL,
    errors
  }
}

export const updateSubcategorySuccess = (updatedSubcategory) => {
  return {
    type: UPDATE_SUBCATEGORY_SUCCESS,
    subcategory: updatedSubcategory
  }
}


export const deleteSubcategory = (id) => dispatch => {
  
  return axiosInstance.delete(`/products/subcategory_delete/${id}`)
    .then(res => res.data)
    .catch(({response}) => console.log(response))//dispatch(deleteSubcategoryFail(response.data.errors)))
}
export const deleteSubcategoryFail = (errors) => {
  return {
    type: DELETE_SUBCATEGORY_FAIL,
    errors
  }
}

export const deleteSubcategorySuccess = (id) => {
  return {
    type: DELETE_SUBCATEGORY_SUCCESS,
    subcategories:id
  }
}

//--------------------------------------------------------------------------------------
 