export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS';
export const FETCH_BRANDS_INIT = 'FETCH_BRANDS_INIT';
export const FETCH_BRANDS_FAIL = 'FETCH_BRANDS_FAIL';

export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_INIT = 'FETCH_BRAND_INIT';
export const FETCH_BRAND_FAIL = 'FETCH_BRAND_FAIL';



export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_FAIL = 'UPDATE_BRAND_FAIL';
export const RESET_BRAND_ERRORS = 'RESET_BRAND_ERRORS';


export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_FAIL = 'DELETE_BRAND_FAIL';


export const FETCH_PRODUCTS_SUCCESS ='FETCH_PRODUCTS_SUCCESS'; 
export const FETCH_PRODUCTS_INIT ='FETCH_PRODUCTS_INIT';
export const FETCH_PRODUCTS_FAIL ='FETCH_PRODUCTS_FAIL'; 

export const FETCH_PRODUCT_SUCCESS ='FETCH_PRODUCT_SUCCESS'; 
export const FETCH_PRODUCT_INIT ='FETCH_PRODUCT_INIT';
export const FETCH_PRODUCT_FAIL ='FETCH_PRODUCT_FAIL';

export const UPDATE_PRODUCT_SUCCESS ='UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL ='UPDATE_PRODUCT_FAIL';
export const RESET_PRODUCT_ERRORS ='RESET_PRODUCT_ERRORS';
export const FETCH_PRODUCT_BY_ID_SUCCESS ='FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_INIT ='FETCH_PRODUCT_BY_ID_INIT';

export const DELETE_PRODUCT_SUCCESS ='DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL ='DELETE_PRODUCT_FAIL';

export const FETCH_CATEGORIES_SUCCESS ='FETCH_CATEGORIES_SUCCESS'; 
export const FETCH_CATEGORIES_INIT ='FETCH_CATEGORIES_INIT'; 
export const FETCH_CATEGORIES_FAIL ='FETCH_CATEGORIES_FAIL'; 

export const FETCH_CATEGORY_BY_ID_INIT ='FETCH_CATEGORY_BY_ID_INIT'; 
export const FETCH_CATEGORY_BY_ID_SUCCESS ='FETCH_CATEGORY_BY_ID_SUCCESS'; 
export const FETCH_CATEGORY_BY_ID_FAIL ='FETCH_CATEGORY_BY_ID_FAIL'; 


export const UPDATE_CATEGORY_BY_ID_SUCCESS ='UPDATE_CATEGORY_BY_ID_SUCCESS'; 
export const UPDATE_CATEGORY_BY_ID_FAIL ='UPDATE_CATEGORY_BY_ID_FAIL'; 
export const RESET_CATEGORY_BY_ID_ERRORS ='RESET_CATEGORY_BY_ID_ERRORS'; 
      


export const FETCH_CATEGORY_INIT ='FETCH_CATEGORY_INIT'; 
export const FETCH_CATEGORY_SUCCESS ='FETCH_CATEGORY_SUCCESS'; 
export const FETCH_CATEGORY_FAIL ='FETCH_CATEGORY_FAIL'; 
export const UPDATE_CATEGORY_SUCCESS ='UPDATE_CATEGORY_SUCCESS'; 
export const UPDATE_CATEGORY_FAIL ='UPDATE_CATEGORY_FAIL'; 
export const DELETE_CATEGORY_FAIL ='DELETE_CATEGORY_FAIL'; 
export const DELETE_CATEGORY_SUCCESS ='DELETE_CATEGORY_SUCCESS'; 
export const RESET_CATEGORY_ERRORS ='RESET_CATEGORY_ERRORS'; 


export const FETCH_SUBCATEGORIES_SUCCESS ='FETCH_SUBCATEGORIES_SUCCESS'; 
export const FETCH_SUBCATEGORIES_INIT ='FETCH_SUBCATEGORIES_INIT';
export const FETCH_SUBCATEGORIES_FAIL ='FETCH_SUBCATEGORIES_FAIL';

export const FETCH_SUBCATEGORY_INIT ='FETCH_SUBCATEGORY_INIT'; 
export const FETCH_SUBCATEGORY_SUCCESS ='FETCH_SUBCATEGORY_SUCCESS'; 
export const FETCH_SUBCATEGORY_FAIL ='FETCH_SUBCATEGORY_FAIL'; 
export const UPDATE_SUBCATEGORY_SUCCESS ='UPDATE_SUBCATEGORY_SUCCESS'; 
export const UPDATE_SUBCATEGORY_FAIL ='UPDATE_SUBCATEGORY_FAIL'; 
export const DELETE_SUBCATEGORY_FAIL ='DELETE_SUBCATEGORY_FAIL'; 
export const DELETE_SUBCATEGORY_SUCCESS ='DELETE_SUBCATEGORY_SUCCESS'; 
export const RESET_SUBCATEGORY_ERRORS ='RESET_SUBCATEGORY_ERRORS'; 

export const FETCH_SUBCATEGORIES_BY_ID_INIT ='UPDATEFETCH_SUBCATEGORIES_BY_ID_INITPRODUCT_SUCCESS';
export const FETCH_SUBCATEGORIES_BY_ID_SUCCESS ='FETCH_SUBCATEGORIES_BY_ID_SUCCESS';
export const FETCH_SUBCATEGORIES_BY_ID_FAIL ='FETCH_SUBCATEGORIES_BY_ID_FAIL';
export const UPDATE_SUBCATEGORIES_BY_ID_SUCCESS ='UPDATE_SUBCATEGORIES_BY_ID_SUCCESS';
export const UPDATE_SUBCATEGORIES_BY_ID_FAIL ='UPDATE_SUBCATEGORIES_BY_ID_FAIL';

export const RESET_SUBCATEGORIES_BY_ID_ERRORS ='RESET_SUBCATEGORIES_BY_ID_ERRORS'; 