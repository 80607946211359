import React from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import { toast } from 'react-toastify';
import * as actions from '../../../actions';


export class BwmLargeImageUpload extends React.Component {

  constructor() {
    super();

    this.state = {
      src: null,
      selectedFile: undefined,
      imageBase64: '',
      initialImageBase64: '',
      croppedImageUrl: {},
      blob:{},
      pending: false,
      status: 'INIT',
      crop: {
         unit: '%',
         width: '',
         aspect: '',
      }
    }
  }

  resetToDefaultState(status) {
    this.setState({
      pending: false,
      status,
      selectedFile: undefined,
      croppedImage: {},
      initialImageBase64: '',
      imageBase64: ''
    });
  }

  onSelectFile = e => {
    const selectedFile = e.target.files[0];
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result,selectedFile })
      );
      reader.readAsDataURL(selectedFile);
    }
  };

  onCropChange=(crop) =>{
    this.setState({ crop });
  }

  onImageLoaded = image => {
    this.imageRef = image;
    if (image.naturalWidth > 2560 && image.naturalHeight > 1440) {

      this.resetToDefaultState('INIT');
      toast.error('Max width of an image is 2560px and height 1440px');
      return;
    }
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };
  async makeClientCrop(crop) {
    const { selectedFile } = this.state;
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, selectedFile.name);
      this.setState({ croppedImageUrl });
    }
  }


  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );
  
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          toast.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        this.setState({blob});
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg',1);
    });
  }

  onError=(error)=> {
    this.setState({pending: false, status: 'FAIL'});
  }

  onSuccess = (uploadedImage) => {
    const {onChange} = this.props.input || this.props;

    this.resetToDefaultState('INIT');

    onChange(uploadedImage);
    this.setState({src: null,selectedFile:undefined});
  }

  uploadImage=()=> {
    const { blob } = this.state;
    if (blob) {

      this.setState({pending: true, status: 'INIT'});
      actions.uploadImage(blob, 'service-category').then(
        (uploadedImage) => { this.onSuccess(uploadedImage) },
        (error) => { this.onError(error)})
    }
  }

  renderSpinningCircle() {
    const { pending } = this.state;

    if (pending) {
      return (
        <div className='img-loading-overlay'>
          <div className='img-spinning-circle'>
          </div>
        </div>
        )
    }
  }

  renderImageStatus() {
    const { status } = this.state;

    if (status === 'OK') {
      return <div className='alert alert-success'> Image Uploaded Succesfuly! </div>
    }

    if (status === 'FAIL') {
      return <div className='alert alert-danger'> Image Upload Failed! </div>
    }
  }

  render() {
    const {crop,croppedImageUrl, src  } = this.state;
    return (
      <div className='img-upload-container'>
        <label className='img-upload btn btn-bwm'>
         <span className='upload-text'> Select an image </span>
         <input type='file'
                accept='.jpg, .png, .jpeg'
                onChange={this.onSelectFile}/>
        </label>

        { src &&
          <button className='btn btn-success btn-upload'
                  type='button'
                  disabled={!src}
                  onClick={() => this.uploadImage()}>
              Upload Image
          </button>
        }

        { src &&
          <ReactCrop src={src}
                     crop={crop}
                     ruleOfThirds
                     onImageLoaded={this.onImageLoaded}
                     onComplete={this.onCropComplete}
                     onChange={this.onCropChange} />
        }

        { croppedImageUrl &&
          <div className='img-preview-container'>
            <div className='img-preview'
                 style={{'backgroundImage': 'url(' + croppedImageUrl + ')'}}>
            </div>
          
            {this.renderSpinningCircle()}
          </div>
        }

        {this.renderImageStatus()}
      </div>
    )
  }
}



