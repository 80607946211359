import { 
    FETCH_DIGITAL_WALLET_INIT,
    FETCH_DIGITAL_WALLET_SUCCESS,
    FETCH_DIGITAL_WALLET_FAIL,
    UPDATE_DIGITAL_WALLET_SUCCESS,
    UPDATE_DIGITAL_WALLET_FAIL,
    RESET_DIGITAL_WALLET_ERRORS,

    FETCH_DIGITAL_WALLET_BALANCE_INIT,
    FETCH_DIGITAL_WALLET_BALANCE_SUCCESS,
    FETCH_DIGITAL_WALLET_BALANCE_FAIL,
    UPDATE_DIGITAL_WALLET_BALANCE_SUCCESS,
    UPDATE_DIGITAL_WALLET_BALANCE_FAIL,
    RESET_DIGITAL_WALLET_BALANCE_ERRORS,

    FETCH_DIGITAL_WALLET_BY_ID_INIT,
    FETCH_DIGITAL_WALLET_BY_ID_SUCCESS,
    FETCH_DIGITAL_WALLET_BY_ID_FAIL,
    UPDATE_DIGITAL_WALLET_BY_ID_SUCCESS,
    UPDATE_DIGITAL_WALLET_BY_ID_FAIL,
    RESET_DIGITAL_WALLET_BY_ID_ERRORS,

    FETCH_DIGITAL_WALLET_BALANCE_BY_ID_INIT,
    FETCH_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS,
    FETCH_DIGITAL_WALLET_BALANCE_BY_ID_FAIL,
    UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS,
    UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_FAIL,
    RESET_DIGITAL_WALLET_BALANCE_BY_ID_ERRORS,

  

    FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_INIT,
    FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_SUCCESS,
    FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_FAIL,

    


    // SEARCH_ACCOUNTS_INIT,
    // SEARCH_ACCOUNTS_SUCCESS,
    // SEARCH_ACCOUNTS_FAIL
   } from "../actions/digital-currency/types";

const INITIAL_STATE = {
    digitalBalanceAccounts:{
        data:[],
        errors:[]
    },
    digitalWalletAccount:{
        data:{}
    },
    digitalWalletAccountBalance:{
        data:{},
    },
    digitalWalletAccountById:{
        data:{}
    },
    digitalWalletUserAccountBalanceById:{
        data:{},
    },
    digitalWalletTransactionsById:{
        data:{}
    }   

}

// export const digitalBalanceAccountsReducer = (state=INITIAL_STATE.digitalBalanceAccounts, action) =>{
//     switch(action.type){
//     case FETCH_DIGITAL_WALLET_BALANCE_INIT:
//         return {...state, data:[],errors:[]}
//     case FETCH_DIGITAL_WALLET_BALANCE_SUCCESS:
//         return {...state, data: action.digitalBalanceAccounts }
//     case FETCH_DIGITAL_WALLET_BALANCE_FAIL:
//         return Object.assign({}, ...state,{errors: action.errors, data:[]})
//     default:
//         return state;
//     }
// }

//Wallet for QR Code
export const selectedDigitalWalletAccountReducer = (state=INITIAL_STATE.digitalWalletAccount, action) =>{
    switch(action.type){
    case FETCH_DIGITAL_WALLET_INIT:
        return {...state, data: {} }
    case FETCH_DIGITAL_WALLET_SUCCESS:
        return {...state, data: action.digitalWalletAccount }
    case FETCH_DIGITAL_WALLET_FAIL:
        return Object.assign({}, ...state,{errors: action.errors, data:[]})
    case UPDATE_DIGITAL_WALLET_SUCCESS:
        return {...state, data: action.digitalWalletAccount};
    case UPDATE_DIGITAL_WALLET_FAIL:
        return {...state, errors: action.errors};
    case RESET_DIGITAL_WALLET_ERRORS:
        return {...state, errors: []};
    default:
        return state;
    }
}
//Wallet Balance
export const selectedDigitalAccountBalanceReducer = (state=INITIAL_STATE.digitalWalletAccountBalance, action) =>{
    switch(action.type){
    case FETCH_DIGITAL_WALLET_BALANCE_INIT:
        return {...state, data: 'Loading..' }
    case FETCH_DIGITAL_WALLET_BALANCE_SUCCESS:
        return {...state, data: action.digitalWalletAccountBalance}
    case FETCH_DIGITAL_WALLET_BALANCE_FAIL:
        return {...state, data: {} }
        //return Object.assign({}, ...state,{errors: action.errors.data.error_message, data:[]})
    case UPDATE_DIGITAL_WALLET_BALANCE_SUCCESS:
        return {...state, data: action.digitalWalletAccountBalance};
    case UPDATE_DIGITAL_WALLET_BALANCE_FAIL:
        return {...state, errors: action.errors};
    case RESET_DIGITAL_WALLET_BALANCE_ERRORS:
        return {...state, errors: []};
    default:
        return state;
    }
}



export const selectedDigitalWalletAccountByIdReducer = (state=INITIAL_STATE.digitalWalletAccountById, action) =>{
    switch(action.type){
    case FETCH_DIGITAL_WALLET_BY_ID_INIT:
        return {...state, data: {} }
    case FETCH_DIGITAL_WALLET_BY_ID_SUCCESS:
        return {...state, data: action.digitalWalletAccountById }
    case FETCH_DIGITAL_WALLET_BY_ID_FAIL:
        return Object.assign({}, ...state,{errors: action.errors, data:[]})
    case UPDATE_DIGITAL_WALLET_BY_ID_SUCCESS:
        return {...state, data: action.digitalWalletAccountById};
    case UPDATE_DIGITAL_WALLET_BY_ID_FAIL:
        return {...state, errors: action.errors};
    case RESET_DIGITAL_WALLET_BY_ID_ERRORS:
        return {...state, errors: []};
    default:
        return state;
    }
}

export const selectedUserDigitalAccountBalanceReducer = (state=INITIAL_STATE.digitalWalletUserAccountBalanceById, action) =>{
    switch(action.type){
    case FETCH_DIGITAL_WALLET_BALANCE_BY_ID_INIT:
        return {...state, data: 'Loading..' }
    case FETCH_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS:
        return {...state, data: action.digitalWalletUserAccountBalanceById}
    case FETCH_DIGITAL_WALLET_BALANCE_BY_ID_FAIL:
        return {...state, data: {} }
        // return {...state,errors: action.errors, data:[]}
    case UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_SUCCESS:
        return {...state, data: action.digitalWalletUserAccountBalanceById};
    case UPDATE_DIGITAL_WALLET_BALANCE_BY_ID_FAIL:
        return {...state, errors: action.errors};
    case RESET_DIGITAL_WALLET_BALANCE_BY_ID_ERRORS:
        return {...state, errors: []};
    default:
        return state;
    }
}


export const digitalWalletTransactionsByIdReducer = (state=INITIAL_STATE.digitalWalletTransactionsById, action) =>{
    switch(action.type){
    case FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_INIT:
        return {...state, data:[],errors:[]}
    case FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_SUCCESS:
        return {...state, data: action.digitalWalletTransactionsById }
    case FETCH_DIGITAL_WALLET_TRANSACTIONS_BY_ID_FAIL:
        return  {...state,errors: []};
    default:
        return state;
    }
}