import React from 'react';
import { toUpperCase, pretifyDate } from '../../../helpers';
import { Link } from 'react-router-dom';

export class ServiceManageCard extends React.Component {

    constructor() {
      super();
  
      this.state = {
        wantDelete: false
      }
    }
  
    showDeleteMenu() {
      this.setState({
        wantDelete: true
      });
    }
  
    closeDeleteMenu() {
      this.setState({
        wantDelete: false
      })
    }
  
    deleteService(serviceId, serviceIndex) {
      this.setState({wantDelete: false});
  
      this.props.deleteServiceCb(serviceId, serviceIndex);
    }
  
  
    render() {
      const { service, modal, serviceIndex } = this.props;
      const { wantDelete } = this.state;
  
      const deleteClass = wantDelete ? 'toBeDeleted' : '';
  
      return (
        <div className='col-md-4'>
          <div className={`card text-center ${deleteClass}`}>
            <div className='card-block'>
              <h4 className='card-title'>{service.title} - {toUpperCase(service.city)}</h4>
              <Link className='btn btn-bwm' to={`/services/${service._id}`}>Go to Rental</Link>
              { service.bookings && service.bookings.length > 0 && modal }
            </div>
            <div className='card-footer text-muted'>
              Created at {pretifyDate(service.createdAt)}
              { !wantDelete &&
                <React.Fragment>
                  <button onClick={() => { this.showDeleteMenu() }} className='btn btn-danger'> Delete </button>
                  <Link className='btn btn-warning' to={{pathname: `/services/${service._id}/edit`, state: { isUpdate: true }}}> Edit </Link>
                </React.Fragment>
              }
              { wantDelete &&
                <div className='delete-menu'>
                  Do you confirm?
                  <button onClick={() => {this.deleteService(service._id, serviceIndex)}} className='btn btn-danger'> Yes </button>
                  <button onClick={() => { this.closeDeleteMenu() }} className='btn btn-success'> No </button>
                </div>
              }
            </div>
          </div>
        </div>
      )
    }
  }