import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput,BwmCheckbox,BwmInputDisabled} from '../../shared/form/BwmInput';
import {BwmTextArea} from '../../shared/form/BwmTextArea';
import {BwmSelect} from '../../shared/form/BwmSelect';
import {BwmServiceImageUpload} from '../../shared/form/BwmServiceImageUpload';

import {BwmResError} from '../../shared/form/BwmResError';
import {required, phone,home_phone,text,alphanumeric,checkbox, minLength2,minLength4, maxLength16, maxLength32, maxLength64,maxLength512} from '../../shared/form/validators';

let init = {}
const ServiceDetailForm = props => {
  const { handleSubmit, pristine,reset, submitting, submitCb, valid, options ,errors,initialValues } = props;
  init = initialValues
  return (
    <form onSubmit={handleSubmit(submitCb)} >

<div className="row">
            <div className="col-md-6">
                <Field
                    name="name"
                    type="text"
                    label='Service Name'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                <Field
                    name="short_description"
                    type="text"
                    label='Short Description'
                    className='form-control'
                    component={BwmInput}
                    validate={[required]}
                />
                  <Field
                    name="description"
                    type="text"
                    label='Description'
                    className='form-control'
                    component={BwmTextArea}
                    validate={[required]}
                />
                 <Field
                    options={options}
                    name="active"
                    type="text"
                    label='Active'
                    className='form-control'
                    component={BwmSelect}
                    values={init.active}
                    validate={[required]}
                />
                <Field
                    name="updated_at"
                    type="text"
                    label='Updated'
                    className='form-control'
                    component={BwmInputDisabled}
                    
                />
                <Field
                    name="created_at"
                    type="text"
                    label='Created'
                    className='form-control'
                    component={BwmInputDisabled}
                    validate={[required]}
                /> 

              
            </div>
            <div className="col-md-6">    
                <Field
                    name="image_link"
                    type="file"
                    label='Image'
                    className='form-control'
                    component={BwmServiceImageUpload}
                    // values={process.env.REACT_APP_FILE_SERVER+'/services/'+init.image_link}
                />
            </div>
            </div>
            <button className='btn  btn-danger btn-bwm btn-form' type="submit" disabled={pristine || submitting}>
          Save 
        </button>
        <button className='btn btn-warning btn-bwm btn-form' type="button" disabled={pristine || submitting} onClick={reset}>Reset</button>
        <BwmResError errors={errors} />
      </form>
  )
}
  
  export default reduxForm({
    form: 'serviceDetailForm',initialValues:{init},enableReinitialize: true
      
  })(ServiceDetailForm)