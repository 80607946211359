import React, { Component } from 'react';

export default class AccountWalletForm extends Component {
  constructor(props) {
    super();
  }

  render() {
    console.log(this.props.result) // works
    
    return (
      <div>
        <h1>Successful Form Submission</h1>
        {/* below doesn't render */}
        <p>{this.props.result}</p>
      </div>
    );
  }
}