import React, {Component} from 'react';
import {UserGuard} from '../../shared/auth/UserGuard';
import * as eventActions from '../../../actions/events/index';
import * as actions from '../../../actions/products/index';
import * as serviceActions from '../../../actions/index';
import EventDetailsByIdForm from './EventDetailsByIdForm';
import Sidebar from '../../shared/Sidebar';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
// import DataTable from 'react-data-table-component';
import moment from 'moment';
import Back from "../../shared/navigation/Back";
import {withRouter,Redirect} from 'react-router-dom';

class EventDetailsById extends Component{
  constructor(){
    super();
    this.state ={
      eventId:'',
      isAllowed: false,
      isFetching: true,
      data:'',
      isLoading:false,
      errors: [],
      redirect: false,
      services:''
    }

    this.booleanValue = ['true', 'false'];
    this.eventCategories = ['General','Product', 'Service','Course'];
    this.eventTypes = ['daily', 'weekly', 'biweekly', 'monthly', 'yealy']
    this.updateEvent = this.updateEvent.bind(this);
    this.categories = this.categories.bind(this);
    this.subcategories = this.subcategories.bind(this);
    this.brands = this.brands.bind(this);
    this.services = this.services.bind(this);
    this.onChangeCategory= this.onChangeCategory.bind(this);

    }
    componentWillMount(){

      let eventId = this.props.match.params.eventId;
      console.log('eventId', eventId)
      this.setState({eventId:eventId})
      this.props.dispatch(eventActions.fetchEvent(eventId))
        .then((event)=>{
          event
        });

      this.props.dispatch(actions.fetchCategories(1,100));
      this.props.dispatch(actions.fetchSubcategories(1,100));
      this.props.dispatch(actions.fetchBrands(1,100));
      this.props.dispatch(serviceActions.fetchServices(1,100));
      this.props.dispatch(serviceActions.fetchServicesCategory(1,100));   

    }

    categories(){
      if(this.props.categories === null || this.props.categories=="") 
          return null;
      let result = this.props.categories.map(a=>a.name)
      return result;
  }

  subcategories(){
      if(this.props.subcategories === null || this.props.subcategories=="") 
      return null;
      let result = this.props.subcategories.map(a=>a.name)
      console.log('subcategories result',result)
      return result;
  }

  brands(){
      if(this.props.brands === null || this.props.brands=="") 
          return null;
      let result = this.props.brands.map(a=>a.name)
      return result;
  }

  services(){
      if(this.props.services === null || this.props.services=="") 
          return null;
      let result = this.props.services.map(a=>a.name)
      console.log('service result',result)
      return result;
  }

  onChangeCategory(value){
      
      return value;
  }
    updateEvent(eventData){
      const {event} = this.props;
      let mergeData = {...event, ...eventData}
      console.log('mergeData',mergeData)
      this.props.dispatch(eventActions.updateEvent(mergeData))
      .then((response) => {
        if(response.status ==='success'){
          toast.success("Success");
          this.setState({errors:[]});
        }else{
          this.setState({errors:[response.errors[0]]})
        }
      })
      
    }


    // resetAccountErrors(){
    //   this.props.dispatch(actions.resetAccountErrors());
    // }


    render(){
      
      const {isAllowed, isFetching, errors, redirect} = this.state;
      // console.log('this.props.auth.user_role', this.props.auth.user_role)
      console.log('this.props.event.start_date', this.props.event)
        
      // if(redirect)
      //   return <Redirect to ={{pathname:'/dashboard', error : {errors}}}/>

        
        if(this.props.auth.user_role =='admin'){
   
        return(

            // <UserGuard > 
              <div className="d-flex" id="wrapper">
                <Sidebar/>
                <div className="container-fluid">
                <div className="row">
                        <div className="col-md-3 col-sm-3">
                        <Back/>
                        </div></div>
                  
            
                  <div className="accountDetail">
                    <section id='accountUserForm'>
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                        
                          <h2>Event Details</h2>
                          {this.props.initialValues.image_link && 
                                      <div className="img-preview-container">
                                          <div className="img-preview">
                                              {/* <img src={'http://localhost:3006/users/'+this.props.initialValues.image_link} alt=""/>   */}
                                              <img style={{height:"150px",width:"150px"}} src={process.env.REACT_APP_FILE_SERVER+'event/'+this.props.initialValues.image_link} alt=""/>         
                                          </div>
                                      </div>
                          }
                        </div>
           
                      </div> 
                    </section>     
             
                    <section id='accountDetailForm'>
                            <div className="row">
                              <div className="col-12">
                                <h2>Event</h2>
                                <EventDetailsByIdForm  
                                  submitCb={this.updateEvent} 
                                  initialValues ={this.props.initialValues}
                                  options={this.booleanValue}
                                  eventCategories={this.eventCategories}
                                  eventTypes = {this.eventTypes}
                                  categories = {this.categories()}
                                  subcategories = {this.subcategories()}
                                  brands={this.brands()}
                                  services={this.services()}
                                  // onChangeCategory={this.onChangeCategory}
                                  errors={this.state.errors}
                                />
                              </div>
                            </div>       
                    </section> 
                  </div>
                </div>

              </div>
            // </UserGuard> 
        )
        }else 
        return(
            <div>
              <h1>Loading...</h1>
            </div>
        )    
      }
    
}      

function mapStateToProps(state){
    return {
        auth: state.auth,
        categories: state.categories.data,
        subcategories: state.subcategories.data,
        brands: state.brands.data,
        services: state.services.data,
        event: state.event.data,
        initialValues: {

          sorting_no:state.event.data.sorting_no,
          name:state.event.data.name,
          active:state.event.data.active,
          short_description:state.event.data.short_description,
          description:state.event.data.description,
          price:state.event.data.price,
          start_date:state.event.data.start_date,
          end_date:state.event.data.end_date,
          image_link:state.event.data.image_link,
          image_links:state.event.data.image_links,
          video_link:state.event.data.video_link,
          video_links:state.event.data.video_links,
          created_at:state.event.data.created_at,
          updated_at:state.event.data.updated_at,
          discount:state.event.data.discount,
          coupon_code:state.event.data.coupon_code,
          coupon_total:state.event.data.coupon_total,
          coupon_sold:state.event.data.coupon_sold,
          event_category:state.event.data.event_category,
          event_type:state.event.data.event_type,
          category:state.event.data.category,
          subcategory:state.event.data.subcategory,
          brand:state.event.data.brand,
          budget:state.event.data.budget,
          income:state.event.data.income,
          expense:state.event.data.expense,
          credited:state.event.data.credited,

          
        }
    }
}

export default connect(mapStateToProps)(EventDetailsById);
