import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {BwmInput} from '../shared/form/BwmInput';
import {BwmResError} from '../shared/form/BwmResError';


const ResetPasswordForm = props => {
  const { handleSubmit, pristine, submitting, submitCb, valid, errors } = props
  return (
    <form onSubmit={handleSubmit(submitCb)}>

    
<Field
        name="password"
        type="text"
        label="Password"
        className="form-control"
        component={BwmInput}
      />    
      
      <Field
        name="passwordConfirmation"
        type="text"
        label="Password Confirmation"
        className="form-control"
        component={BwmInput}
      /> 
      
    
 
      <button className="btn btn-success btn-bwm btn-form" type="submit" disabled={!valid || pristine || submitting}>
        Submit
      </button>
      <BwmResError errors = {errors} />
    </form>
  )
}

const validate = values => {
  const errors = {}

  // if (!values.passwordConfirmation && values.passwordConfirmation.length < 6 ) {
  //   errors.passwordConfirmation = 'Please enter password confirmation equal or greater than 6 Character!';
  // }

  // if (values.password !== values.passwordConfirmation) {
  //   errors.password = 'Passwords must be the same';
  // }
  return errors
}

export default reduxForm({
  form: 'resetPasswordForm', // a unique identifier for this form
  validate
})(ResetPasswordForm)